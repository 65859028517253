
  .widget-title{
    position:relative;
}
.widget-title a{
    position:absolute;
    right:0;
    bottom:20px;
    font-size:13px;
}
.navbar-default{
    border:0;
    box-shadow:none !important;
    background-color:transparent;
}
.navbar-brand{
    font-size:24px;
    color:#fff !important;
    padding:0 0 0 0;
    margin:0;
    line-height:1;
    text-transform:capitalize;
    height:auto;
    text-decoration:none !important;
    font-weight:700;
}
.navbar-brand img{
    width:100%;
}
.navbar-nav > li > a i{
    margin-right:3px;
}
.navbar-nav > li > a{
    font-size:14px;
    color:#fff !important;
    padding:10px 15px;
    text-transform:uppercase;
    text-decoration:none !important;
    line-height:30px;
    font-family:"Source Sans Pro",sans-serif;
    letter-spacing:1px;
}
.yamm-content .links-widget{
    padding:20px 0 0;
}
.yamm-content .links-widget a{
    color:#111111 !important;
    font-family:"Source Sans Pro",sans-serif;
}
.navbar-nav > li{
    -webkit-transition:background-color 0.7s ease 0s;
    -moz-transition:background-color 0.7s ease 0s;
    -ms-transition:background-color 0.7s ease 0s;
    -o-transition:background-color 0.7s ease 0s;
    transition:background-color 0.7s ease 0s;
}
.navbar-nav > li:hover,.navbar-default .navbar-nav > .active > a,.navbar-default .navbar-nav > .active > a:hover,.navbar-default .navbar-nav > .active > a:focus{
    background-color:rgba(255,255,255,0.1);
}
.navbar-default .navbar-nav > .open > a,.navbar-default .navbar-nav > .open > a:hover,.navbar-default .navbar-nav > .open > a:focus{
    background-color:transparent;
}
.m30{
    margin-top:30px;
}
.ads-widget img{
    margin:0 auto;
    width:auto;
    display:block;
}
.yamm .nav,.yamm .collapse,.yamm .dropup,.yamm .dropdown{
    position:static;
}
.yamm .dropdown.yamm-fw{
    position:static;
}
.yamm .container{
    position:relative;
}
.yamm .yamm-content{
    padding:30px;
}
.yamm .dropdown.yamm-fw .dropdown-menu{
    left:0;
    right:0;
    padding:30px;
}
.yamm .dropdown{
    position:relative;
}
.yamm .dropdown.yamm-fw.yamm-half .dropdown-menu{
    left:auto;
    right:auto;
}
.yamm-half .yamm-content ul{
    min-width:180px;
}
.yamm-fw .dropdown-menu li{
    padding:10px 0px;
}
.yamm-fw .dropdown-menu li a{
    padding:0 !important;
    font-size:14px;
    background-color:transparent !important;
    text-transform:capitalize;
}
.yamm .dropdown .dropdown-menu{
    border-radius:0;
    margin-top:20px;
    padding:0;
}
.yamm .dropdown.yamm-fw .dropdown-menu{
    border-radius:0;
    left:0;
    background-color:#fff !important;
    margin-top:20px;
    padding:0;
    right:0;
}
.yamm .dropdown .dropdown-menu .show-left{
    margin-top:0
}
.well{
    border:0;
    padding:12px 0;
}
.yamm p{
    margin-top:0;
    padding-top:0;
    border-bottom:0;
    line-height:1;
    margin-bottom:0;
    padding-bottom:15px;
    font-weight:600;
    color:#222222;
    font-size:14px;
}
.well .form-control{
    font-size:12px !important;
    height:35px;
    margin-bottom:15px;
}
.yamm ul{
    padding:0;
}
@media (min-width:992px){
    .yamm-fw{
        position:relative;
    }
    .hasmenu:hover > ul,.yamm-fw:hover > ul{
        visibility:visible;
        opacity:1;
        top:100%;
    }
    .navbar-nav .hasmenu ul,.navbar-nav .yamm-fw ul{
        -webkit-transition:visibility 0.35s ease-in-out,opacity 0.35s ease-in-out,top 0.35s ease-in-out,bottom 0.35s ease-in-out,left 0.35s ease-in-out,right 0.35s ease-in-out;
        -moz-transition:visibility 0.35s ease-in-out,opacity 0.35s ease-in-out,top 0.35s ease-in-out,bottom 0.35s ease-in-out,left 0.35s ease-in-out,right 0.35s ease-in-out;
        -o-transition:visibility 0.35s ease-in-out,opacity 0.35s ease-in-out,top 0.35s ease-in-out,bottom 0.35s ease-in-out,left 0.35s ease-in-out,right 0.35s ease-in-out;
        transition:visibility 0.35s ease-in-out,opacity 0.35s ease-in-out,top 0.35s ease-in-out,bottom 0.35s ease-in-out,left 0.35s ease-in-out,right 0.35s ease-in-out;
    }
    .hasmenu .dropdown-menu,.yamm-fw .dropdown-menu{
        min-width:240px;
        padding:0;
        -webkit-border-radius:0;
        -moz-border-radius:0;
        border-radius:0;
    }
    .hasmenu > ul,.yamm-fw > ul{
        position:absolute;
        top:130%;
        visibility:hidden;
        display:block;
        opacity:0;
        left:0;
    }
    .hasmenu > ul.start-right,.yamm-fw > ul.start-right{
        right:0;
        left:auto;
    }
    .hasmenu > ul li,.yamm-fw > ul li{
        position:relative;
    }
    .hasmenu > ul li:hover > ul,.yamm-fw > ul li:hover > ul{
        visibility:visible;
        opacity:1;
        left:100%;
    }
    .hasmenu > ul li:hover > ul.show-right,.yamm-fw > ul li:hover > ul.show-right{
        right:100%;
    }
}
@media (max-width:1200px){
    .nav > li > a{
        padding:1em 0.7em;
    }
}
.dropdown{
    position:relative;
}
.dropdown-toggle:focus{
    outline:0;
}
.dropdown-menu{
    position:absolute;
    top:100%;
    left:0;
    z-index:999999999;
    display:none;
    float:left;
    min-width:160px;
    padding:0;
    margin:2px 0 0;
    list-style:none;
    font-size:14px;
    text-align:left;
    background-color:#ffffff;
    border-radius:0.25em;
    background-clip:padding-box;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    border:1px solid #efefef;
    box-shadow:none;
}
.dropdown-menu.pull-right{
    right:0;
    left:auto;
}
.dropdown-menu .divider{
    height:1px;
    margin:0.5em 0;
    overflow:hidden;
    background-color:#e6e6e6;
}
.dropdown-mega span{
    position:absolute;
    right:0;
    color:#999999;
    font-style:italic;
}
.dropdown-mega > li a:before{
    content:"\f105";
    font-family:"FontAwesome";
    font-size:13px;
    left:0;
    display:inline-block !important;
    color:#999;
    padding-right:8px;
    position:relative;
    top:0;
}
.dropdown-mega > li{
    list-style:none;
}
.box h5{
    font-size:15px;
    font-weight:400;
    margin-bottom:20px;
    text-transform:uppercase;
}
.dropdown-mega > li > a,.dropdown-menu > li > a{
    clear:both;
    color:#111111 !important;
    display:block;
    font-size:13px !important;
    font-weight:400 !important;
    line-height:1;
    padding:15px 20px;
    white-space:nowrap;
    text-transform:capitalize;
    letter-spacing:1px;
    -webkit-transition:all 0.3s ease 0s;
    -moz-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
    transition:all 0.3s ease 0s;
}
.dropdown-mega > li,.dropdown-menu > li{
    border-bottom:1px solid #efefef;
}
.dropdown-mega > li:last-child,.dropdown-menu > li:last-child{
    border-bottom:0;
}
.dropdown-menu > li:hover > a{
    background-color:#242424;
    color:#FFF !important;
}
.dropdown-menu > .active > a,.dropdown-menu > .active > a:hover,.dropdown-menu > .active > a:focus{
    text-decoration:none;
    outline:0;
    background-color:#00A651;
    color:#fff;
}
.dropdown-menu > .disabled > a,.dropdown-menu > .disabled > a:hover,.dropdown-menu > .disabled > a:focus{
    color:#cccccc;
}
.dropdown-menu > .disabled > a:hover,.dropdown-menu > .disabled > a:focus{
    text-decoration:none;
    background-color:transparent;
    background-image:none;
    cursor:not-allowed;
}
.open > .dropdown-menu{
    display:block;
}
.open > a{
    outline:0;
}
.dropdown-menu-right{
    left:auto;
    right:0;
}
.dropdown-menu-left{
    left:0;
    right:auto;
}
.dropdown-header{
    display:block;
    padding:0.5em 1em;
    font-size:14px;
    font-weight:bold;
    line-height:1;
    color:#666666;
    white-space:nowrap;
}
.dropdown-backdrop{
    position:fixed;
    left:0;
    right:0;
    bottom:0;
    top:0;
    z-index:990;
}
.pull-right > .dropdown-menu{
    right:0;
    left:auto;
}
.dropup .caret,.navbar-fixed-bottom .dropdown .caret{
    border-top:0;
    border-bottom:0.3em solid;
    content:"";
}
.dropup .dropdown-menu,.navbar-fixed-bottom .dropdown .dropdown-menu{
    top:auto;
    bottom:100%;
    margin-bottom:1px;
}
@media (min-width:768px){
    .navbar-right .dropdown-menu{
        left:auto;
        right:0;
    }
    .navbar-right .dropdown-menu-left{
        left:0;
        right:auto;
    }
}
.yamm-content .widget{
    position:relative;
    display:block;
    margin-bottom:30px;
}
.widget-title{
    padding:15px 0 0;
    display:block;
    position:relative;
}
.widget-title h4{
    line-height:1;
    padding:0 !important;
    font-weight:700;
    margin:0 !important;
    font-size:16px;
    text-transform:uppercase;
}
.widget-title hr{
    border-width:4px;
    margin-bottom:0;
}
.widget-title hr:before{
    width:80px;
    background-color:#ddd;
    display:block;
    height:4px;
    position:relative;
    content:"";
    top:-4px;
}
.widget hr{
    border-color:#e0e0e0;
}
div.mini-widget-title{
    float:left;
    margin-left:10px;
    width:105px;
}
div.mini-widget-title a{
    color:#121212;
    font-size:14px !important;
    font-weight:400;
    line-height:20px;
}
div.mini-widget-hr{
    border-bottom:1px solid #e0e0e0;
    height:1px;
    margin-top:15px;
    width:45px;
}
div.mini-widget-thumb{
    display:block;
    float:left;
    width:57px;
}
div.mini-widget-thumb a,div.mini-widget-thumb img{
    display:inherit;
    height:inherit;
    width:inherit;
}
.mini-widget .post{
    margin-bottom:30px;
}
.mini-widget-title small{
    display:block;
    padding:5px 0 0;
    font-style:italic;
    color:#999;
}
@media (max-width:1080px){
    .yamm-content div.mini-widget-thumb{
        display:none !important;
    }
    div.mini-widget-thumb{
        width:25px;
        float:left;
    }
    div.mini-widget-title{
        margin:0 0 0 10px;
    }
}
@media (max-width:992px){
    .navbar-default .navbar-toggle{
        background-color:#231f20 !important;
    }
    .navbar-brand{
        padding:0;
        margin:0 auto 10px !important;
        width:100%;
        display:inline-block;
        float:none;
        text-align:center;
    }
    .navbar-brand img{
        max-width:220px;
        padding:0;
        margin:0 auto;
    }
    .ads-widget img{
        margin:0 auto!important;
        width:auto;
        display:block;
        float:none;
        text-align:center;
    }
    .nav > li > a{
        padding:10px 10px;
    }
}
@media (max-width:900px){
    .nav > li > a{
        padding:10px 10px;
    }
}
@media (max-width:768px){
    .dropdown-menu{
        background-color:#ffffff !important;
    }
    .nav > li > a{
        padding:10px 10px;
    }
    .yamm-content{
        padding:0 20px !important;
    }
    .navbar-nav > li:hover,.navbar-default .navbar-nav > .active > a,.navbar-default .navbar-nav > .active > a:hover,.navbar-default .navbar-nav > .active > a:focus{
        background-color:transparent !important;
    }
}
@media (max-width:420px){
    .header .widget,.header .widget-title,.navbar-nav,.navbar{
        margin:0 !important;
        border-radius:0 !important;
        float:left !important;
        width:100% !important;
    }
}
@media (max-width:1406px){
    .dm-social{
        display:none;
    }
}
.webform-next{
    background:#008001;
    color:#fff;
}
p{
    font-size:14px;
    line-height:28px;
}
a{
    text-decoration:none;
}
a:hover{
    text-decoration:none;
}
a:focus{
    outline:none;
}
ul,ol{
    list-style:none;
    padding:0;
}
.color-overlay{
    background-image:url(/sites/all/themes/anc/images/overlay.png);
}
h1,h2,h3,h4,h5,h6{
    font-family:'Nunito',sans-serif;
}
.color-overlay{
    bottom:0;
    height:100%;
    left:0;
    min-width:100%;
    position:absolute;
    right:0;
    top:0;
    width:100%;
    z-index:999;
}
.green{
    background-color:#00a651;
}
.red{
    background-color:#00a651;
}
.blue{
    background-color:#ffcb08;
}
.purpal{
    background-color:#231f20;
}
.yellow{
    background-color:#ffcb08;
}
.brown{
    background-color:#49311c;
}
.nopadding{
    padding:0;
}
.top-bar{
    background-color:#FFCB08;
    max-height:48px;
    position:relative;
    display:block;
    border:1px solid #FFDB4D;
    border-top:1px solid #F7C600;
}
.weather{
    background-color:#00a651;
    color:#fff;
    display:inline-block;
    font-family:"Nunito",sans-serif;
    font-weight:500;
    letter-spacing:1px;
    text-transform:uppercase;
    font-size:14px;
    line-height:14px;
    padding-top:6px;
    text-align:center;
    padding-bottom:4px;
    padding-left:5px;
    padding-right:5px;
}
.weather span{
    display:block;
    font-size:20px;
    font-weight:600;
    line-height:20px;
    text-align:center;
    margin-bottom:4px;
}
.weather i{
    font-size:14px;
}
.top-bar .block{
    padding:0;
    margin:0;
}
.top-bar .search{
    background-color:#ffcb08;
    display:inline-block;
    color:#fff;
    padding-left:20px;
    padding-top:7px;
}
.top-bar .search a{
    color:#FFF;
}
.top-bar .search a:hover{
    color:#272727;
}
.top-bar .search i{
    font-size:14px;
    margin:0 12px;
}
#header-container{
    position:relative;
}
.top-header{
    padding-top:20px;
}
.breakingNews{
    background-color:#231F20;
    padding-top:10px;
    padding-left:20px;
}
#anc{
    padding:15px;
    color:#fff;
    font-size:18px;
    text-align:center;
    background-color:#00A651;
}
.logo{
    position:relative;
    z-index:999;
    padding-right:20px;
}
.navbar-default .navbar-toggle{
    background-color:#231f20 !important;
    width:50px;
    margin-right:20px;
}
@media only screen and (min-width:990px){
    .logo img{
        float:right;
    }
    .main-header .nav-outer:before{
        position:absolute;
        left:0;
        bottom:0;
        height:53px;
        width:100%;
        left:-100%;
        content:"";
        z-index:9;
        background-color:transparent;
    }
}
.bn-title{
    padding-left:15px;
}
.header-image{
    position:relative;
    overflow:hidden;
    margin:0px 0%;
    width:100%;
    color:#ffffff;
    font-size:16px;
    background-color:#000000;
    border:0px solid #272727;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    -webkit-transform:translateZ(0);
    transform:translateZ(0);
}
.header-image *,.header-image *:before,.header-image *:after{
    -webkit-transition:all 0.4s ease;
    transition:all 0.4s ease;
}
.header-image img{
    max-width:100%;
    backface-visibility:hidden;
    vertical-align:top;
}
.header-image i{
    position:absolute;
    line-height:50px;
    width:50px;
    text-align:center;
    font-size:28px;
    z-index:9;
    top:0;
    left:0;
}
.header-image i.ion-star:after{
    position:absolute;
    top:3px;
    left:3px;
    content:'';
    width:0;
    height:0;
    border-style:solid;
    border-width:75px 75px 0 0;
    border-color:#231f20 transparent transparent;
    z-index:-1;
}
.header-image i.ion-volume-medium:after{
    position:absolute;
    top:3px;
    left:3px;
    content:'';
    width:0;
    height:0;
    border-style:solid;
    border-width:75px 75px 0 0;
    border-color:#00a651 transparent transparent;
    z-index:-1;
}
.header-image i.ion-share:after{
    position:absolute;
    top:3px;
    left:3px;
    content:'';
    width:0;
    height:0;
    border-style:solid;
    border-width:75px 75px 0 0;
    border-color:#00a651 transparent transparent;
    z-index:-1;
}
.header-image i.ion-videocamera:after{
    position:absolute;
    top:3px;
    left:3px;
    content:'';
    width:0;
    height:0;
    border-style:solid;
    border-width:75px 75px 0 0;
    border-color:#ffcb08 transparent transparent;
    z-index:-1;
}
.header-image span{
    position:absolute;
    bottom:0px;
    top:0;
    left:0px;
    right:0;
    z-index:1;
    background-color:rgba(0,0,0,0.5);
    color:#FFF;
}
.header-image span a{
    bottom:0;
    position:absolute;
    padding:10px 20px;
    color:#FFF;
    font-weight:600;
    text-transform:uppercase;
    font-family:'Nunito',sans-serif;
    letter-spacing:1px;
}
.header-image span a:hover{
    color:#ffcb08;
}
.header-image:hover img,.header-image.hover img{
    zoom:1;
    filter:alpha(opacity=70);
    -webkit-opacity:0.7;
    opacity:0.7;
    -webkit-transform:scale(1.2);
    transform:scale(1.2);
}
.header-image:hover i,.header-image.hover i{
    -webkit-transform:translate(0,0);
    transform:translate(0,0);
}
.slider .btn{
    margin-right:20px;
}
.btn-primary,#block-simplenews-1 .btn{
    background-color:#00a651;
    border:2px solid #231f20;
    font-size:14px;
    text-transform:uppercase;
    border-radius:0px;
    color:#fff;
}
#block-simplenews-1 .btn{
    width:100%;
    text-align:center;
}
.btn-primary:hover{
    background-color:#272727;
    border:2px solid #272727;
}
.btn-white{
    background-color:transparent;
    border:1px solid #fff;
    font-size:14px;
    text-transform:uppercase;
    border-radius:0px;
    color:#ffcb08;
}
.btn-white:hover{
    color:#595959;
    background-color:#fff;
    border:1px solid #fff;
}
.slider-caption{
    position:absolute;
    bottom:30%;
    z-index:9999;
    width:50%;
}
.slider-title{
    font-size:42px;
    text-transform:uppercase;
    color:#fff;
    line-height:52px;
}
.slider-content{
    font-size:18px;
    padding:10px 0px 15px 0px;
    color:#fff;
}
.slider .owl-next{
    background-color:rgba(255,255,255,0.9) !important;
    color:#242424 !important;
    position:absolute;
    right:0px;
    top:45%;
    opacity:0;
}
.slider:hover .owl-next,.slider:hover .owl-prev{
    opacity:1;
}
.slider .owl-prev{
    background-color:rgba(255,255,255,0.9) !important;
    color:#242424 !important;
    left:0;
    position:absolute;
    top:45%;
    opacity:0;
}
.slider .owl-controls .owl-prev,.slider .owl-controls .owl-next{
    font-size:30px;
    color:#fff;
}
.feature-item{
    padding:2px;
}
.navbar-default,.header,.flex-direction-nav a,.mini-widget .post:hover a,.widget-title hr:before{
    background-color:transparent !important;
    border-color:transparent !important;
    color:#ffcb08 !important;
}
a,.widget-title a{
    color:#00A652;
}
a:hover,a:focus{
    color:#0080c9;
}
.navbar-collapse{
    padding-left:0px;
}
.main-wrapper{
    padding:60px 0;
}
.lifestyle{
    padding:0px 0 0;
    display:block;
    position:relative;
    margin-bottom:20px;
}
.lifestyle h4{
    line-height:1;
    padding:0 !important;
    font-weight:700;
    margin:0 !important;
    text-transform:uppercase;
    letter-spacing:1px;
}
.lifestyle hr{
    border-width:2px;
    margin-bottom:0;
    margin-top:25px;
}
.lifestyle hr:before{
    width:80px;
    background-color:#ffcb08;
    display:block;
    height:2px;
    position:relative;
    content:"";
    top:-2px;
}
.fashion{
    text-align:center;
    padding-bottom:20px;
}
.latest-category .fashion h1,.fashion h1{
    text-transform:capitalize;
    font-size:30px;
    color:#242424;
    -webkit-transition:all 0.3s ease 0s;
    -moz-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
    transition:all 0.3s ease 0s;
}
.latest-category .fashion h1:hover,.fashion h1:hover{
    color:#FFCC00;
}
.fashion h2{
    color:#231f20;
    font-size:16px;
    text-transform:uppercase;
    padding-top:10px;
    margin-top:0px;
    padding-bottom:10px;
    letter-spacing:1px;
    font-weight:600;
    -webkit-transition:all 0.3s ease 0s;
    -moz-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
    transition:all 0.3s ease 0s;
}
.fashion h2:hover{
    color:#ffcb08;
}
.fashion i{
    padding-right:10px;
    color:#a6a6a6;
}
.fashion span{
    color:#FFF;
}
.lorem{
    color:#272727;
    display:inline-block;
    float:left;
    width:auto;
    height:auto;
    line-height:44px;
    font-size:50px;
    font-weight:500;
    margin:0.1em 0.2em 0em 0;
    overflow:hidden;
    text-align:center;
    text-transform:uppercase;
}
.paragraph p{
    line-height:28px;
    color:#595959;
}
.btn-lifestyle{
    padding-top:13px;
    font-size:14px;
    color:#fff;
    background-color:#ffcb08;
    text-transform:uppercase;
    border:0px;
    border-radius:0px;
    float:left;
}
.btn-lifestyle:hover{
    background-color:#272727;
    color:#fff;
}
.paragraph{
    padding-bottom:20px;
    position:relative;
    overflow:hidden;
    border-bottom:1px solid #eee;
}
.paragraph a{
    color:#fff;
    background-color:#ffcb08;
    padding:7px 15px;
    margin-left:10px;
    margin-top:5px;
    float:right;
}
.paragraph a:hover{
    background-color:#272727;
    color:#fff;
}
.banner img{
    margin:50px auto 50px;
}
.whats-hot{
    padding:0 20px;
}
.media-body small{
    font-style:italic;
    color:#595959;
}
.widget{
    display:block;
    margin-bottom:50px;
    position:relative;
    overflow:hidden;
}
.section{
    margin-top:60px;
}
.media-sidebar{
    padding-top:30px;
}
.media{
    margin-top:20px;
}
.media-heading{
    font-size:16px;
    font-weight:600;
    letter-spacing:1px;
    padding-top:10px;
    color:#242424;
    text-transform:uppercase;
    -webkit-transition:all 0.3s ease 0s;
    -moz-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
    transition:all 0.3s ease 0s;
}
.media-heading:hover{
    color:#ffcb08;
}
.technology span{
    color:#FFF;
    font-size:12px;
    text-transform:uppercase;
    padding:2px 10px;
    margin-right:2px;
}
.technology p{
    margin-bottom:0;
}
.papular-videos{
    background-color:#f4c004;
    padding:50px 0;
}
.papular-videos h2,.papular-videos p,.papular-videos h4,.papular-videos .lorem{
    color:#242424;
}
.carousel-video{
    backface-visibility:#242424;
}
.carousel-cell{
    width:100%;
}
.flickity-page-dots{
    bottom:0px;
}
.flickity-page-dots .dot{
    width:12px;
    height:12px;
    opacity:1;
    background:transparent;
    border:2px solid white;
}
.flickity-page-dots .dot.is-selected{
    background:white;
}
.latest-category{
    padding:60px 0px;
}
.author img{
    margin-bottom:20px;
}
.social-media-widget ul{
    padding:0;
    margin:0 !important;
}
.social-media-widget li{
    text-align:center;
    padding:20px;
    list-style:none;
    float:left;
    width:33.33%;
    margin:0 !important;
    -webkit-transition:all 0.3s ease 0s;
    -moz-transition:all 0.3s ease 0s;
    -ms-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
    transition:all 0.3s ease 0s;
}
.social-media-widget li:hover{
    zoom:1;
    filter:alpha(opacity=80);
    opacity:0.8;
}
.social-media-widget small{
    font-family:'Nunito',sans-serif;
    line-height:1;
    letter-spacing:1px;
    padding:10px 0 0;
}
.social-media-widget li i{
    font-size:24px;
}
.social-media-widget ul li a{
    color:#ffffff !important;
}
.social-media-widget li small{
    display:block;
}
.social-media-widget li.facebook{
    background-color:#3b5998;
}
.social-media-widget li.twitter{
    background-color:#55acee;
}
.social-media-widget li.googleplus{
    background-color:#dd4b39;
}
.social-media-widget li.pinterest{
    background-color:#cb2027;
}
.social-media-widget li.linkedin{
    background-color:#007bb5;
}
.social-media-widget li.rss{
    background-color:#F36F24;
}
.social-media-widget li.youtube{
    background-color:#bb0000;
}
.social-media-widget li.instagram{
    background-color:#125688;
}
.latest-post{
    margin-top:20px;
}
.img-padding{
    margin-bottom:20px;
}
.interview a h2{
    font-size:16px;
    text-transform:uppercase;
    color:#121212;
    padding-bottom:10px;
}
.ad{
    display:table;
    margin:0 auto;
}
.widget .insta img{
    float:left;
    margin:5px;
    width:80px;
    cursor:pointer;
    -webkit-transition:all 0.3s ease 0s;
    -moz-transition:all 0.3s ease 0s;
    -ms-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
    transition:all 0.3s ease 0s;
}
.widget .insta img:hover{
    opacity:0.5;
}
.pagination > li > a,.pagination > li > span{
    color:#272727;
}
.pagination > .active > a,.pagination > .active > span,.pagination > .active > a:hover,.pagination > .active > span:hover,.pagination > .active > a:focus,.pagination > .active > span:focus{
    color:#272727;
    background-color:#ffcb08;
    border:1px solid #ffcb08;
}
.pagination > li > a:hover,.pagination > li > span:hover{
    background-color:#ffcb08;
}
.single-para{
    padding-bottom:20px;
}
.blog-title h1{
    text-transform:capitalize;
    margin:0;
}
blockquote{
    border-left:0 none;
    color:#797979;
    font-family:Raleway-SemiBold;
    font-size:20px;
    line-height:1.8;
    margin:50px 20px;
    padding:0.35em 50px 0.5em;
    position:relative;
    text-transform:capitalize;
}
blockquote::before{
    content:"";
    font-family:"FontAwesome";
    color:#ccc;
    display:block;
    font-size:34px;
    left:0;
    position:absolute;
    top:-15px;
}
.post-sharing{
    display:block;
    width:100%;
    position:relative;
    margin:0px 0 0;
}
.fb-button{
    font-size:12px;
    padding-top:8px;
}
.single-blog .fashion{
    text-align:left;
}
.breadcrumb{
    margin-bottom:10px;
    background-color:transparent;
    padding:0;
    border-top:1px solid #ddd;
    border-bottom:1px solid #ddd;
}
.breadcrumb a:first-child{
    margin-left:0;
}
.breadcrumb a{
    color:#797979;
    margin:0 10px;
    text-transform:capitalize;
    font-size:14px;
    -webkit-transition:all 0.3s ease 0s;
    -moz-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
    transition:all 0.3s ease 0s;
}
.breadcrumb a:hover{
    color:#ffcb08;
}
.breadcrumb .topic{
    color:#ccc;
}
.search .stylish-input-group .input-group-addon{
    background:white !important;
    border-radius:0;
    border-left:1px solid #ccc;
}
.search .stylish-input-group .form-control{
    border-right:0;
    box-shadow:0 0 0;
    border-color:#ccc;
    height:50px;
}
.search .stylish-input-group button{
    border:0;
    background:transparent;
}
.article-img-left{
    float:left;
    height:50%;
    margin-right:30px;
    overflow:hidden;
    position:relative;
    width:50%;
    margin-top:20px;
    margin-bottom:20px;
}
.article-img-right{
    float:right;
    height:50%;
    margin-left:30px;
    overflow:hidden;
    position:relative;
    width:50%;
    margin-top:20px;
    margin-bottom:20px;
}
.widget .tag_cloud a{
    background:#ffffff none repeat scroll 0 0;
    border:1px solid #e2e2e2;
    display:inline-block;
    float:left;
    font-size:13px;
    font-weight:500;
    line-height:30px;
    margin:0 10px 8px 0;
    padding:0 20px;
    color:#242424;
    -webkit-transition:all 0.3s ease 0s;
    -moz-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
    transition:all 0.3s ease 0s;
}
.widget .tag_cloud a:hover{
    color:#FFCC00;
    border:1px solid #FFCC00;
}
.fashion span{
    font-weight:12px;
    padding:2px 10px;
    text-transform:uppercase;
    margin-right:5px;
}
.widget ul li{
    border-bottom:1px solid #f1f1f1;
}
.widget ul li a{
    color:#242424;
    font-size:14px;
    font-weight:500;
    line-height:42px;
    position:relative;
    letter-spacing:1px;
    -webkit-transition:all 0.3s ease 0s;
    -moz-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
    transition:all 0.3s ease 0s;
    text-transform:uppercase;
}
.widget ul li a:hover{
    color:#FFCC00;
}
.widget ul li a span{
    padding:5px;
    float:right;
    border:1px solid #f1f1f1;
    color:#999;
    line-height:10px;
    margin-top:10px;
}
.widget ul li a span:hover{
    color:#FFCC00;
}
.tag-cloud-post i{
    margin-right:20px;
}
.tag-cloud-post a{
    color:#999 !important;
    margin-right:10px;
}
.section-comment{
    margin-top:40px;
}
.media{
    position:relative;
}
.media-comment{
    margin-bottom:50px;
}
.time-comment{
    margin-bottom:10px;
    display:inline-block;
    color:#b4b4b4;
    line-height:1;
}
.media .reply{
    right:20px;
    padding:5px 10px;
    position:absolute;
    top:14px;
    font-size:12px;
}
.comment-reply{
    margin-left:45px;
    margin-bottom:50px;
}
.comments .well{
    background-color:#ffffff !important;
    border:0 !important;
    box-shadow:none !important;
    margin-bottom:0px;
    padding-bottom:0px !important;
}
.commentform textarea.form-control{
    height:140px !important;
    max-width:100% !important;
}
label{
    font-size:14px;
}
.required{
    color:#DA5333;
    margin-left:5px;
}
.sociallinks{
    padding:0;
    list-style:none;
}
.sociallinks i{
    margin-right:5px;
}
.darkcolor a{
    color:#121212 !important;
}
.sociallinks li{
    margin-bottom:10px;
}
.contact-para{
    line-height:1.8;
    padding-bottom:20px;
}
#map-canvas{
    width:100%;
    height:400px;
}
.footer-wrapper{
    background-color:#ffcb07;
    border-top:#D9AD00 1px solid;
    width:100%;
    padding:50px 0px;
}
.footer-wrapper .lifestyle h4{
    color:#242424;
}
.about p{
    line-height:1.6;
    word-spacing:1px;
    padding:15px 0;
    color:#242424;
    text-align:left;
    font-size:14px;
}
.about i{
    padding-bottom:10px;
    color:#242424;
}
.about i:last-child{
    padding-bottom:0px;
}
.about span{
    color:#242424;
    padding-left:10px;
}
.footer-tech{
    color:#fff;
    line-height:1.6;
}
.footer-wrapper .media h4{
    color:#242424;
}
.footer-wrapper .media h4:hover{
    color:#ffcb08;
}
.form-control{
    border-radius:0px;
    margin-bottom:25px;
}
.check{
    padding:0;
    list-style:none;
}
.check li{
    margin-bottom:10px;
}
.check li a{
    color:#242424;
}
.check li a:hover{
    color:#ffcb08;
}
.footer-bottom{
    background-color:#151515;
}
.footer-bottom .navbar{
    min-height:auto !important;
}
.footer-bottom .navbar-nav > li > a{
    padding:0 20px;
    color:#FFF !important;
}
.copyright{
    position:relative;
    overflow:hidden;
    padding:15px 0;
}
.copyright p{
    color:#FFF;
    margin-bottom:0;
}
.footer-bottom .navbar-nav{
    min-height:20px;
}
.img-gellary{
    position:relative;
    overflow:hidden;
}
.img-gellary img{
    opacity:0.9;
    -webkit-transition:all 0.3s ease 0s;
    -moz-transition:all 0.3s ease 0s;
    -ms-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
    transition:all 0.3s ease 0s;
}
.img-gellary img:hover{
    opacity:1;
}
#spinner{
    background:#fff;
    height:100%;
    left:0;
    opacity:1;
    filter:alpha(opacity=100);
    position:fixed;
    text-align:center;
    top:0;
    width:100%;
    z-index:999999999;
}
.spinner-img{
    height:100%;
    left:0;
    position:fixed;
    top:50%;
    width:100%;
}
.page404{
    text-align:center;
}
.page404 h1{
    font-size:150px;
}
.page404 h3{
    margin-top:0;
    margin-bottom:50px;
}
@media only screen and (min-width:320px) and (max-width:359px){
    .logo img{
        margin:0 auto;
    }
    .wrapper{
        margin-top:0px;
    }
    .slider .btn{
        display:none;
    }
    .slider-caption{
        position:absolute;
        z-index:9999;
        width:70%;
        bottom:12% !important;
        left:15%;
    }
    .slider-title{
        font-size:16px;
        margin-bottom:20px;
        color:#fff;
        line-height:21px;
        font-weight:600;
    }
    .slider-content{
        display:none;
    }
    .breakingNews>ul>li>a{
        padding-top:0px;
    }
    .fashion h2{
        font-size:14px;
    }
    .banner img{
        margin:40px auto 0;
    }
    .section{
        margin-top:40px;
    }
    .media-heading{
        font-size:12px;
    }
    .section-comment{
        margin-top:20px;
    }
    .comment-reply{
        margin-left:0px;
    }
    .technology p{
        font-size:12px;
    }
    .social-media{
        margin-top:40px;
    }
    .latest-post{
        margin-top:30px;
    }
    .navbar-toggle .icon-bar{
        border-radius:1px;
        display:block;
        height:2px;
        margin:0 auto;
        width:22px;
    }
    .navbar-toggle{
        background-color:transparent;
        background-image:none;
        border:1px solid transparent;
        border-radius:4px;
        margin-top:8px;
        padding:10px;
        position:relative;
        text-align:center;
        width:100%;
        margin-right:0;
    }
    .footer-wrapper .lifestyle{
        margin-top:20px;
    }
    .lifestyle hr{
        margin-top:10px;
    }
    .header-image{
        margin-bottom:20px;
    }
    .media-object{
        display:block;
        width:50px;
    }
}
@media only screen and (min-width:320px) and (max-width:767px){
    .main-slider-two{
        margin-top:8px;
    }
    .logo img{
        margin:0 auto;
    }
    .wrapper{
        margin-top:0px;
    }
    .slider .btn{
        display:none;
    }
    .slider-caption{
        position:absolute;
        z-index:9999;
        width:70%;
        left:15%;
    }
    .slider-title{
        font-size:16px;
        margin-bottom:20px;
        color:#fff;
        line-height:21px;
        font-weight:600;
    }
    .slider-content{
        display:none;
    }
    .breakingNews>.bn-title{
        padding-top:0px;
    }
    .breakingNews>ul>li>a{
        padding-top:0px;
    }
    .fashion h2{
        font-size:14px;
    }
    .banner img{
        margin:40px auto 0;
    }
    .section{
        margin-top:40px;
    }
    .section-comment{
        margin-top:20px;
    }
    .media-heading{
        font-size:12px;
    }
    .technology p{
        font-size:12px;
    }
    .social-media{
        margin-top:40px;
    }
    .latest-post{
        margin-top:30px;
    }
    .footer-wrapper{
        margin-top:20px;
    }
    .navbar-toggle .icon-bar{
        border-radius:1px;
        display:block;
        height:2px;
        margin:0 auto;
        width:22px;
    }
    .navbar-toggle{
        background-color:transparent;
        background-image:none;
        border:1px solid transparent;
        border-radius:4px;
        margin-top:8px;
        padding:10px;
        position:relative;
        text-align:center;
        width:100%;
        margin-right:0;
    }
    .footer-wrapper .lifestyle{
        margin-top:20px;
    }
    .lifestyle hr{
        margin-top:10px;
    }
    .header-image{
        margin-bottom:20px;
    }
    .media-object{
        display:block;
        width:50px;
    }
}
@media screen and (min-device-width:480px) and (max-device-width:767px) and (orientation:landscape){
    .logo img{
        margin:0 auto;
    }
    .wrapper{
        margin-top:0px;
    }
    .slider .btn{
        display:none;
    }
    .slider-caption{
        position:absolute;
        z-index:9999;
        width:70%;
        bottom:12% !important;
        left:15%;
    }
    .slider-title{
        font-size:16px;
        margin-bottom:20px;
        color:#fff;
        line-height:21px;
        font-weight:600;
    }
    .slider-content{
        display:none;
    }
    .breakingNews>ul>li>a{
        padding-top:0px;
    }
    .fashion h2{
        font-size:16px;
    }
    .banner img{
        margin:40px auto 0;
    }
    .section{
        margin-top:40px;
    }
    .section-comment{
        margin-top:20px;
    }
    .media-heading{
        font-size:14px;
    }
    .technology p{
        font-size:14px;
    }
    .social-media{
        margin-top:40px;
    }
    .latest-post{
        margin-top:30px;
    }
    .footer-wrapper{
        margin-top:20px;
    }
}
@media (min-width:768px) and (max-width:1279px){
    .slider-caption{
        position:absolute;
        z-index:9999;
        width:70%;
        left:15%;
    }
    .slider-title{
        font-size:30px;
        margin-bottom:20px;
        color:#fff;
        font-weight:600;
    }
    .slider-content{
        display:none;
    }
    .breakingNews>.bn-title{
        padding-top:0px;
    }
    .breakingNews>ul>li>a{
        padding-top:0px;
    }
    .social-media{
        margin-top:60px;
    }
    .latest-post{
        margin-top:30px;
    }
    .footer-wrapper .lifestyle{
        margin-top:20px;
    }
    .footer-bottom .navbar-nav > li > a{
        padding:0 15px;
    }
    .navbar-nav > li > a{
        font-size:12px !important;
    }
    .yamm .dropdown .dropdown-menu{
        margin-top:20px;
    }
    .yamm .dropdown.yamm-fw .dropdown-menu{
        margin-top:20px;
    }
    .footer-bottom .navbar-nav > li > a{
        padding:0 15px;
    }
    .footer-wrapper .lifestyle{
        margin-top:20px;
    }
}
@media screen and (min-device-width:768px) and (max-device-width:1279px) and (orientation:landscape){
    .fashion h2{
        font-size:14px;
    }
    .media-heading{
        font-size:12px;
    }
    .social-media{
        margin-top:0px;
    }
}
@media (min-width:992px) and (max-width:1199px){
}
#navbar .menu{
    text-transform:uppercase;
}
#navbar .menu .fa{
    margin-right:5px;
}
.search .btn-primary{
    border:none;
}
#admin-menu{
    position:fixed;
    top:auto !important;
    bottom:0px;
}
#admin-menu li ul{
    bottom:28px;
}
#admin-menu li ul li{
    position:relative;
}
#admin-menu li ul li ul{
    bottom:auto;
    position:absolute;
    bottom:0;
}
.logged-in .footer-bottom{
    padding-bottom:20px;
}
#mini-panel-top_bar i{
    margin-right:6px;
}
#mini-panel-top_bar .nav > li > a:hover{
    text-decoration:none;
    background-color:#FFCB08;
    border-bottom:2px solid #fff;
}
#mini-panel-top_bar .nav > li > a:focus{
    background-color:transparent;
}
#mini-panel-top_bar ul li{
    display:inline-block;
}
#mini-panel-top_bar ul li a{
    color:#222;
}
.pane-block-1 a{
    display:inline-block;
    margin-left:10px;
    color:#222;
}
.pane-block-1 a:hover{
    color:#fff;
}
.pane-block-1{
    padding-top:12px;
}
.pane-system-user-menu{
    padding-top:4px;
    z-index:9999;
}
.breakingNews a{
    color:#fff;
}
.whats-hot .pane-block-3 .btn-default{
    background:#F6C800;
}
#twitter-widget-0{
    max-height:352px;
    overflow-y:scroll;
    width:100%;
}
#embed-responsive-item{
    max-height:350px;
}
.twitter-tweet{
    width:100%;
    padding:20px;
}
.whats-hot h2{
    border-bottom:1px solid #fff;
    padding-bottom:10px;
    text-transform:uppercase;
}
#mini-panel-home_blocks .left h2{
    border-bottom:1px solid #333;
    padding-bottom:10px;
    text-transform:uppercase;
}
.pane-block-10 h2{
    border-bottom:1px solid #333;
    padding-bottom:10px;
    text-transform:uppercase;
}
.pane-block-3 h2{
    border-bottom:1px solid #8DF5BC;
    padding-bottom:10px;
    text-transform:uppercase;
}
#mini-panel-home_blocks .left{
    padding:20px 10px 10px 10px;
    background:#252122 url(/sites/all/themes/anc/images/current_late_president.jpg) no-repeat center;
    color:#ccc;
    height:100%;
}
#mini-panel-home_blocks .right h2{
    border-bottom:1px solid #333;
    padding-bottom:10px;
    text-transform:uppercase;
}
#mini-panel-home_blocks .right{
    color:#000;
    padding:20px 20px 0 20px;
}
#mini-panel-home_blocks .right .views-row{
    padding-top:4px;
    border-bottom:1px dotted #333;
    clear:both;
    overflow:hidden;
    padding-bottom:2px;
}
#mini-panel-home_blocks .right .views-field-field-tweet-linked-images{
    display:inline-block;
    float:left;
    max-height:70px;
    margin-bottom:5px;
}
#mini-panel-home_blocks .right .views-field-field-tweet-linked-images img{
    border:2px solid #fff;
    margin-right:15px;
    width:100px;
}
.dropdown-menu li,.dropdown-menu li a{
    width:100%;
    display:block;
}
#mini-panel-home_blocks .right a{
    color:#000;
    margin-bottom:10px;
}
#mini-panel-home_blocks .right .readmore a{
    color:#fff;
}
#mini-panel-home_blocks .right a:hover{
    color:#666;
}
#mini-panel-home_blocks_2 .left{
    background:#00A652 url(/sites/all/themes/anc/images/women_green.jpg) no-repeat center;
    color:#fff;
    padding:20px;
}
#mini-panel-home_blocks_2 .right{
    background:#222;
    color:#fff;
    padding-top:20px;
    text-align:center;
}
#mini-panel-home_blocks_2 .right .pane-content{
    padding-top:30px;
}
#panel-bootstrap-column-1.fa{
    margin-right:10px;
    color:#222;
}
.readmore,.node-readmore{
    padding:3px 5px;
    background-color:#00A651;
    border-radius:2px;
    margin-top:10px;
    bottom:10px;
}
.readmore a,.node-readmore a{
    color:#fff;
}
.front .whats-hot{
    background-color:#FFCB06;
}
.news-article{
    margin-bottom:10px;
}
.stripes{
    width:100%;
    background:#000;
    height:auto;
    padding:10px 0;
    min-height:500px;
}
.fb-page,._1dro{
    width:100%;
}
.panels-bootstrap-tabs,.region-officials{
    background-color:#fff;
    min-height:475px;
}
.panels-bootstrap-tabs li{
    background-color:#F6C800;
}
.panels-bootstrap-tabs li a{
    color:#000;
}
.panels-bootstrap-tabs .pane-content{
    padding-left:20px;
}
.panels-bootstrap-tabs li:active{
    background-color:#fff;
}
.panels-bootstrap-tabs li:active a{
    color:#333;
}
.panels-bootstrap-tabs li:hover{
    background-color:#FFCD00;
}
.panels-bootstrap-tabs li:hover a{
    color:#333;
}
.news-title{
    margin-bottom:10px;
}
#content-area{
    padding-top:20px;
    padding-bottom:40px;
}
@media screen and (min-width:768px){
    .dropdown:hover .dropdown-menu,.btn-group:hover .dropdown-menu{
        display:block;
    }
    .dropdown-menu{
        top:26px;
        background:#FFD020;
        border-color:#333;
    }
    .dropdown-menu li{
        border-color:#333;
    }
    .dropdown-toggle{
        margin-bottom:2px;
    }
    .navbar .dropdown-toggle,.nav-tabs .dropdown-toggle{
        margin-bottom:0;
    }
}
.not-logged-in .page-user h1{
    display:none;
}
#search-block-form .btn-primary{
    background-color:#00A651;
    border:none;
    margin-top:5px;
}
@media (min-width:720px){
    #user-login{
        width:50%;
        margin-bottom:150px;
    }
    #user-pass{
        width:50%;
        margin-bottom:150px;
    }
}
.read{
    margin-top:30px;
    clear:both;
    background-color:#00A652;
    padding:8px 15px;
    border:1px solid #FFCB06;
    width:102px;
    display:block;
}
.read a{
    color:#fff;
    display:block;
}
.read:hover{
    background-color:#000;
}
.node-readmore{
    background-color:#00A652;
}
.social-icons i:hover{
    color:#FFCB06;
}
.node-readmore:hover{
    background-color:#FFCB06;
    color:#000;
}
#home-video{
    background-color:#00A652;
    padding:35px 20px;
}
.node-readmore a{
    color:#fff;
}
#node-15 .field-item{
    float:left;
    margin:10px;
}
.jcarousel-skin-default{
    background:#fff;
}
.jcarousel-skin-default .jcarousel-item{
    padding:0;
    width:220px;
    height:150px;
    overflow:hidden;
    border:1px solid #333;
    list-style:none;
    background:#fff none;
}
.jcarousel-skin-default .jcarousel-item-horizontal{
    margin:0 4px;
}
.jcarousel-skin-default .jcarousel-container-horizontal{
    width:440px;
    height:190px;
    padding:20px 40px;
    margin:auto;
}
.jcarousel-skin-default .jcarousel-next,.jcarousel-skin-default .jcarousel-prev{
    display:block;
    width:32px;
    height:32px;
    background-image:url(/sites/all/themes/anc/images/arrows.png);
}
.jcarousel-skin-default .jcarousel-next-disabled,.jcarousel-skin-default .jcarousel-prev-disabled{
    display:none;
}
.jcarousel-skin-default .jcarousel-prev-horizontal{
    position:absolute;
    top:80px;
    left:10px;
    background-position:0 0;
}
.jcarousel-skin-default .jcarousel-prev-horizontal:hover{
    background-position:-32px 0;
}
.jcarousel-skin-default .jcarousel-prev-horizontal:active{
    background-position:-64px 0;
}
.jcarousel-skin-default .jcarousel-next-horizontal{
    position:absolute;
    top:80px;
    right:10px;
    background-position:0 -32px;
}
.jcarousel-skin-default .jcarousel-next-horizontal:hover{
    background-position:-32px -32px;
}
.jcarousel-skin-default .jcarousel-next-horizontal:active{
    background-position:-64px -32px;
}
#backtotop{
    background:url(/sites/all/themes/anc/images/backtotop.png) no-repeat center center;
    bottom:40px;
    cursor:pointer;
    display:none;
    height:70px;
    position:fixed;
    right:20px;
    text-indent:-9999px;
    width:70px;
    z-index:1;
}
.compact-form-label{
    margin-top:7px;
    margin-left:10px;
}
#block-views-popular-posts-block .views-field-field-headline-image img{
    border:2px solid #000;
    margin-right:15px;
}
#block-views-popular-posts-block a{
    color:#000;
    margin-bottom:10px;
}
#block-views-popular-posts-block .readmore a{
    color:#fff;
}
#block-views-popular-posts-block a:hover{
    color:#666;
}
#mini-panel-top_bar .dropdown-toggle{
    background-color:transparent;
}
.media-left .img-responsive{
    width:100px !important;
    height:80px !important;
}
#header-blocks{
    bottom:0;
    margin-bottom:0;
}
@media (max-width:760px){
    #header-group{
        background:transparent !important;
        position:relative;
    }
    #header-group h1{
        display:none;
    }
    .top-bar .pane-block-1{
        display:none;
    }
    #mini-panel-top_bar .nav > li > a:hover{
        text-decoration:none;
        background-color:transparent;
        border-top:2px solid #FFCB06;
        border-bottom:none;
    }
    .breakingNews{
        margin-top:12px;
        height:auto;
    }
    .whats-hot{
        margin-top:0 !important;
    }
    .flickity-slider{
        margin-bottom:0 !important;
    }
}
.footer-wrapper .nav > li > a{
    position:relative;
    display:block;
    padding:5px 10px;
    color:#000;
}
.page-contact .panel-default > .panel-heading{
    color:#000;
    background-color:#F3D58D;
    border:#FFCB07 solid 1px;
}
.page-contact .panel-default > .panel-heading a{
    display:block;
}
.page-contact h2{
    padding-bottom:5px;
    margin-bottom:20px;
    border-bottom:4px solid #00A651;
}
#anc ul li a{
    position:relative;
    display:block;
    padding:5px 15px;
    float:left;
    background:transparent;
    color:#fff;
    font-size:12px;
    margin-right:10px;
}
#anc ul li a:hover{
    background:#231F20;
}
#anc ul li{
    position:relative;
    background:transparent;
}
#block-views-officials-block-block-1 .views-field-title{
    text-align:center;
}
#block-views-officials-block-block-1 .views-field-field-picture{
    text-align:center;
}
#block-views-officials-block-block-1 .views-field-field-picture img{
    border:1px solid #FFCB07;
    border-radius:4px;
    padding:5px;
    margin:0 auto;
}
#block-views-officials-block-block-1 .views-field-field-picture img:hover{
    box-shadow:0 0 2px 1px rgba(0,140,186,0.5);
}
#block-views-officials-block-block-1{
    background:#fff;
    padding-left:20px;
    padding-right:20px;
    padding-bottom:15px;
    margin-bottom:30px;
}
@media (min-width:760px){
    #anc ul li a{
        background:#008241;
        margin-right:10px;
    }
}
#block-block-9{
    margin-top:20px;
}
.sharethis-wrapper{
    margin-top:30px;
}
@media only screen and (min-device-width :768px) and (max-device-width :1024px) and (orientation :portrait){
    .social-icons{
        display:none;
    }
}
@media only screen and (min-width :1224px){
    #mini-panel-home_blocks_2 .left,#mini-panel-home_blocks_2 .right,#mini-panel-home_blocks .left,#mini-panel-home_blocks .right{
        height:420px;
    }
    #mini-panel-home_blocks .left p{
        font-size:14px;
        line-height:20px;
    }
    #mini-panel-home_blocks .right .views-row{
        padding-top:8px;
        clear:both;
        overflow:hidden;
    }
    #mini-panel-home_blocks .right a,#mini-panel-home_blocks .right{
        color:#000;
        line-height:14px;
    }
}
@media only screen and (min-width :1300px){
    #mini-panel-home_blocks .left p{
        font-size:14px;
        line-height:26px;
    }
    #mini-panel-home_blocks .right a,#mini-panel-home_blocks .right{
        color:#000;
        line-height:18px;
    }
    #mini-panel-home_blocks_2 .left,#mini-panel-home_blocks_2 .right,#mini-panel-home_blocks .left,#mini-panel-home_blocks .right{
        height:440px !important;
    }
}
@media only screen and (min-width :1600px){
    #mini-panel-home_blocks_2 .left,#mini-panel-home_blocks_2 .right,#mini-panel-home_blocks .left,#mini-panel-home_blocks .right{
        height:450px !important;
    }
    #mini-panel-home_blocks .left p{
        font-size:14px;
        line-height:28px;
    }
    #mini-panel-home_blocks .right a,#mini-panel-home_blocks .right{
        color:#000;
        line-height:24px;
    }
}
@media only screen and (min-width :1680px){
    #mini-panel-home_blocks_2 .left,#mini-panel-home_blocks_2 .right,#mini-panel-home_blocks .left,#mini-panel-home_blocks .right{
        height:480px;
    }
    #mini-panel-home_blocks .left p{
        font-size:14px;
        line-height:28px;
    }
    #mini-panel-home_blocks .right a,#mini-panel-home_blocks .right{
        color:#000;
        line-height:16px;
    }
}
@media only screen and (min-width :1824px){
    #mini-panel-home_blocks_2 .left,#mini-panel-home_blocks_2 .right,#mini-panel-home_blocks .left,#mini-panel-home_blocks .right{
        height:480px !important;
    }
    #mini-panel-home_blocks_2 .right{
        padding-top:5px;
    }
}
.views-field-field-dpf a{
    text-align:center;
    padding:5px;
    background:#D0FFE7;
    border:1px solid #333;
    color:#fff;
}
.views-field-field-dpf{
    text-align:center;
}
#edit-views-send-message-format,#views-form-media-contacts-page #edit-to,#views-form-media-contacts-page #edit-token,.help-block{
    display:none;
}
.main-slider-two .slide-column{
    position:relative;
    padding:0px 2px;
}
.main-slider-two .slide-column .row{
    margin:0px 2px;
}
.main-slider-two .slide-column .inner-slide{
    padding:0px 2px;
}
.news-block-three{
    position:relative;
}
.news-block-three .inner-box{
    position:relative;
    overflow:hidden;
}
.news-block-three .inner-box .image{
    position:relative;
}
.news-block-three .inner-box:hover .image img{
    -webkit-transform :scale(1.2,1.2);
    -ms-transform :scale(1.2,1.2);
    transform :scale(1.2,1.2);
}
.news-block-three .inner-box .image img{
    position:relative;
    width:100%;
    display:block;
    transition:all 0.6s ease;
    -moz-transition:all 0.6s ease;
    -webkit-transition:all 0.6s ease;
    -ms-transition:all 0.6s ease;
    -o-transition:all 0.6s ease;
}
.news-block-three .inner-box .image .overlay-box{
    position:absolute;
    left:0px;
    top:0px;
    width:100%;
    height:100%;
    display:block;
    background:-webkit-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.6));
    background:-moz-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.6));
    background:-o-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.6));
    background:-ms-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.6));
}
.news-block-three .inner-box .image .overlay-box .play-btn{
    position:absolute;
    right:15px;
    top:15px;
    width:34px;
    height:34px;
    line-height:28px;
    display:inline-block;
    border-radius:50%;
    text-align:center;
    color:#ffffff;
    border:3px solid #ffffff;
    transition:all 0.3s ease;
    -moz-transition:all 0.3s ease;
    -webkit-transition:all 0.3s ease;
    -ms-transition:all 0.3s ease;
    -o-transition:all 0.3s ease;
}
.news-block-three .inner-box .image .overlay-box .play-btn .icon{
    font-size:12px;
    padding-left:3px;
}
.news-block-three .inner-box .image .overlay-box .content{
    position:absolute;
    content:'';
    left:0px;
    bottom:0px;
    width:100%;
    text-align:center;
    padding:0px 15px 20px 15px;
}
.news-block-three .inner-box .image .overlay-box .content .tag{
    position:relative;
    color:#ffffff;
    font-size:12px;
    display:inline-block;
    padding:0px 12px;
    font-weight:400;
    margin-bottom:12px;
    text-transform:uppercase;
    background-color:#00A54F;
}
.news-block-three .inner-box .image .overlay-box .content .tag a{
    color:#ffffff;
}
.news-block-three .inner-box .image .overlay-box .content h3{
    position:relative;
    font-weight:700;
    font-size:18px;
    line-height:1.6em;
    margin-bottom:8px;
    text-transform:uppercase;
}
.news-block-three .inner-box .image .overlay-box .content h3 a{
    color:#ffffff;
    transition:all 0.3s ease;
    -moz-transition:all 0.3s ease;
    -webkit-transition:all 0.3s ease;
    -ms-transition:all 0.3s ease;
    -o-transition:all 0.3s ease;
}
.news-block-three .inner-box .image .overlay-box .content h3 a:hover{
    color:#A2FFCE;
}
.news-block-three .inner-box .image .overlay-box .content .post-meta{
    position:relative;
}
.news-block-three .inner-box .image .overlay-box .content .post-meta li{
    position:relative;
    color:#ffffff;
    font-size:11px;
    font-weight:400;
    padding-left:20px;
    line-height:1em;
    margin-right:15px;
    display:inline-block;
    text-transform:uppercase;
}
.news-block-three .inner-box .image .overlay-box .content .post-meta li .icon{
    position:absolute;
    left:0px;
    top:0px;
    font-size:11px;
    color:#ffffff;
}
.news-block-three.style-two .inner-box .image .overlay-box .content h3{
    font-size:30px;
    line-height:1.4em;
}
.news-block-three.style-three .inner-box .image .overlay-box .content h3{
    font-size:26px;
    line-height:1.4em;
}
.news-block-three.style-four .inner-box .image .overlay-box .content h3{
    font-size:22px;
    line-height:1.4em;
}
.news-block .inner-box{
    position:relative;
}
.news-block .inner-box .image{
    position:relative;
}
.news-block .inner-box .image .category{
    position:absolute;
    left:15px;
    bottom:15px;
    color:#ffffff;
    padding:2px 10px;
    display:inline-block;
    text-transform:uppercase;
    letter-spacing:1px;
    background-color:#222222;
}
.news-block .inner-box .image img{
    position:relative;
    width:100%;
    display:block;
}
.news-block .inner-box .lower-box{
    position:relative;
    padding:45px 0px 30px;
    border-bottom:1px solid #eeeeee;
}
.news-block .inner-box .lower-box h3{
    position:relative;
    font-size:32px;
    font-weight:400;
    line-height:1.4em;
    margin-bottom:15px;
    font-family:'Montserrat',sans-serif;
}
.news-block .inner-box .lower-box h3 a{
    color:#333333;
    transition:all 0.3s ease;
    -moz-transition:all 0.3s ease;
    -webkit-transition:all 0.3s ease;
    -ms-transition:all 0.3s ease;
    -o-transition:all 0.3s ease;
}
.news-block .inner-box .lower-box h3 a:hover{
    color:#00A54F;
}
.news-block .inner-box .lower-box .post-meta{
    position:relative;
    margin-bottom:15px;
}
.news-block .inner-box .lower-box .post-meta li{
    position:relative;
    color:#999999;
    font-weight:600;
    font-size:13px;
    padding-left:18px;
    display:inline-block;
    margin-right:20px;
    letter-spacing:1px;
    text-transform:uppercase;
}
.news-block .inner-box .lower-box .post-meta li .icon{
    position:absolute;
    left:0px;
    top:0px;
    font-size:13px;
    color:#999999;
}
.news-block .inner-box .lower-box .text{
    position:relative;
    color:#777777;
    font-size:14px;
    line-height:1.8em;
    margin-bottom:25px;
}
.main-header{
    position:relative;
    left:0px;
    top:0px;
    z-index:999;
    width:100%;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
    background-color:#ffcb07;
}
.main-header .container-fluid{
    padding-right:0px;
    padding-left:0px;
    margin-right:auto;
    margin-left:auto;
}
.main-header .col-md-2,.main-header .col-sm-3,.main-header .col-xs-12,.main-header .col-md-10,.main-header .col-sm-9{
    position:relative;
    min-height:1px;
    padding-right:0px;
    padding-left:0px;
}
#header-group h1{
    margin-left:0px;
    margin-top:40px;
    font-size:32px;
}
#header-group{
    background:transparent !important;
    min-height:120px;
}
.main-header .logo-outer img{
    max-height:160px;
}
.main-header .header-lower{
    bottom:0px;
    width:100%;
    z-index:5;
    background-color:#00A651;
}
.main-header .nav-outer{
    position:relative;
    margin-left:0px;
    padding-left:0px;
    bottom:0;
}
#countdown{
    border:2px solid #00A651;
    border-radius:50%;
    behavior:url(/sites/all/themes/anc/css/PIE.htc);
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    width:70px;
    height:70px;
    padding:15px;
    text-align:center;
    color:#00A651;
    font:32px Arial,sans-serif;
}
#next-event span{
    margin:15px auto;
    display:inline-block;
}
#next-event{
    width:100%;
    margin:0 auto;
    text-align:center;
}
#edit-search-block-form--2{
    background-color:#FFCB08;
    border:1px solid #FFDB4D;
    border-top:1px solid #F7C600;
    color:#00A651;
    margin-top:5px;
}
.view .view--anc-today a{
    font-size:14px;
}
#pane-46 .item-list li{
    padding:10px;
    background-color:#ffff;
    border-bottom:1px dotted #666;
}
#pane-46 .item-list li a{
    font-size:18px;
}
#pane-46 li a{
    display:block;
}
#block-panels-mini-social-feeds .nav-tabs{
    display:none;
}
#pane-46{
    padding-top:30px;
    padding-right:20px;
}
.pane-anc-today .views-field-created{
    color:#666;
}
.page-search .search-info a{
    display:none;
}
#top_benner{
    padding-top:15px;
}
.collapsed .panel-body,.webform-component-select-table{
    display:none !important;
}