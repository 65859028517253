@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Nunito:400,300,500,600,700,800);
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: 'Nunito','Helvetica Neue','Helvetica',Arial,sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
  position: relative;
  height: 100%;
  font-weight: 400;
  overflow-x: hidden;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}

.bootThree article, .bootThree aside, .bootThree details, .bootThree figcaption, .bootThree figure, .bootThree footer, .bootThree header, .bootThree hgroup, .bootThree main, .bootThree menu, .bootThree nav, .bootThree section, .bootThree summary {
  display: block;
}

.bootThree audio, .bootThree canvas, .bootThree progress, .bootThree video {
  display: inline-block;
  vertical-align: baseline;
}

.bootThree audio:not([controls]) {
  display: none;
  height: 0;
}

.bootThree [hidden], .bootThree template {
  display: none;
}

.bootThree a {
  background-color: transparent;
}

.bootThree a:active, .bootThree a:hover {
  outline: 0;
}

.bootThree abbr[title] {
  border-bottom: 1px dotted;
}

.bootThree b, .bootThree strong {
  font-weight: 700;
}

.bootThree dfn {
  font-style: italic;
}

.bootThree h1 {
  margin: .67em 0;
  font-size: 2em;
}

.bootThree mark {
  color: #000;
  background: #ff0;
}

.bootThree small {
  font-size: 80%;
}

.bootThree sub, .bootThree sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

.bootThree sup {
  top: -.5em;
}

.bootThree sub {
  bottom: -.25em;
}

.bootThree img {
  border: 0;
}

.bootThree svg:not(:root) {
  overflow: hidden;
}

.bootThree figure {
  margin: 1em 40px;
}

.bootThree hr {
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.bootThree pre {
  overflow: auto;
}

.bootThree code, .bootThree kbd, .bootThree pre, .bootThree samp {
  font-family: monospace,monospace;
  font-size: 1em;
}

.bootThree button, .bootThree input, .bootThree optgroup, .bootThree select, .bootThree textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

.bootThree button {
  overflow: visible;
}

.bootThree button, .bootThree select {
  text-transform: none;
}

.bootThree button, .bootThree html input[type=button], .bootThree input[type=reset], .bootThree input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

.bootThree button[disabled], .bootThree html input[disabled] {
  cursor: default;
}

.bootThree button::-moz-focus-inner, .bootThree input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.bootThree input {
  line-height: normal;
}

.bootThree input[type=checkbox], .bootThree input[type=radio] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.bootThree input[type=number]::-webkit-inner-spin-button, .bootThree input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

.bootThree input[type=search] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

.bootThree input[type=search]::-webkit-search-cancel-button, .bootThree input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.bootThree fieldset {
  padding: .35em .625em .75em;
  margin: 0 2px;
  border: 1px solid silver;
}

.bootThree legend {
  padding: 0;
  border: 0;
}

.bootThree textarea {
  overflow: auto;
}

.bootThree optgroup {
  font-weight: 700;
}

.bootThree table {
  border-spacing: 0;
  border-collapse: collapse;
}

.bootThree td, .bootThree th {
  padding: 0;
}

@media print {
  .bootThree *, .bootThree :after, .bootThree :before {
    color: #000 !important;
    text-shadow: none !important;
    background: 0 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .bootThree a, .bootThree a:visited {
    text-decoration: underline;
  }
  .bootThree a[href]:after {
    content: " (" attr(href) ")";
  }
  .bootThree abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .bootThree a[href^="javascript:"]:after, .bootThree a[href^="#"]:after {
    content: "";
  }
  .bootThree blockquote, .bootThree pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  .bootThree thead {
    display: table-header-group;
  }
  .bootThree img, .bootThree tr {
    page-break-inside: avoid;
  }
  .bootThree img {
    max-width: 100% !important;
  }
  .bootThree h2, .bootThree h3, .bootThree p {
    orphans: 3;
    widows: 3;
  }
  .bootThree h2, .bootThree h3 {
    page-break-after: avoid;
  }
  .bootThree .navbar {
    display: none;
  }
  .bootThree .btn > .caret, .bootThree .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }
  .bootThree .label {
    border: 1px solid #000;
  }
  .bootThree .table {
    border-collapse: collapse !important;
  }
  .bootThree .table td, .bootThree .table th {
    background-color: #fff !important;
  }
  .bootThree .table-bordered td, .bootThree .table-bordered th {
    border: 1px solid #ddd !important;
  }
}

@font-face {
  .bootThree {
    font-family: 'Glyphicons Halflings';
    src: url(/sites/all/themes/anc/fonts/glyphicons-halflings-regular.eot);
    src: url(/sites/all/themes/anc/fonts/glyphicons-halflings-regular.eot?#iefix) format("embedded-opentype"), url(/sites/all/themes/anc/fonts/glyphicons-halflings-regular.woff2) format("woff2"), url(/sites/all/themes/anc/fonts/glyphicons-halflings-regular.woff) format("woff"), url(/sites/all/themes/anc/fonts/glyphicons-halflings-regular.ttf) format("truetype"), url(/sites/all/themes/anc/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular) format("svg");
  }
}

.bootThree .glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bootThree .glyphicon-asterisk:before {
  content: "\002a";
}

.bootThree .glyphicon-plus:before {
  content: "\002b";
}

.bootThree .glyphicon-eur:before, .bootThree .glyphicon-euro:before {
  content: "\20ac";
}

.bootThree .glyphicon-minus:before {
  content: "\2212";
}

.bootThree .glyphicon-cloud:before {
  content: "\2601";
}

.bootThree .glyphicon-envelope:before {
  content: "\2709";
}

.bootThree .glyphicon-pencil:before {
  content: "\270f";
}

.bootThree .glyphicon-glass:before {
  content: "\e001";
}

.bootThree .glyphicon-music:before {
  content: "\e002";
}

.bootThree .glyphicon-search:before {
  content: "\e003";
}

.bootThree .glyphicon-heart:before {
  content: "\e005";
}

.bootThree .glyphicon-star:before {
  content: "\e006";
}

.bootThree .glyphicon-star-empty:before {
  content: "\e007";
}

.bootThree .glyphicon-user:before {
  content: "\e008";
}

.bootThree .glyphicon-film:before {
  content: "\e009";
}

.bootThree .glyphicon-th-large:before {
  content: "\e010";
}

.bootThree .glyphicon-th:before {
  content: "\e011";
}

.bootThree .glyphicon-th-list:before {
  content: "\e012";
}

.bootThree .glyphicon-ok:before {
  content: "\e013";
}

.bootThree .glyphicon-remove:before {
  content: "\e014";
}

.bootThree .glyphicon-zoom-in:before {
  content: "\e015";
}

.bootThree .glyphicon-zoom-out:before {
  content: "\e016";
}

.bootThree .glyphicon-off:before {
  content: "\e017";
}

.bootThree .glyphicon-signal:before {
  content: "\e018";
}

.bootThree .glyphicon-cog:before {
  content: "\e019";
}

.bootThree .glyphicon-trash:before {
  content: "\e020";
}

.bootThree .glyphicon-home:before {
  content: "\e021";
}

.bootThree .glyphicon-file:before {
  content: "\e022";
}

.bootThree .glyphicon-time:before {
  content: "\e023";
}

.bootThree .glyphicon-road:before {
  content: "\e024";
}

.bootThree .glyphicon-download-alt:before {
  content: "\e025";
}

.bootThree .glyphicon-download:before {
  content: "\e026";
}

.bootThree .glyphicon-upload:before {
  content: "\e027";
}

.bootThree .glyphicon-inbox:before {
  content: "\e028";
}

.bootThree .glyphicon-play-circle:before {
  content: "\e029";
}

.bootThree .glyphicon-repeat:before {
  content: "\e030";
}

.bootThree .glyphicon-refresh:before {
  content: "\e031";
}

.bootThree .glyphicon-list-alt:before {
  content: "\e032";
}

.bootThree .glyphicon-lock:before {
  content: "\e033";
}

.bootThree .glyphicon-flag:before {
  content: "\e034";
}

.bootThree .glyphicon-headphones:before {
  content: "\e035";
}

.bootThree .glyphicon-volume-off:before {
  content: "\e036";
}

.bootThree .glyphicon-volume-down:before {
  content: "\e037";
}

.bootThree .glyphicon-volume-up:before {
  content: "\e038";
}

.bootThree .glyphicon-qrcode:before {
  content: "\e039";
}

.bootThree .glyphicon-barcode:before {
  content: "\e040";
}

.bootThree .glyphicon-tag:before {
  content: "\e041";
}

.bootThree .glyphicon-tags:before {
  content: "\e042";
}

.bootThree .glyphicon-book:before {
  content: "\e043";
}

.bootThree .glyphicon-bookmark:before {
  content: "\e044";
}

.bootThree .glyphicon-print:before {
  content: "\e045";
}

.bootThree .glyphicon-camera:before {
  content: "\e046";
}

.bootThree .glyphicon-font:before {
  content: "\e047";
}

.bootThree .glyphicon-bold:before {
  content: "\e048";
}

.bootThree .glyphicon-italic:before {
  content: "\e049";
}

.bootThree .glyphicon-text-height:before {
  content: "\e050";
}

.bootThree .glyphicon-text-width:before {
  content: "\e051";
}

.bootThree .glyphicon-align-left:before {
  content: "\e052";
}

.bootThree .glyphicon-align-center:before {
  content: "\e053";
}

.bootThree .glyphicon-align-right:before {
  content: "\e054";
}

.bootThree .glyphicon-align-justify:before {
  content: "\e055";
}

.bootThree .glyphicon-list:before {
  content: "\e056";
}

.bootThree .glyphicon-indent-left:before {
  content: "\e057";
}

.bootThree .glyphicon-indent-right:before {
  content: "\e058";
}

.bootThree .glyphicon-facetime-video:before {
  content: "\e059";
}

.bootThree .glyphicon-picture:before {
  content: "\e060";
}

.bootThree .glyphicon-map-marker:before {
  content: "\e062";
}

.bootThree .glyphicon-adjust:before {
  content: "\e063";
}

.bootThree .glyphicon-tint:before {
  content: "\e064";
}

.bootThree .glyphicon-edit:before {
  content: "\e065";
}

.bootThree .glyphicon-share:before {
  content: "\e066";
}

.bootThree .glyphicon-check:before {
  content: "\e067";
}

.bootThree .glyphicon-move:before {
  content: "\e068";
}

.bootThree .glyphicon-step-backward:before {
  content: "\e069";
}

.bootThree .glyphicon-fast-backward:before {
  content: "\e070";
}

.bootThree .glyphicon-backward:before {
  content: "\e071";
}

.bootThree .glyphicon-play:before {
  content: "\e072";
}

.bootThree .glyphicon-pause:before {
  content: "\e073";
}

.bootThree .glyphicon-stop:before {
  content: "\e074";
}

.bootThree .glyphicon-forward:before {
  content: "\e075";
}

.bootThree .glyphicon-fast-forward:before {
  content: "\e076";
}

.bootThree .glyphicon-step-forward:before {
  content: "\e077";
}

.bootThree .glyphicon-eject:before {
  content: "\e078";
}

.bootThree .glyphicon-chevron-left:before {
  content: "\e079";
}

.bootThree .glyphicon-chevron-right:before {
  content: "\e080";
}

.bootThree .glyphicon-plus-sign:before {
  content: "\e081";
}

.bootThree .glyphicon-minus-sign:before {
  content: "\e082";
}

.bootThree .glyphicon-remove-sign:before {
  content: "\e083";
}

.bootThree .glyphicon-ok-sign:before {
  content: "\e084";
}

.bootThree .glyphicon-question-sign:before {
  content: "\e085";
}

.bootThree .glyphicon-info-sign:before {
  content: "\e086";
}

.bootThree .glyphicon-screenshot:before {
  content: "\e087";
}

.bootThree .glyphicon-remove-circle:before {
  content: "\e088";
}

.bootThree .glyphicon-ok-circle:before {
  content: "\e089";
}

.bootThree .glyphicon-ban-circle:before {
  content: "\e090";
}

.bootThree .glyphicon-arrow-left:before {
  content: "\e091";
}

.bootThree .glyphicon-arrow-right:before {
  content: "\e092";
}

.bootThree .glyphicon-arrow-up:before {
  content: "\e093";
}

.bootThree .glyphicon-arrow-down:before {
  content: "\e094";
}

.bootThree .glyphicon-share-alt:before {
  content: "\e095";
}

.bootThree .glyphicon-resize-full:before {
  content: "\e096";
}

.bootThree .glyphicon-resize-small:before {
  content: "\e097";
}

.bootThree .glyphicon-exclamation-sign:before {
  content: "\e101";
}

.bootThree .glyphicon-gift:before {
  content: "\e102";
}

.bootThree .glyphicon-leaf:before {
  content: "\e103";
}

.bootThree .glyphicon-fire:before {
  content: "\e104";
}

.bootThree .glyphicon-eye-open:before {
  content: "\e105";
}

.bootThree .glyphicon-eye-close:before {
  content: "\e106";
}

.bootThree .glyphicon-warning-sign:before {
  content: "\e107";
}

.bootThree .glyphicon-plane:before {
  content: "\e108";
}

.bootThree .glyphicon-calendar:before {
  content: "\e109";
}

.bootThree .glyphicon-random:before {
  content: "\e110";
}

.bootThree .glyphicon-comment:before {
  content: "\e111";
}

.bootThree .glyphicon-magnet:before {
  content: "\e112";
}

.bootThree .glyphicon-chevron-up:before {
  content: "\e113";
}

.bootThree .glyphicon-chevron-down:before {
  content: "\e114";
}

.bootThree .glyphicon-retweet:before {
  content: "\e115";
}

.bootThree .glyphicon-shopping-cart:before {
  content: "\e116";
}

.bootThree .glyphicon-folder-close:before {
  content: "\e117";
}

.bootThree .glyphicon-folder-open:before {
  content: "\e118";
}

.bootThree .glyphicon-resize-vertical:before {
  content: "\e119";
}

.bootThree .glyphicon-resize-horizontal:before {
  content: "\e120";
}

.bootThree .glyphicon-hdd:before {
  content: "\e121";
}

.bootThree .glyphicon-bullhorn:before {
  content: "\e122";
}

.bootThree .glyphicon-bell:before {
  content: "\e123";
}

.bootThree .glyphicon-certificate:before {
  content: "\e124";
}

.bootThree .glyphicon-thumbs-up:before {
  content: "\e125";
}

.bootThree .glyphicon-thumbs-down:before {
  content: "\e126";
}

.bootThree .glyphicon-hand-right:before {
  content: "\e127";
}

.bootThree .glyphicon-hand-left:before {
  content: "\e128";
}

.bootThree .glyphicon-hand-up:before {
  content: "\e129";
}

.bootThree .glyphicon-hand-down:before {
  content: "\e130";
}

.bootThree .glyphicon-circle-arrow-right:before {
  content: "\e131";
}

.bootThree .glyphicon-circle-arrow-left:before {
  content: "\e132";
}

.bootThree .glyphicon-circle-arrow-up:before {
  content: "\e133";
}

.bootThree .glyphicon-circle-arrow-down:before {
  content: "\e134";
}

.bootThree .glyphicon-globe:before {
  content: "\e135";
}

.bootThree .glyphicon-wrench:before {
  content: "\e136";
}

.bootThree .glyphicon-tasks:before {
  content: "\e137";
}

.bootThree .glyphicon-filter:before {
  content: "\e138";
}

.bootThree .glyphicon-briefcase:before {
  content: "\e139";
}

.bootThree .glyphicon-fullscreen:before {
  content: "\e140";
}

.bootThree .glyphicon-dashboard:before {
  content: "\e141";
}

.bootThree .glyphicon-paperclip:before {
  content: "\e142";
}

.bootThree .glyphicon-heart-empty:before {
  content: "\e143";
}

.bootThree .glyphicon-link:before {
  content: "\e144";
}

.bootThree .glyphicon-phone:before {
  content: "\e145";
}

.bootThree .glyphicon-pushpin:before {
  content: "\e146";
}

.bootThree .glyphicon-usd:before {
  content: "\e148";
}

.bootThree .glyphicon-gbp:before {
  content: "\e149";
}

.bootThree .glyphicon-sort:before {
  content: "\e150";
}

.bootThree .glyphicon-sort-by-alphabet:before {
  content: "\e151";
}

.bootThree .glyphicon-sort-by-alphabet-alt:before {
  content: "\e152";
}

.bootThree .glyphicon-sort-by-order:before {
  content: "\e153";
}

.bootThree .glyphicon-sort-by-order-alt:before {
  content: "\e154";
}

.bootThree .glyphicon-sort-by-attributes:before {
  content: "\e155";
}

.bootThree .glyphicon-sort-by-attributes-alt:before {
  content: "\e156";
}

.bootThree .glyphicon-unchecked:before {
  content: "\e157";
}

.bootThree .glyphicon-expand:before {
  content: "\e158";
}

.bootThree .glyphicon-collapse-down:before {
  content: "\e159";
}

.bootThree .glyphicon-collapse-up:before {
  content: "\e160";
}

.bootThree .glyphicon-log-in:before {
  content: "\e161";
}

.bootThree .glyphicon-flash:before {
  content: "\e162";
}

.bootThree .glyphicon-log-out:before {
  content: "\e163";
}

.bootThree .glyphicon-new-window:before {
  content: "\e164";
}

.bootThree .glyphicon-record:before {
  content: "\e165";
}

.bootThree .glyphicon-save:before {
  content: "\e166";
}

.bootThree .glyphicon-open:before {
  content: "\e167";
}

.bootThree .glyphicon-saved:before {
  content: "\e168";
}

.bootThree .glyphicon-import:before {
  content: "\e169";
}

.bootThree .glyphicon-export:before {
  content: "\e170";
}

.bootThree .glyphicon-send:before {
  content: "\e171";
}

.bootThree .glyphicon-floppy-disk:before {
  content: "\e172";
}

.bootThree .glyphicon-floppy-saved:before {
  content: "\e173";
}

.bootThree .glyphicon-floppy-remove:before {
  content: "\e174";
}

.bootThree .glyphicon-floppy-save:before {
  content: "\e175";
}

.bootThree .glyphicon-floppy-open:before {
  content: "\e176";
}

.bootThree .glyphicon-credit-card:before {
  content: "\e177";
}

.bootThree .glyphicon-transfer:before {
  content: "\e178";
}

.bootThree .glyphicon-cutlery:before {
  content: "\e179";
}

.bootThree .glyphicon-header:before {
  content: "\e180";
}

.bootThree .glyphicon-compressed:before {
  content: "\e181";
}

.bootThree .glyphicon-earphone:before {
  content: "\e182";
}

.bootThree .glyphicon-phone-alt:before {
  content: "\e183";
}

.bootThree .glyphicon-tower:before {
  content: "\e184";
}

.bootThree .glyphicon-stats:before {
  content: "\e185";
}

.bootThree .glyphicon-sd-video:before {
  content: "\e186";
}

.bootThree .glyphicon-hd-video:before {
  content: "\e187";
}

.bootThree .glyphicon-subtitles:before {
  content: "\e188";
}

.bootThree .glyphicon-sound-stereo:before {
  content: "\e189";
}

.bootThree .glyphicon-sound-dolby:before {
  content: "\e190";
}

.bootThree .glyphicon-sound-5-1:before {
  content: "\e191";
}

.bootThree .glyphicon-sound-6-1:before {
  content: "\e192";
}

.bootThree .glyphicon-sound-7-1:before {
  content: "\e193";
}

.bootThree .glyphicon-copyright-mark:before {
  content: "\e194";
}

.bootThree .glyphicon-registration-mark:before {
  content: "\e195";
}

.bootThree .glyphicon-cloud-download:before {
  content: "\e197";
}

.bootThree .glyphicon-cloud-upload:before {
  content: "\e198";
}

.bootThree .glyphicon-tree-conifer:before {
  content: "\e199";
}

.bootThree .glyphicon-tree-deciduous:before {
  content: "\e200";
}

.bootThree .glyphicon-cd:before {
  content: "\e201";
}

.bootThree .glyphicon-save-file:before {
  content: "\e202";
}

.bootThree .glyphicon-open-file:before {
  content: "\e203";
}

.bootThree .glyphicon-level-up:before {
  content: "\e204";
}

.bootThree .glyphicon-copy:before {
  content: "\e205";
}

.bootThree .glyphicon-paste:before {
  content: "\e206";
}

.bootThree .glyphicon-alert:before {
  content: "\e209";
}

.bootThree .glyphicon-equalizer:before {
  content: "\e210";
}

.bootThree .glyphicon-king:before {
  content: "\e211";
}

.bootThree .glyphicon-queen:before {
  content: "\e212";
}

.bootThree .glyphicon-pawn:before {
  content: "\e213";
}

.bootThree .glyphicon-bishop:before {
  content: "\e214";
}

.bootThree .glyphicon-knight:before {
  content: "\e215";
}

.bootThree .glyphicon-baby-formula:before {
  content: "\e216";
}

.bootThree .glyphicon-tent:before {
  content: "\26fa";
}

.bootThree .glyphicon-blackboard:before {
  content: "\e218";
}

.bootThree .glyphicon-bed:before {
  content: "\e219";
}

.bootThree .glyphicon-apple:before {
  content: "\f8ff";
}

.bootThree .glyphicon-erase:before {
  content: "\e221";
}

.bootThree .glyphicon-hourglass:before {
  content: "\231b";
}

.bootThree .glyphicon-lamp:before {
  content: "\e223";
}

.bootThree .glyphicon-duplicate:before {
  content: "\e224";
}

.bootThree .glyphicon-piggy-bank:before {
  content: "\e225";
}

.bootThree .glyphicon-scissors:before {
  content: "\e226";
}

.bootThree .glyphicon-bitcoin:before {
  content: "\e227";
}

.bootThree .glyphicon-btc:before {
  content: "\e227";
}

.bootThree .glyphicon-xbt:before {
  content: "\e227";
}

.bootThree .glyphicon-yen:before {
  content: "\00a5";
}

.bootThree .glyphicon-jpy:before {
  content: "\00a5";
}

.bootThree .glyphicon-ruble:before {
  content: "\20bd";
}

.bootThree .glyphicon-rub:before {
  content: "\20bd";
}

.bootThree .glyphicon-scale:before {
  content: "\e230";
}

.bootThree .glyphicon-ice-lolly:before {
  content: "\e231";
}

.bootThree .glyphicon-ice-lolly-tasted:before {
  content: "\e232";
}

.bootThree .glyphicon-education:before {
  content: "\e233";
}

.bootThree .glyphicon-option-horizontal:before {
  content: "\e234";
}

.bootThree .glyphicon-option-vertical:before {
  content: "\e235";
}

.bootThree .glyphicon-menu-hamburger:before {
  content: "\e236";
}

.bootThree .glyphicon-modal-window:before {
  content: "\e237";
}

.bootThree .glyphicon-oil:before {
  content: "\e238";
}

.bootThree .glyphicon-grain:before {
  content: "\e239";
}

.bootThree .glyphicon-sunglasses:before {
  content: "\e240";
}

.bootThree .glyphicon-text-size:before {
  content: "\e241";
}

.bootThree .glyphicon-text-color:before {
  content: "\e242";
}

.bootThree .glyphicon-text-background:before {
  content: "\e243";
}

.bootThree .glyphicon-object-align-top:before {
  content: "\e244";
}

.bootThree .glyphicon-object-align-bottom:before {
  content: "\e245";
}

.bootThree .glyphicon-object-align-horizontal:before {
  content: "\e246";
}

.bootThree .glyphicon-object-align-left:before {
  content: "\e247";
}

.bootThree .glyphicon-object-align-vertical:before {
  content: "\e248";
}

.bootThree .glyphicon-object-align-right:before {
  content: "\e249";
}

.bootThree .glyphicon-triangle-right:before {
  content: "\e250";
}

.bootThree .glyphicon-triangle-left:before {
  content: "\e251";
}

.bootThree .glyphicon-triangle-bottom:before {
  content: "\e252";
}

.bootThree .glyphicon-triangle-top:before {
  content: "\e253";
}

.bootThree .glyphicon-console:before {
  content: "\e254";
}

.bootThree .glyphicon-superscript:before {
  content: "\e255";
}

.bootThree .glyphicon-subscript:before {
  content: "\e256";
}

.bootThree .glyphicon-menu-left:before {
  content: "\e257";
}

.bootThree .glyphicon-menu-right:before {
  content: "\e258";
}

.bootThree .glyphicon-menu-down:before {
  content: "\e259";
}

.bootThree .glyphicon-menu-up:before {
  content: "\e260";
}

.bootThree * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bootThree :after, .bootThree :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bootThree html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.bootThree button, .bootThree input, .bootThree select, .bootThree textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.bootThree a {
  color: #337ab7;
  text-decoration: none;
}

.bootThree a:focus, .bootThree a:hover {
  color: #23527c;
  text-decoration: underline;
}

.bootThree a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.bootThree figure {
  margin: 0;
}

.bootThree img {
  vertical-align: middle;
}

.bootThree .carousel-inner > .item > a > img, .bootThree .carousel-inner > .item > img, .bootThree .img-responsive, .bootThree .thumbnail a > img, .bootThree .thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto;
}

.bootThree .img-rounded {
  border-radius: 6px;
}

.bootThree .img-thumbnail {
  display: inline-block;
  max-width: 100%;
  height: auto;
  padding: 4px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.bootThree .img-circle {
  border-radius: 50%;
}

.bootThree hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

.bootThree .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.bootThree .sr-only-focusable:active, .bootThree .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.bootThree [role=button] {
  cursor: pointer;
}

.bootThree .h1, .bootThree .h2, .bootThree .h3, .bootThree .h4, .bootThree .h5, .bootThree .h6, .bootThree h1, .bootThree h2, .bootThree h3, .bootThree h4, .bootThree h5, .bootThree h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.bootThree .h1 .small, .bootThree .h1 small, .bootThree .h2 .small, .bootThree .h2 small, .bootThree .h3 .small, .bootThree .h3 small, .bootThree .h4 .small, .bootThree .h4 small, .bootThree .h5 .small, .bootThree .h5 small, .bootThree .h6 .small, .bootThree .h6 small, .bootThree h1 .small, .bootThree h1 small, .bootThree h2 .small, .bootThree h2 small, .bootThree h3 .small, .bootThree h3 small, .bootThree h4 .small, .bootThree h4 small, .bootThree h5 .small, .bootThree h5 small, .bootThree h6 .small, .bootThree h6 small {
  font-weight: 400;
  line-height: 1;
  color: #777;
}

.bootThree .h1, .bootThree .h2, .bootThree .h3, .bootThree h1, .bootThree h2, .bootThree h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.bootThree .h1 .small, .bootThree .h1 small, .bootThree .h2 .small, .bootThree .h2 small, .bootThree .h3 .small, .bootThree .h3 small, .bootThree h1 .small, .bootThree h1 small, .bootThree h2 .small, .bootThree h2 small, .bootThree h3 .small, .bootThree h3 small {
  font-size: 65%;
}

.bootThree .h4, .bootThree .h5, .bootThree .h6, .bootThree h4, .bootThree h5, .bootThree h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.bootThree .h4 .small, .bootThree .h4 small, .bootThree .h5 .small, .bootThree .h5 small, .bootThree .h6 .small, .bootThree .h6 small, .bootThree h4 .small, .bootThree h4 small, .bootThree h5 .small, .bootThree h5 small, .bootThree h6 .small, .bootThree h6 small {
  font-size: 75%;
}

.bootThree .h1, .bootThree h1 {
  font-size: 36px;
}

.bootThree .h2, .bootThree h2 {
  font-size: 30px;
}

.bootThree .h3, .bootThree h3 {
  font-size: 24px;
}

.bootThree .h4, .bootThree h4 {
  font-size: 18px;
}

.bootThree .h5, .bootThree h5 {
  font-size: 14px;
}

.bootThree .h6, .bootThree h6 {
  font-size: 12px;
}

.bootThree p {
  margin: 0 0 10px;
}

.bootThree .lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .bootThree .lead {
    font-size: 21px;
  }
}

.bootThree .small, .bootThree small {
  font-size: 85%;
}

.bootThree .mark, .bootThree mark {
  padding: .2em;
  background-color: #fcf8e3;
}

.bootThree .text-left {
  text-align: left;
}

.bootThree .text-right {
  text-align: right;
}

.bootThree .text-center {
  text-align: center;
}

.bootThree .text-justify {
  text-align: justify;
}

.bootThree .text-nowrap {
  white-space: nowrap;
}

.bootThree .text-lowercase {
  text-transform: lowercase;
}

.bootThree .text-uppercase {
  text-transform: uppercase;
}

.bootThree .text-capitalize {
  text-transform: capitalize;
}

.bootThree .text-muted {
  color: #777;
}

.bootThree .text-primary {
  color: #337ab7;
}

.bootThree a.text-primary:focus, .bootThree a.text-primary:hover {
  color: #286090;
}

.bootThree .text-success {
  color: #3c763d;
}

.bootThree a.text-success:focus, .bootThree a.text-success:hover {
  color: #2b542c;
}

.bootThree .text-info {
  color: #31708f;
}

.bootThree a.text-info:focus, .bootThree a.text-info:hover {
  color: #245269;
}

.bootThree .text-warning {
  color: #8a6d3b;
}

.bootThree a.text-warning:focus, .bootThree a.text-warning:hover {
  color: #66512c;
}

.bootThree .text-danger {
  color: #a94442;
}

.bootThree a.text-danger:focus, .bootThree a.text-danger:hover {
  color: #843534;
}

.bootThree .bg-primary {
  color: #fff;
  background-color: #337ab7;
}

.bootThree a.bg-primary:focus, .bootThree a.bg-primary:hover {
  background-color: #286090;
}

.bootThree .bg-success {
  background-color: #dff0d8;
}

.bootThree a.bg-success:focus, .bootThree a.bg-success:hover {
  background-color: #c1e2b3;
}

.bootThree .bg-info {
  background-color: #d9edf7;
}

.bootThree a.bg-info:focus, .bootThree a.bg-info:hover {
  background-color: #afd9ee;
}

.bootThree .bg-warning {
  background-color: #fcf8e3;
}

.bootThree a.bg-warning:focus, .bootThree a.bg-warning:hover {
  background-color: #f7ecb5;
}

.bootThree .bg-danger {
  background-color: #f2dede;
}

.bootThree a.bg-danger:focus, .bootThree a.bg-danger:hover {
  background-color: #e4b9b9;
}

.bootThree .page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}

.bootThree ol, .bootThree ul {
  margin-top: 0;
  margin-bottom: 10px;
}

.bootThree ol ol, .bootThree ol ul, .bootThree ul ol, .bootThree ul ul {
  margin-bottom: 0;
}

.bootThree .list-unstyled {
  padding-left: 0;
  list-style: none;
}

.bootThree .list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}

.bootThree .list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

.bootThree dl {
  margin-top: 0;
  margin-bottom: 20px;
}

.bootThree dd, .bootThree dt {
  line-height: 1.42857143;
}

.bootThree dt {
  font-weight: 700;
}

.bootThree dd {
  margin-left: 0;
}

@media (min-width: 768px) {
  .bootThree .dl-horizontal dt {
    float: left;
    width: 160px;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .bootThree .dl-horizontal dd {
    margin-left: 180px;
  }
}

.bootThree abbr[data-original-title], .bootThree abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #777;
}

.bootThree .initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.bootThree blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

.bootThree blockquote ol:last-child, .bootThree blockquote p:last-child, .bootThree blockquote ul:last-child {
  margin-bottom: 0;
}

.bootThree blockquote .small, .bootThree blockquote footer, .bootThree blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777;
}

.bootThree blockquote .small:before, .bootThree blockquote footer:before, .bootThree blockquote small:before {
  content: '\2014 \00A0';
}

.bootThree .blockquote-reverse, .bootThree blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #eee;
  border-left: 0;
}

.bootThree .blockquote-reverse .small:before, .bootThree .blockquote-reverse footer:before, .bootThree .blockquote-reverse small:before, .bootThree blockquote.pull-right .small:before, .bootThree blockquote.pull-right footer:before, .bootThree blockquote.pull-right small:before {
  content: '';
}

.bootThree .blockquote-reverse .small:after, .bootThree .blockquote-reverse footer:after, .bootThree .blockquote-reverse small:after, .bootThree blockquote.pull-right .small:after, .bootThree blockquote.pull-right footer:after, .bootThree blockquote.pull-right small:after {
  content: '\00A0 \2014';
}

.bootThree address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857143;
}

.bootThree code, .bootThree kbd, .bootThree pre, .bootThree samp {
  font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
}

.bootThree code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

.bootThree kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.bootThree kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bootThree pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.bootThree pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}

.bootThree .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.bootThree .container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .bootThree .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .bootThree .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .bootThree .container {
    width: 1170px;
  }
}

.bootThree .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.bootThree .row {
  margin-right: -15px;
  margin-left: -15px;
}

.bootThree .col-lg-1, .bootThree .col-lg-10, .bootThree .col-lg-11, .bootThree .col-lg-12, .bootThree .col-lg-2, .bootThree .col-lg-3, .bootThree .col-lg-4, .bootThree .col-lg-5, .bootThree .col-lg-6, .bootThree .col-lg-7, .bootThree .col-lg-8, .bootThree .col-lg-9, .bootThree .col-md-1, .bootThree .col-md-10, .bootThree .col-md-11, .bootThree .col-md-12, .bootThree .col-md-2, .bootThree .col-md-3, .bootThree .col-md-4, .bootThree .col-md-5, .bootThree .col-md-6, .bootThree .col-md-7, .bootThree .col-md-8, .bootThree .col-md-9, .bootThree .col-sm-1, .bootThree .col-sm-10, .bootThree .col-sm-11, .bootThree .col-sm-12, .bootThree .col-sm-2, .bootThree .col-sm-3, .bootThree .col-sm-4, .bootThree .col-sm-5, .bootThree .col-sm-6, .bootThree .col-sm-7, .bootThree .col-sm-8, .bootThree .col-sm-9, .bootThree .col-xs-1, .bootThree .col-xs-10, .bootThree .col-xs-11, .bootThree .col-xs-12, .bootThree .col-xs-2, .bootThree .col-xs-3, .bootThree .col-xs-4, .bootThree .col-xs-5, .bootThree .col-xs-6, .bootThree .col-xs-7, .bootThree .col-xs-8, .bootThree .col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.bootThree .col-xs-1, .bootThree .col-xs-10, .bootThree .col-xs-11, .bootThree .col-xs-12, .bootThree .col-xs-2, .bootThree .col-xs-3, .bootThree .col-xs-4, .bootThree .col-xs-5, .bootThree .col-xs-6, .bootThree .col-xs-7, .bootThree .col-xs-8, .bootThree .col-xs-9 {
  float: left;
}

.bootThree .col-xs-12 {
  width: 100%;
}

.bootThree .col-xs-11 {
  width: 91.66666667%;
}

.bootThree .col-xs-10 {
  width: 83.33333333%;
}

.bootThree .col-xs-9 {
  width: 75%;
}

.bootThree .col-xs-8 {
  width: 66.66666667%;
}

.bootThree .col-xs-7 {
  width: 58.33333333%;
}

.bootThree .col-xs-6 {
  width: 50%;
}

.bootThree .col-xs-5 {
  width: 41.66666667%;
}

.bootThree .col-xs-4 {
  width: 33.33333333%;
}

.bootThree .col-xs-3 {
  width: 25%;
}

.bootThree .col-xs-2 {
  width: 16.66666667%;
}

.bootThree .col-xs-1 {
  width: 8.33333333%;
}

.bootThree .col-xs-pull-12 {
  right: 100%;
}

.bootThree .col-xs-pull-11 {
  right: 91.66666667%;
}

.bootThree .col-xs-pull-10 {
  right: 83.33333333%;
}

.bootThree .col-xs-pull-9 {
  right: 75%;
}

.bootThree .col-xs-pull-8 {
  right: 66.66666667%;
}

.bootThree .col-xs-pull-7 {
  right: 58.33333333%;
}

.bootThree .col-xs-pull-6 {
  right: 50%;
}

.bootThree .col-xs-pull-5 {
  right: 41.66666667%;
}

.bootThree .col-xs-pull-4 {
  right: 33.33333333%;
}

.bootThree .col-xs-pull-3 {
  right: 25%;
}

.bootThree .col-xs-pull-2 {
  right: 16.66666667%;
}

.bootThree .col-xs-pull-1 {
  right: 8.33333333%;
}

.bootThree .col-xs-pull-0 {
  right: auto;
}

.bootThree .col-xs-push-12 {
  left: 100%;
}

.bootThree .col-xs-push-11 {
  left: 91.66666667%;
}

.bootThree .col-xs-push-10 {
  left: 83.33333333%;
}

.bootThree .col-xs-push-9 {
  left: 75%;
}

.bootThree .col-xs-push-8 {
  left: 66.66666667%;
}

.bootThree .col-xs-push-7 {
  left: 58.33333333%;
}

.bootThree .col-xs-push-6 {
  left: 50%;
}

.bootThree .col-xs-push-5 {
  left: 41.66666667%;
}

.bootThree .col-xs-push-4 {
  left: 33.33333333%;
}

.bootThree .col-xs-push-3 {
  left: 25%;
}

.bootThree .col-xs-push-2 {
  left: 16.66666667%;
}

.bootThree .col-xs-push-1 {
  left: 8.33333333%;
}

.bootThree .col-xs-push-0 {
  left: auto;
}

.bootThree .col-xs-offset-12 {
  margin-left: 100%;
}

.bootThree .col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.bootThree .col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.bootThree .col-xs-offset-9 {
  margin-left: 75%;
}

.bootThree .col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.bootThree .col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.bootThree .col-xs-offset-6 {
  margin-left: 50%;
}

.bootThree .col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.bootThree .col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.bootThree .col-xs-offset-3 {
  margin-left: 25%;
}

.bootThree .col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.bootThree .col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.bootThree .col-xs-offset-0 {
  margin-left: 0;
}

@media (min-width: 768px) {
  .bootThree .col-sm-1, .bootThree .col-sm-10, .bootThree .col-sm-11, .bootThree .col-sm-12, .bootThree .col-sm-2, .bootThree .col-sm-3, .bootThree .col-sm-4, .bootThree .col-sm-5, .bootThree .col-sm-6, .bootThree .col-sm-7, .bootThree .col-sm-8, .bootThree .col-sm-9 {
    float: left;
  }
  .bootThree .col-sm-12 {
    width: 100%;
  }
  .bootThree .col-sm-11 {
    width: 91.66666667%;
  }
  .bootThree .col-sm-10 {
    width: 83.33333333%;
  }
  .bootThree .col-sm-9 {
    width: 75%;
  }
  .bootThree .col-sm-8 {
    width: 66.66666667%;
  }
  .bootThree .col-sm-7 {
    width: 58.33333333%;
  }
  .bootThree .col-sm-6 {
    width: 50%;
  }
  .bootThree .col-sm-5 {
    width: 41.66666667%;
  }
  .bootThree .col-sm-4 {
    width: 33.33333333%;
  }
  .bootThree .col-sm-3 {
    width: 25%;
  }
  .bootThree .col-sm-2 {
    width: 16.66666667%;
  }
  .bootThree .col-sm-1 {
    width: 8.33333333%;
  }
  .bootThree .col-sm-pull-12 {
    right: 100%;
  }
  .bootThree .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .bootThree .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .bootThree .col-sm-pull-9 {
    right: 75%;
  }
  .bootThree .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .bootThree .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .bootThree .col-sm-pull-6 {
    right: 50%;
  }
  .bootThree .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .bootThree .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .bootThree .col-sm-pull-3 {
    right: 25%;
  }
  .bootThree .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .bootThree .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .bootThree .col-sm-pull-0 {
    right: auto;
  }
  .bootThree .col-sm-push-12 {
    left: 100%;
  }
  .bootThree .col-sm-push-11 {
    left: 91.66666667%;
  }
  .bootThree .col-sm-push-10 {
    left: 83.33333333%;
  }
  .bootThree .col-sm-push-9 {
    left: 75%;
  }
  .bootThree .col-sm-push-8 {
    left: 66.66666667%;
  }
  .bootThree .col-sm-push-7 {
    left: 58.33333333%;
  }
  .bootThree .col-sm-push-6 {
    left: 50%;
  }
  .bootThree .col-sm-push-5 {
    left: 41.66666667%;
  }
  .bootThree .col-sm-push-4 {
    left: 33.33333333%;
  }
  .bootThree .col-sm-push-3 {
    left: 25%;
  }
  .bootThree .col-sm-push-2 {
    left: 16.66666667%;
  }
  .bootThree .col-sm-push-1 {
    left: 8.33333333%;
  }
  .bootThree .col-sm-push-0 {
    left: auto;
  }
  .bootThree .col-sm-offset-12 {
    margin-left: 100%;
  }
  .bootThree .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .bootThree .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .bootThree .col-sm-offset-9 {
    margin-left: 75%;
  }
  .bootThree .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .bootThree .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .bootThree .col-sm-offset-6 {
    margin-left: 50%;
  }
  .bootThree .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .bootThree .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .bootThree .col-sm-offset-3 {
    margin-left: 25%;
  }
  .bootThree .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .bootThree .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .bootThree .col-sm-offset-0 {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .bootThree .col-md-1, .bootThree .col-md-10, .bootThree .col-md-11, .bootThree .col-md-12, .bootThree .col-md-2, .bootThree .col-md-3, .bootThree .col-md-4, .bootThree .col-md-5, .bootThree .col-md-6, .bootThree .col-md-7, .bootThree .col-md-8, .bootThree .col-md-9 {
    float: left;
  }
  .bootThree .col-md-12 {
    width: 100%;
  }
  .bootThree .col-md-11 {
    width: 91.66666667%;
  }
  .bootThree .col-md-10 {
    width: 83.33333333%;
  }
  .bootThree .col-md-9 {
    width: 75%;
  }
  .bootThree .col-md-8 {
    width: 66.66666667%;
  }
  .bootThree .col-md-7 {
    width: 58.33333333%;
  }
  .bootThree .col-md-6 {
    width: 50%;
  }
  .bootThree .col-md-5 {
    width: 41.66666667%;
  }
  .bootThree .col-md-4 {
    width: 33.33333333%;
  }
  .bootThree .col-md-3 {
    width: 25%;
  }
  .bootThree .col-md-2 {
    width: 16.66666667%;
  }
  .bootThree .col-md-1 {
    width: 8.33333333%;
  }
  .bootThree .col-md-pull-12 {
    right: 100%;
  }
  .bootThree .col-md-pull-11 {
    right: 91.66666667%;
  }
  .bootThree .col-md-pull-10 {
    right: 83.33333333%;
  }
  .bootThree .col-md-pull-9 {
    right: 75%;
  }
  .bootThree .col-md-pull-8 {
    right: 66.66666667%;
  }
  .bootThree .col-md-pull-7 {
    right: 58.33333333%;
  }
  .bootThree .col-md-pull-6 {
    right: 50%;
  }
  .bootThree .col-md-pull-5 {
    right: 41.66666667%;
  }
  .bootThree .col-md-pull-4 {
    right: 33.33333333%;
  }
  .bootThree .col-md-pull-3 {
    right: 25%;
  }
  .bootThree .col-md-pull-2 {
    right: 16.66666667%;
  }
  .bootThree .col-md-pull-1 {
    right: 8.33333333%;
  }
  .bootThree .col-md-pull-0 {
    right: auto;
  }
  .bootThree .col-md-push-12 {
    left: 100%;
  }
  .bootThree .col-md-push-11 {
    left: 91.66666667%;
  }
  .bootThree .col-md-push-10 {
    left: 83.33333333%;
  }
  .bootThree .col-md-push-9 {
    left: 75%;
  }
  .bootThree .col-md-push-8 {
    left: 66.66666667%;
  }
  .bootThree .col-md-push-7 {
    left: 58.33333333%;
  }
  .bootThree .col-md-push-6 {
    left: 50%;
  }
  .bootThree .col-md-push-5 {
    left: 41.66666667%;
  }
  .bootThree .col-md-push-4 {
    left: 33.33333333%;
  }
  .bootThree .col-md-push-3 {
    left: 25%;
  }
  .bootThree .col-md-push-2 {
    left: 16.66666667%;
  }
  .bootThree .col-md-push-1 {
    left: 8.33333333%;
  }
  .bootThree .col-md-push-0 {
    left: auto;
  }
  .bootThree .col-md-offset-12 {
    margin-left: 100%;
  }
  .bootThree .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .bootThree .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .bootThree .col-md-offset-9 {
    margin-left: 75%;
  }
  .bootThree .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .bootThree .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .bootThree .col-md-offset-6 {
    margin-left: 50%;
  }
  .bootThree .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .bootThree .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .bootThree .col-md-offset-3 {
    margin-left: 25%;
  }
  .bootThree .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .bootThree .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .bootThree .col-md-offset-0 {
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .bootThree .col-lg-1, .bootThree .col-lg-10, .bootThree .col-lg-11, .bootThree .col-lg-12, .bootThree .col-lg-2, .bootThree .col-lg-3, .bootThree .col-lg-4, .bootThree .col-lg-5, .bootThree .col-lg-6, .bootThree .col-lg-7, .bootThree .col-lg-8, .bootThree .col-lg-9 {
    float: left;
  }
  .bootThree .col-lg-12 {
    width: 100%;
  }
  .bootThree .col-lg-11 {
    width: 91.66666667%;
  }
  .bootThree .col-lg-10 {
    width: 83.33333333%;
  }
  .bootThree .col-lg-9 {
    width: 75%;
  }
  .bootThree .col-lg-8 {
    width: 66.66666667%;
  }
  .bootThree .col-lg-7 {
    width: 58.33333333%;
  }
  .bootThree .col-lg-6 {
    width: 50%;
  }
  .bootThree .col-lg-5 {
    width: 41.66666667%;
  }
  .bootThree .col-lg-4 {
    width: 33.33333333%;
  }
  .bootThree .col-lg-3 {
    width: 25%;
  }
  .bootThree .col-lg-2 {
    width: 16.66666667%;
  }
  .bootThree .col-lg-1 {
    width: 8.33333333%;
  }
  .bootThree .col-lg-pull-12 {
    right: 100%;
  }
  .bootThree .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .bootThree .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .bootThree .col-lg-pull-9 {
    right: 75%;
  }
  .bootThree .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .bootThree .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .bootThree .col-lg-pull-6 {
    right: 50%;
  }
  .bootThree .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .bootThree .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .bootThree .col-lg-pull-3 {
    right: 25%;
  }
  .bootThree .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .bootThree .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .bootThree .col-lg-pull-0 {
    right: auto;
  }
  .bootThree .col-lg-push-12 {
    left: 100%;
  }
  .bootThree .col-lg-push-11 {
    left: 91.66666667%;
  }
  .bootThree .col-lg-push-10 {
    left: 83.33333333%;
  }
  .bootThree .col-lg-push-9 {
    left: 75%;
  }
  .bootThree .col-lg-push-8 {
    left: 66.66666667%;
  }
  .bootThree .col-lg-push-7 {
    left: 58.33333333%;
  }
  .bootThree .col-lg-push-6 {
    left: 50%;
  }
  .bootThree .col-lg-push-5 {
    left: 41.66666667%;
  }
  .bootThree .col-lg-push-4 {
    left: 33.33333333%;
  }
  .bootThree .col-lg-push-3 {
    left: 25%;
  }
  .bootThree .col-lg-push-2 {
    left: 16.66666667%;
  }
  .bootThree .col-lg-push-1 {
    left: 8.33333333%;
  }
  .bootThree .col-lg-push-0 {
    left: auto;
  }
  .bootThree .col-lg-offset-12 {
    margin-left: 100%;
  }
  .bootThree .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .bootThree .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .bootThree .col-lg-offset-9 {
    margin-left: 75%;
  }
  .bootThree .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .bootThree .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .bootThree .col-lg-offset-6 {
    margin-left: 50%;
  }
  .bootThree .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .bootThree .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .bootThree .col-lg-offset-3 {
    margin-left: 25%;
  }
  .bootThree .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .bootThree .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .bootThree .col-lg-offset-0 {
    margin-left: 0;
  }
}

.bootThree table {
  background-color: transparent;
}

.bootThree caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left;
}

.bootThree th {
  text-align: left;
}

.bootThree .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.bootThree .table > tbody > tr > td, .bootThree .table > tbody > tr > th, .bootThree .table > tfoot > tr > td, .bootThree .table > tfoot > tr > th, .bootThree .table > thead > tr > td, .bootThree .table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.bootThree .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.bootThree .table > caption + thead > tr:first-child > td, .bootThree .table > caption + thead > tr:first-child > th, .bootThree .table > colgroup + thead > tr:first-child > td, .bootThree .table > colgroup + thead > tr:first-child > th, .bootThree .table > thead:first-child > tr:first-child > td, .bootThree .table > thead:first-child > tr:first-child > th {
  border-top: 0;
}

.bootThree .table > tbody + tbody {
  border-top: 2px solid #ddd;
}

.bootThree .table .table {
  background-color: #fff;
}

.bootThree .table-condensed > tbody > tr > td, .bootThree .table-condensed > tbody > tr > th, .bootThree .table-condensed > tfoot > tr > td, .bootThree .table-condensed > tfoot > tr > th, .bootThree .table-condensed > thead > tr > td, .bootThree .table-condensed > thead > tr > th {
  padding: 5px;
}

.bootThree .table-bordered {
  border: 1px solid #ddd;
}

.bootThree .table-bordered > tbody > tr > td, .bootThree .table-bordered > tbody > tr > th, .bootThree .table-bordered > tfoot > tr > td, .bootThree .table-bordered > tfoot > tr > th, .bootThree .table-bordered > thead > tr > td, .bootThree .table-bordered > thead > tr > th {
  border: 1px solid #ddd;
}

.bootThree .table-bordered > thead > tr > td, .bootThree .table-bordered > thead > tr > th {
  border-bottom-width: 2px;
}

.bootThree .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.bootThree .table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

.bootThree table col[class*=col-] {
  position: static;
  display: table-column;
  float: none;
}

.bootThree table td[class*=col-], .bootThree table th[class*=col-] {
  position: static;
  display: table-cell;
  float: none;
}

.bootThree .table > tbody > tr.active > td, .bootThree .table > tbody > tr.active > th, .bootThree .table > tbody > tr > td.active, .bootThree .table > tbody > tr > th.active, .bootThree .table > tfoot > tr.active > td, .bootThree .table > tfoot > tr.active > th, .bootThree .table > tfoot > tr > td.active, .bootThree .table > tfoot > tr > th.active, .bootThree .table > thead > tr.active > td, .bootThree .table > thead > tr.active > th, .bootThree .table > thead > tr > td.active, .bootThree .table > thead > tr > th.active {
  background-color: #f5f5f5;
}

.bootThree .table-hover > tbody > tr.active:hover > td, .bootThree .table-hover > tbody > tr.active:hover > th, .bootThree .table-hover > tbody > tr:hover > .active, .bootThree .table-hover > tbody > tr > td.active:hover, .bootThree .table-hover > tbody > tr > th.active:hover {
  background-color: #e8e8e8;
}

.bootThree .table > tbody > tr.success > td, .bootThree .table > tbody > tr.success > th, .bootThree .table > tbody > tr > td.success, .bootThree .table > tbody > tr > th.success, .bootThree .table > tfoot > tr.success > td, .bootThree .table > tfoot > tr.success > th, .bootThree .table > tfoot > tr > td.success, .bootThree .table > tfoot > tr > th.success, .bootThree .table > thead > tr.success > td, .bootThree .table > thead > tr.success > th, .bootThree .table > thead > tr > td.success, .bootThree .table > thead > tr > th.success {
  background-color: #dff0d8;
}

.bootThree .table-hover > tbody > tr.success:hover > td, .bootThree .table-hover > tbody > tr.success:hover > th, .bootThree .table-hover > tbody > tr:hover > .success, .bootThree .table-hover > tbody > tr > td.success:hover, .bootThree .table-hover > tbody > tr > th.success:hover {
  background-color: #d0e9c6;
}

.bootThree .table > tbody > tr.info > td, .bootThree .table > tbody > tr.info > th, .bootThree .table > tbody > tr > td.info, .bootThree .table > tbody > tr > th.info, .bootThree .table > tfoot > tr.info > td, .bootThree .table > tfoot > tr.info > th, .bootThree .table > tfoot > tr > td.info, .bootThree .table > tfoot > tr > th.info, .bootThree .table > thead > tr.info > td, .bootThree .table > thead > tr.info > th, .bootThree .table > thead > tr > td.info, .bootThree .table > thead > tr > th.info {
  background-color: #d9edf7;
}

.bootThree .table-hover > tbody > tr.info:hover > td, .bootThree .table-hover > tbody > tr.info:hover > th, .bootThree .table-hover > tbody > tr:hover > .info, .bootThree .table-hover > tbody > tr > td.info:hover, .bootThree .table-hover > tbody > tr > th.info:hover {
  background-color: #c4e3f3;
}

.bootThree .table > tbody > tr.warning > td, .bootThree .table > tbody > tr.warning > th, .bootThree .table > tbody > tr > td.warning, .bootThree .table > tbody > tr > th.warning, .bootThree .table > tfoot > tr.warning > td, .bootThree .table > tfoot > tr.warning > th, .bootThree .table > tfoot > tr > td.warning, .bootThree .table > tfoot > tr > th.warning, .bootThree .table > thead > tr.warning > td, .bootThree .table > thead > tr.warning > th, .bootThree .table > thead > tr > td.warning, .bootThree .table > thead > tr > th.warning {
  background-color: #fcf8e3;
}

.bootThree .table-hover > tbody > tr.warning:hover > td, .bootThree .table-hover > tbody > tr.warning:hover > th, .bootThree .table-hover > tbody > tr:hover > .warning, .bootThree .table-hover > tbody > tr > td.warning:hover, .bootThree .table-hover > tbody > tr > th.warning:hover {
  background-color: #faf2cc;
}

.bootThree .table > tbody > tr.danger > td, .bootThree .table > tbody > tr.danger > th, .bootThree .table > tbody > tr > td.danger, .bootThree .table > tbody > tr > th.danger, .bootThree .table > tfoot > tr.danger > td, .bootThree .table > tfoot > tr.danger > th, .bootThree .table > tfoot > tr > td.danger, .bootThree .table > tfoot > tr > th.danger, .bootThree .table > thead > tr.danger > td, .bootThree .table > thead > tr.danger > th, .bootThree .table > thead > tr > td.danger, .bootThree .table > thead > tr > th.danger {
  background-color: #f2dede;
}

.bootThree .table-hover > tbody > tr.danger:hover > td, .bootThree .table-hover > tbody > tr.danger:hover > th, .bootThree .table-hover > tbody > tr:hover > .danger, .bootThree .table-hover > tbody > tr > td.danger:hover, .bootThree .table-hover > tbody > tr > th.danger:hover {
  background-color: #ebcccc;
}

.bootThree .table-responsive {
  min-height: .01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px) {
  .bootThree .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .bootThree .table-responsive > .table {
    margin-bottom: 0;
  }
  .bootThree .table-responsive > .table > tbody > tr > td, .bootThree .table-responsive > .table > tbody > tr > th, .bootThree .table-responsive > .table > tfoot > tr > td, .bootThree .table-responsive > .table > tfoot > tr > th, .bootThree .table-responsive > .table > thead > tr > td, .bootThree .table-responsive > .table > thead > tr > th {
    white-space: nowrap;
  }
  .bootThree .table-responsive > .table-bordered {
    border: 0;
  }
  .bootThree .table-responsive > .table-bordered > tbody > tr > td:first-child, .bootThree .table-responsive > .table-bordered > tbody > tr > th:first-child, .bootThree .table-responsive > .table-bordered > tfoot > tr > td:first-child, .bootThree .table-responsive > .table-bordered > tfoot > tr > th:first-child, .bootThree .table-responsive > .table-bordered > thead > tr > td:first-child, .bootThree .table-responsive > .table-bordered > thead > tr > th:first-child {
    border-left: 0;
  }
  .bootThree .table-responsive > .table-bordered > tbody > tr > td:last-child, .bootThree .table-responsive > .table-bordered > tbody > tr > th:last-child, .bootThree .table-responsive > .table-bordered > tfoot > tr > td:last-child, .bootThree .table-responsive > .table-bordered > tfoot > tr > th:last-child, .bootThree .table-responsive > .table-bordered > thead > tr > td:last-child, .bootThree .table-responsive > .table-bordered > thead > tr > th:last-child {
    border-right: 0;
  }
  .bootThree .table-responsive > .table-bordered > tbody > tr:last-child > td, .bootThree .table-responsive > .table-bordered > tbody > tr:last-child > th, .bootThree .table-responsive > .table-bordered > tfoot > tr:last-child > td, .bootThree .table-responsive > .table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0;
  }
}

.bootThree fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.bootThree legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

.bootThree label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.bootThree input[type=search] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bootThree input[type=checkbox], .bootThree input[type=radio] {
  margin: 4px 0 0;
  margin-top: 1px\9;
  line-height: normal;
}

.bootThree input[type=file] {
  display: block;
}

.bootThree input[type=range] {
  display: block;
  width: 100%;
}

.bootThree select[multiple], .bootThree select[size] {
  height: auto;
}

.bootThree input[type=file]:focus, .bootThree input[type=checkbox]:focus, .bootThree input[type=radio]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.bootThree output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
}

.bootThree .form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.bootThree .form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.bootThree .form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.bootThree .form-control:-ms-input-placeholder {
  color: #999;
}

.bootThree .form-control::-webkit-input-placeholder {
  color: #999;
}

.bootThree .form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.bootThree .form-control[disabled], .bootThree .form-control[readonly], .bootThree fieldset[disabled] .form-control {
  background-color: #eee;
  opacity: 1;
}

.bootThree .form-control[disabled], .bootThree fieldset[disabled] .form-control {
  cursor: not-allowed;
}

.bootThree textarea.form-control {
  height: auto;
}

.bootThree input[type=search] {
  -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .bootThree input[type=date].form-control, .bootThree input[type=time].form-control, .bootThree input[type=datetime-local].form-control, .bootThree input[type=month].form-control {
    line-height: 34px;
  }
  .bootThree .input-group-sm input[type=date], .bootThree .input-group-sm input[type=time], .bootThree .input-group-sm input[type=datetime-local], .bootThree .input-group-sm input[type=month], .bootThree input[type=date].input-sm, .bootThree input[type=time].input-sm, .bootThree input[type=datetime-local].input-sm, .bootThree input[type=month].input-sm {
    line-height: 30px;
  }
  .bootThree .input-group-lg input[type=date], .bootThree .input-group-lg input[type=time], .bootThree .input-group-lg input[type=datetime-local], .bootThree .input-group-lg input[type=month], .bootThree input[type=date].input-lg, .bootThree input[type=time].input-lg, .bootThree input[type=datetime-local].input-lg, .bootThree input[type=month].input-lg {
    line-height: 46px;
  }
}

.bootThree .form-group {
  margin-bottom: 15px;
}

.bootThree .checkbox, .bootThree .radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bootThree .checkbox label, .bootThree .radio label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.bootThree .checkbox input[type=checkbox], .bootThree .checkbox-inline input[type=checkbox], .bootThree .radio input[type=radio], .bootThree .radio-inline input[type=radio] {
  position: absolute;
  margin-top: 4px\9;
  margin-left: -20px;
}

.bootThree .checkbox + .checkbox, .bootThree .radio + .radio {
  margin-top: -5px;
}

.bootThree .checkbox-inline, .bootThree .radio-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
}

.bootThree .checkbox-inline + .checkbox-inline, .bootThree .radio-inline + .radio-inline {
  margin-top: 0;
  margin-left: 10px;
}

.bootThree fieldset[disabled] input[type=checkbox], .bootThree fieldset[disabled] input[type=radio], .bootThree input[type=checkbox].disabled, .bootThree input[type=checkbox][disabled], .bootThree input[type=radio].disabled, .bootThree input[type=radio][disabled] {
  cursor: not-allowed;
}

.bootThree .checkbox-inline.disabled, .bootThree .radio-inline.disabled, .bootThree fieldset[disabled] .checkbox-inline, .bootThree fieldset[disabled] .radio-inline {
  cursor: not-allowed;
}

.bootThree .checkbox.disabled label, .bootThree .radio.disabled label, .bootThree fieldset[disabled] .checkbox label, .bootThree fieldset[disabled] .radio label {
  cursor: not-allowed;
}

.bootThree .form-control-static {
  min-height: 34px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
}

.bootThree .form-control-static.input-lg, .bootThree .form-control-static.input-sm {
  padding-right: 0;
  padding-left: 0;
}

.bootThree .input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.bootThree select.input-sm {
  height: 30px;
  line-height: 30px;
}

.bootThree select[multiple].input-sm, .bootThree textarea.input-sm {
  height: auto;
}

.bootThree .form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.bootThree .form-group-sm select.form-control {
  height: 30px;
  line-height: 30px;
}

.bootThree .form-group-sm select[multiple].form-control, .bootThree .form-group-sm textarea.form-control {
  height: auto;
}

.bootThree .form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.bootThree .input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.bootThree select.input-lg {
  height: 46px;
  line-height: 46px;
}

.bootThree select[multiple].input-lg, .bootThree textarea.input-lg {
  height: auto;
}

.bootThree .form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.bootThree .form-group-lg select.form-control {
  height: 46px;
  line-height: 46px;
}

.bootThree .form-group-lg select[multiple].form-control, .bootThree .form-group-lg textarea.form-control {
  height: auto;
}

.bootThree .form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.bootThree .has-feedback {
  position: relative;
}

.bootThree .has-feedback .form-control {
  padding-right: 42.5px;
}

.bootThree .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.bootThree .form-group-lg .form-control + .form-control-feedback, .bootThree .input-group-lg + .form-control-feedback, .bootThree .input-lg + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.bootThree .form-group-sm .form-control + .form-control-feedback, .bootThree .input-group-sm + .form-control-feedback, .bootThree .input-sm + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.bootThree .has-success .checkbox, .bootThree .has-success .checkbox-inline, .bootThree .has-success .control-label, .bootThree .has-success .help-block, .bootThree .has-success .radio, .bootThree .has-success .radio-inline, .bootThree .has-success.checkbox label, .bootThree .has-success.checkbox-inline label, .bootThree .has-success.radio label, .bootThree .has-success.radio-inline label {
  color: #3c763d;
}

.bootThree .has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.bootThree .has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.bootThree .has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d;
}

.bootThree .has-success .form-control-feedback {
  color: #3c763d;
}

.bootThree .has-warning .checkbox, .bootThree .has-warning .checkbox-inline, .bootThree .has-warning .control-label, .bootThree .has-warning .help-block, .bootThree .has-warning .radio, .bootThree .has-warning .radio-inline, .bootThree .has-warning.checkbox label, .bootThree .has-warning.checkbox-inline label, .bootThree .has-warning.radio label, .bootThree .has-warning.radio-inline label {
  color: #8a6d3b;
}

.bootThree .has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.bootThree .has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.bootThree .has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b;
}

.bootThree .has-warning .form-control-feedback {
  color: #8a6d3b;
}

.bootThree .has-error .checkbox, .bootThree .has-error .checkbox-inline, .bootThree .has-error .control-label, .bootThree .has-error .help-block, .bootThree .has-error .radio, .bootThree .has-error .radio-inline, .bootThree .has-error.checkbox label, .bootThree .has-error.checkbox-inline label, .bootThree .has-error.radio label, .bootThree .has-error.radio-inline label {
  color: #a94442;
}

.bootThree .has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.bootThree .has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.bootThree .has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}

.bootThree .has-error .form-control-feedback {
  color: #a94442;
}

.bootThree .has-feedback label ~ .form-control-feedback {
  top: 25px;
}

.bootThree .has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}

.bootThree .help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

@media (min-width: 768px) {
  .bootThree .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .bootThree .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .bootThree .form-inline .form-control-static {
    display: inline-block;
  }
  .bootThree .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .bootThree .form-inline .input-group .form-control, .bootThree .form-inline .input-group .input-group-addon, .bootThree .form-inline .input-group .input-group-btn {
    width: auto;
  }
  .bootThree .form-inline .input-group > .form-control {
    width: 100%;
  }
  .bootThree .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .bootThree .form-inline .checkbox, .bootThree .form-inline .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .bootThree .form-inline .checkbox label, .bootThree .form-inline .radio label {
    padding-left: 0;
  }
  .bootThree .form-inline .checkbox input[type=checkbox], .bootThree .form-inline .radio input[type=radio] {
    position: relative;
    margin-left: 0;
  }
  .bootThree .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.bootThree .form-horizontal .checkbox, .bootThree .form-horizontal .checkbox-inline, .bootThree .form-horizontal .radio, .bootThree .form-horizontal .radio-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
}

.bootThree .form-horizontal .checkbox, .bootThree .form-horizontal .radio {
  min-height: 27px;
}

.bootThree .form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 768px) {
  .bootThree .form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
  }
}

.bootThree .form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}

@media (min-width: 768px) {
  .bootThree .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .bootThree .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px;
  }
}

.bootThree .btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.bootThree .btn.active.focus, .bootThree .btn.active:focus, .bootThree .btn.focus, .bootThree .btn:active.focus, .bootThree .btn:active:focus, .bootThree .btn:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.bootThree .btn.focus, .bootThree .btn:focus, .bootThree .btn:hover {
  color: #333;
  text-decoration: none;
}

.bootThree .btn.active, .bootThree .btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.bootThree .btn.disabled, .bootThree .btn[disabled], .bootThree fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

.bootThree a.btn.disabled, .bootThree fieldset[disabled] a.btn {
  pointer-events: none;
}

.bootThree .btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.bootThree .btn-default.focus, .bootThree .btn-default:focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}

.bootThree .btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.bootThree .btn-default.active, .bootThree .btn-default:active, .bootThree .open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
  background-image: none;
}

.bootThree .btn-default.active.focus, .bootThree .btn-default.active:focus, .bootThree .btn-default.active:hover, .bootThree .btn-default:active.focus, .bootThree .btn-default:active:focus, .bootThree .btn-default:active:hover, .bootThree .open > .dropdown-toggle.btn-default.focus, .bootThree .open > .dropdown-toggle.btn-default:focus, .bootThree .open > .dropdown-toggle.btn-default:hover {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.bootThree .btn-default.active, .bootThree .btn-default:active, .bootThree .open > .dropdown-toggle.btn-default {
  background-image: none;
}

.bootThree .btn-default.disabled.focus, .bootThree .btn-default.disabled:focus, .bootThree .btn-default.disabled:hover, .bootThree .btn-default[disabled].focus, .bootThree .btn-default[disabled]:focus, .bootThree .btn-default[disabled]:hover, .bootThree fieldset[disabled] .btn-default.focus, .bootThree fieldset[disabled] .btn-default:focus, .bootThree fieldset[disabled] .btn-default:hover {
  background-color: #fff;
  border-color: #ccc;
}

.bootThree .btn-default .badge {
  color: #fff;
  background-color: #333;
}

.bootThree .btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.bootThree .btn-primary.focus, .bootThree .btn-primary:focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}

.bootThree .btn-primary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.bootThree .btn-primary.active, .bootThree .btn-primary:active, .bootThree .open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.bootThree .btn-primary.active.focus, .bootThree .btn-primary.active:focus, .bootThree .btn-primary.active:hover, .bootThree .btn-primary:active.focus, .bootThree .btn-primary:active:focus, .bootThree .btn-primary:active:hover, .bootThree .open > .dropdown-toggle.btn-primary.focus, .bootThree .open > .dropdown-toggle.btn-primary:focus, .bootThree .open > .dropdown-toggle.btn-primary:hover {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}

.bootThree .btn-primary.active, .bootThree .btn-primary:active, .bootThree .open > .dropdown-toggle.btn-primary {
  background-image: none;
}

.bootThree .btn-primary.disabled.focus, .bootThree .btn-primary.disabled:focus, .bootThree .btn-primary.disabled:hover, .bootThree .btn-primary[disabled].focus, .bootThree .btn-primary[disabled]:focus, .bootThree .btn-primary[disabled]:hover, .bootThree fieldset[disabled] .btn-primary.focus, .bootThree fieldset[disabled] .btn-primary:focus, .bootThree fieldset[disabled] .btn-primary:hover {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.bootThree .btn-primary .badge {
  color: #337ab7;
  background-color: #fff;
}

.bootThree .btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.bootThree .btn-success.focus, .bootThree .btn-success:focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625;
}

.bootThree .btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.bootThree .btn-success.active, .bootThree .btn-success:active, .bootThree .open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.bootThree .btn-success.active.focus, .bootThree .btn-success.active:focus, .bootThree .btn-success.active:hover, .bootThree .btn-success:active.focus, .bootThree .btn-success:active:focus, .bootThree .btn-success:active:hover, .bootThree .open > .dropdown-toggle.btn-success.focus, .bootThree .open > .dropdown-toggle.btn-success:focus, .bootThree .open > .dropdown-toggle.btn-success:hover {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.bootThree .btn-success.active, .bootThree .btn-success:active, .bootThree .open > .dropdown-toggle.btn-success {
  background-image: none;
}

.bootThree .btn-success.disabled.focus, .bootThree .btn-success.disabled:focus, .bootThree .btn-success.disabled:hover, .bootThree .btn-success[disabled].focus, .bootThree .btn-success[disabled]:focus, .bootThree .btn-success[disabled]:hover, .bootThree fieldset[disabled] .btn-success.focus, .bootThree fieldset[disabled] .btn-success:focus, .bootThree fieldset[disabled] .btn-success:hover {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.bootThree .btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}

.bootThree .btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.bootThree .btn-info.focus, .bootThree .btn-info:focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}

.bootThree .btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.bootThree .btn-info.active, .bootThree .btn-info:active, .bootThree .open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.bootThree .btn-info.active.focus, .bootThree .btn-info.active:focus, .bootThree .btn-info.active:hover, .bootThree .btn-info:active.focus, .bootThree .btn-info:active:focus, .bootThree .btn-info:active:hover, .bootThree .open > .dropdown-toggle.btn-info.focus, .bootThree .open > .dropdown-toggle.btn-info:focus, .bootThree .open > .dropdown-toggle.btn-info:hover {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}

.bootThree .btn-info.active, .bootThree .btn-info:active, .bootThree .open > .dropdown-toggle.btn-info {
  background-image: none;
}

.bootThree .btn-info.disabled.focus, .bootThree .btn-info.disabled:focus, .bootThree .btn-info.disabled:hover, .bootThree .btn-info[disabled].focus, .bootThree .btn-info[disabled]:focus, .bootThree .btn-info[disabled]:hover, .bootThree fieldset[disabled] .btn-info.focus, .bootThree fieldset[disabled] .btn-info:focus, .bootThree fieldset[disabled] .btn-info:hover {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.bootThree .btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}

.bootThree .btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.bootThree .btn-warning.focus, .bootThree .btn-warning:focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}

.bootThree .btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.bootThree .btn-warning.active, .bootThree .btn-warning:active, .bootThree .open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.bootThree .btn-warning.active.focus, .bootThree .btn-warning.active:focus, .bootThree .btn-warning.active:hover, .bootThree .btn-warning:active.focus, .bootThree .btn-warning:active:focus, .bootThree .btn-warning:active:hover, .bootThree .open > .dropdown-toggle.btn-warning.focus, .bootThree .open > .dropdown-toggle.btn-warning:focus, .bootThree .open > .dropdown-toggle.btn-warning:hover {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}

.bootThree .btn-warning.active, .bootThree .btn-warning:active, .bootThree .open > .dropdown-toggle.btn-warning {
  background-image: none;
}

.bootThree .btn-warning.disabled.focus, .bootThree .btn-warning.disabled:focus, .bootThree .btn-warning.disabled:hover, .bootThree .btn-warning[disabled].focus, .bootThree .btn-warning[disabled]:focus, .bootThree .btn-warning[disabled]:hover, .bootThree fieldset[disabled] .btn-warning.focus, .bootThree fieldset[disabled] .btn-warning:focus, .bootThree fieldset[disabled] .btn-warning:hover {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.bootThree .btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}

.bootThree .btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.bootThree .btn-danger.focus, .bootThree .btn-danger:focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19;
}

.bootThree .btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.bootThree .btn-danger.active, .bootThree .btn-danger:active, .bootThree .open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.bootThree .btn-danger.active.focus, .bootThree .btn-danger.active:focus, .bootThree .btn-danger.active:hover, .bootThree .btn-danger:active.focus, .bootThree .btn-danger:active:focus, .bootThree .btn-danger:active:hover, .bootThree .open > .dropdown-toggle.btn-danger.focus, .bootThree .open > .dropdown-toggle.btn-danger:focus, .bootThree .open > .dropdown-toggle.btn-danger:hover {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}

.bootThree .btn-danger.active, .bootThree .btn-danger:active, .bootThree .open > .dropdown-toggle.btn-danger {
  background-image: none;
}

.bootThree .btn-danger.disabled.focus, .bootThree .btn-danger.disabled:focus, .bootThree .btn-danger.disabled:hover, .bootThree .btn-danger[disabled].focus, .bootThree .btn-danger[disabled]:focus, .bootThree .btn-danger[disabled]:hover, .bootThree fieldset[disabled] .btn-danger.focus, .bootThree fieldset[disabled] .btn-danger:focus, .bootThree fieldset[disabled] .btn-danger:hover {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.bootThree .btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}

.bootThree .btn-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
}

.bootThree .btn-link, .bootThree .btn-link.active, .bootThree .btn-link:active, .bootThree .btn-link[disabled], .bootThree fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bootThree .btn-link, .bootThree .btn-link:active, .bootThree .btn-link:focus, .bootThree .btn-link:hover {
  border-color: transparent;
}

.bootThree .btn-link:focus, .bootThree .btn-link:hover {
  color: #23527c;
  text-decoration: underline;
  background-color: transparent;
}

.bootThree .btn-link[disabled]:focus, .bootThree .btn-link[disabled]:hover, .bootThree fieldset[disabled] .btn-link:focus, .bootThree fieldset[disabled] .btn-link:hover {
  color: #777;
  text-decoration: none;
}

.bootThree .btn-group-lg > .btn, .bootThree .btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.bootThree .btn-group-sm > .btn, .bootThree .btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.bootThree .btn-group-xs > .btn, .bootThree .btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.bootThree .btn-block {
  display: block;
  width: 100%;
}

.bootThree .btn-block + .btn-block {
  margin-top: 5px;
}

.bootThree input[type=button].btn-block, .bootThree input[type=reset].btn-block, .bootThree input[type=submit].btn-block {
  width: 100%;
}

.bootThree .fade {
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.bootThree .fade.in {
  opacity: 1;
}

.bootThree .collapse {
  display: none;
}

.bootThree .collapse.in {
  display: block;
}

.bootThree tr.collapse.in {
  display: table-row;
}

.bootThree tbody.collapse.in {
  display: table-row-group;
}

.bootThree .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .35s;
  -o-transition-duration: .35s;
  transition-duration: .35s;
  -webkit-transition-property: height,visibility;
  -o-transition-property: height,visibility;
  transition-property: height,visibility;
}

.bootThree .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.bootThree .dropdown, .bootThree .dropup {
  position: relative;
}

.bootThree .dropdown-toggle:focus {
  outline: 0;
}

.bootThree .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.bootThree .dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.bootThree .dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.bootThree .dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.bootThree .dropdown-menu > li > a:focus, .bootThree .dropdown-menu > li > a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

.bootThree .dropdown-menu > .active > a, .bootThree .dropdown-menu > .active > a:focus, .bootThree .dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0;
}

.bootThree .dropdown-menu > .disabled > a, .bootThree .dropdown-menu > .disabled > a:focus, .bootThree .dropdown-menu > .disabled > a:hover {
  color: #777;
}

.bootThree .dropdown-menu > .disabled > a:focus, .bootThree .dropdown-menu > .disabled > a:hover {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.bootThree .open > .dropdown-menu {
  display: block;
}

.bootThree .open > a {
  outline: 0;
}

.bootThree .dropdown-menu-right {
  right: 0;
  left: auto;
}

.bootThree .dropdown-menu-left {
  right: auto;
  left: 0;
}

.bootThree .dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777;
  white-space: nowrap;
}

.bootThree .dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.bootThree .pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.bootThree .dropup .caret, .bootThree .navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}

.bootThree .dropup .dropdown-menu, .bootThree .navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

@media (min-width: 768px) {
  .bootThree .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .bootThree .navbar-right .dropdown-menu-left {
    right: auto;
    left: 0;
  }
}

.bootThree .btn-group, .bootThree .btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.bootThree .btn-group-vertical > .btn, .bootThree .btn-group > .btn {
  position: relative;
  float: left;
}

.bootThree .btn-group-vertical > .btn.active, .bootThree .btn-group-vertical > .btn:active, .bootThree .btn-group-vertical > .btn:focus, .bootThree .btn-group-vertical > .btn:hover, .bootThree .btn-group > .btn.active, .bootThree .btn-group > .btn:active, .bootThree .btn-group > .btn:focus, .bootThree .btn-group > .btn:hover {
  z-index: 2;
}

.bootThree .btn-group .btn + .btn, .bootThree .btn-group .btn + .btn-group, .bootThree .btn-group .btn-group + .btn, .bootThree .btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.bootThree .btn-toolbar {
  margin-left: -5px;
}

.bootThree .btn-toolbar .btn, .bootThree .btn-toolbar .btn-group, .bootThree .btn-toolbar .input-group {
  float: left;
}

.bootThree .btn-toolbar > .btn, .bootThree .btn-toolbar > .btn-group, .bootThree .btn-toolbar > .input-group {
  margin-left: 5px;
}

.bootThree .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.bootThree .btn-group > .btn:first-child {
  margin-left: 0;
}

.bootThree .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bootThree .btn-group > .btn:last-child:not(:first-child), .bootThree .btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bootThree .btn-group > .btn-group {
  float: left;
}

.bootThree .btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.bootThree .btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child, .bootThree .btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bootThree .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bootThree .btn-group .dropdown-toggle:active, .bootThree .btn-group.open .dropdown-toggle {
  outline: 0;
}

.bootThree .btn-group > .btn + .dropdown-toggle {
  padding-right: 8px;
  padding-left: 8px;
}

.bootThree .btn-group > .btn-lg + .dropdown-toggle {
  padding-right: 12px;
  padding-left: 12px;
}

.bootThree .btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.bootThree .btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bootThree .btn .caret {
  margin-left: 0;
}

.bootThree .btn-lg .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0;
}

.bootThree .dropup .btn-lg .caret {
  border-width: 0 5px 5px;
}

.bootThree .btn-group-vertical > .btn, .bootThree .btn-group-vertical > .btn-group, .bootThree .btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
}

.bootThree .btn-group-vertical > .btn-group > .btn {
  float: none;
}

.bootThree .btn-group-vertical > .btn + .btn, .bootThree .btn-group-vertical > .btn + .btn-group, .bootThree .btn-group-vertical > .btn-group + .btn, .bootThree .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.bootThree .btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.bootThree .btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.bootThree .btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.bootThree .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.bootThree .btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child, .bootThree .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.bootThree .btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bootThree .btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}

.bootThree .btn-group-justified > .btn, .bootThree .btn-group-justified > .btn-group {
  display: table-cell;
  float: none;
  width: 1%;
}

.bootThree .btn-group-justified > .btn-group .btn {
  width: 100%;
}

.bootThree .btn-group-justified > .btn-group .dropdown-menu {
  left: auto;
}

.bootThree [data-toggle=buttons] > .btn input[type=checkbox], .bootThree [data-toggle=buttons] > .btn input[type=radio], .bootThree [data-toggle=buttons] > .btn-group > .btn input[type=checkbox], .bootThree [data-toggle=buttons] > .btn-group > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.bootThree .input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}

.bootThree .input-group[class*=col-] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}

.bootThree .input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.bootThree .input-group .form-control:focus {
  z-index: 3;
}

.bootThree .input-group-lg > .form-control, .bootThree .input-group-lg > .input-group-addon, .bootThree .input-group-lg > .input-group-btn > .btn {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.bootThree select.input-group-lg > .form-control, .bootThree select.input-group-lg > .input-group-addon, .bootThree select.input-group-lg > .input-group-btn > .btn {
  height: 46px;
  line-height: 46px;
}

.bootThree select[multiple].input-group-lg > .form-control, .bootThree select[multiple].input-group-lg > .input-group-addon, .bootThree select[multiple].input-group-lg > .input-group-btn > .btn, .bootThree textarea.input-group-lg > .form-control, .bootThree textarea.input-group-lg > .input-group-addon, .bootThree textarea.input-group-lg > .input-group-btn > .btn {
  height: auto;
}

.bootThree .input-group-sm > .form-control, .bootThree .input-group-sm > .input-group-addon, .bootThree .input-group-sm > .input-group-btn > .btn {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.bootThree select.input-group-sm > .form-control, .bootThree select.input-group-sm > .input-group-addon, .bootThree select.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  line-height: 30px;
}

.bootThree select[multiple].input-group-sm > .form-control, .bootThree select[multiple].input-group-sm > .input-group-addon, .bootThree select[multiple].input-group-sm > .input-group-btn > .btn, .bootThree textarea.input-group-sm > .form-control, .bootThree textarea.input-group-sm > .input-group-addon, .bootThree textarea.input-group-sm > .input-group-btn > .btn {
  height: auto;
}

.bootThree .input-group .form-control, .bootThree .input-group-addon, .bootThree .input-group-btn {
  display: table-cell;
}

.bootThree .input-group .form-control:not(:first-child):not(:last-child), .bootThree .input-group-addon:not(:first-child):not(:last-child), .bootThree .input-group-btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.bootThree .input-group-addon, .bootThree .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.bootThree .input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.bootThree .input-group-addon.input-sm {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
}

.bootThree .input-group-addon.input-lg {
  padding: 10px 16px;
  font-size: 18px;
  border-radius: 6px;
}

.bootThree .input-group-addon input[type=checkbox], .bootThree .input-group-addon input[type=radio] {
  margin-top: 0;
}

.bootThree .input-group .form-control:first-child, .bootThree .input-group-addon:first-child, .bootThree .input-group-btn:first-child > .btn, .bootThree .input-group-btn:first-child > .btn-group > .btn, .bootThree .input-group-btn:first-child > .dropdown-toggle, .bootThree .input-group-btn:last-child > .btn-group:not(:last-child) > .btn, .bootThree .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bootThree .input-group-addon:first-child {
  border-right: 0;
}

.bootThree .input-group .form-control:last-child, .bootThree .input-group-addon:last-child, .bootThree .input-group-btn:first-child > .btn-group:not(:first-child) > .btn, .bootThree .input-group-btn:first-child > .btn:not(:first-child), .bootThree .input-group-btn:last-child > .btn, .bootThree .input-group-btn:last-child > .btn-group > .btn, .bootThree .input-group-btn:last-child > .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bootThree .input-group-addon:last-child {
  border-left: 0;
}

.bootThree .input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}

.bootThree .input-group-btn > .btn {
  position: relative;
}

.bootThree .input-group-btn > .btn + .btn {
  margin-left: -1px;
}

.bootThree .input-group-btn > .btn:active, .bootThree .input-group-btn > .btn:focus, .bootThree .input-group-btn > .btn:hover {
  z-index: 2;
}

.bootThree .input-group-btn:first-child > .btn, .bootThree .input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}

.bootThree .input-group-btn:last-child > .btn, .bootThree .input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px;
}

.bootThree .nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.bootThree .nav > li {
  position: relative;
  display: block;
}

.bootThree .nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.bootThree .nav > li > a:focus, .bootThree .nav > li > a:hover {
  text-decoration: none;
  background-color: #eee;
}

.bootThree .nav > li.disabled > a {
  color: #777;
}

.bootThree .nav > li.disabled > a:focus, .bootThree .nav > li.disabled > a:hover {
  color: #777;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
}

.bootThree .nav .open > a, .bootThree .nav .open > a:focus, .bootThree .nav .open > a:hover {
  background-color: #eee;
  border-color: #337ab7;
}

.bootThree .nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.bootThree .nav > li > a > img {
  max-width: none;
}

.bootThree .nav-tabs {
  border-bottom: 1px solid #ddd;
}

.bootThree .nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.bootThree .nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.bootThree .nav-tabs > li > a:hover {
  border-color: #eee #eee #ddd;
}

.bootThree .nav-tabs > li.active > a, .bootThree .nav-tabs > li.active > a:focus, .bootThree .nav-tabs > li.active > a:hover {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

.bootThree .nav-tabs.nav-justified {
  width: 100%;
  border-bottom: 0;
}

.bootThree .nav-tabs.nav-justified > li {
  float: none;
}

.bootThree .nav-tabs.nav-justified > li > a {
  margin-bottom: 5px;
  text-align: center;
}

.bootThree .nav-tabs.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (min-width: 768px) {
  .bootThree .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%;
  }
  .bootThree .nav-tabs.nav-justified > li > a {
    margin-bottom: 0;
  }
}

.bootThree .nav-tabs.nav-justified > li > a {
  margin-right: 0;
  border-radius: 4px;
}

.bootThree .nav-tabs.nav-justified > .active > a, .bootThree .nav-tabs.nav-justified > .active > a:focus, .bootThree .nav-tabs.nav-justified > .active > a:hover {
  border: 1px solid #ddd;
}

@media (min-width: 768px) {
  .bootThree .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
  }
  .bootThree .nav-tabs.nav-justified > .active > a, .bootThree .nav-tabs.nav-justified > .active > a:focus, .bootThree .nav-tabs.nav-justified > .active > a:hover {
    border-bottom-color: #fff;
  }
}

.bootThree .nav-pills > li {
  float: left;
}

.bootThree .nav-pills > li > a {
  border-radius: 4px;
}

.bootThree .nav-pills > li + li {
  margin-left: 2px;
}

.bootThree .nav-pills > li.active > a, .bootThree .nav-pills > li.active > a:focus, .bootThree .nav-pills > li.active > a:hover {
  color: #fff;
  background-color: #337ab7;
}

.bootThree .nav-stacked > li {
  float: none;
}

.bootThree .nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0;
}

.bootThree .nav-justified {
  width: 100%;
}

.bootThree .nav-justified > li {
  float: none;
}

.bootThree .nav-justified > li > a {
  margin-bottom: 5px;
  text-align: center;
}

.bootThree .nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (min-width: 768px) {
  .bootThree .nav-justified > li {
    display: table-cell;
    width: 1%;
  }
  .bootThree .nav-justified > li > a {
    margin-bottom: 0;
  }
}

.bootThree .nav-tabs-justified {
  border-bottom: 0;
}

.bootThree .nav-tabs-justified > li > a {
  margin-right: 0;
  border-radius: 4px;
}

.bootThree .nav-tabs-justified > .active > a, .bootThree .nav-tabs-justified > .active > a:focus, .bootThree .nav-tabs-justified > .active > a:hover {
  border: 1px solid #ddd;
}

@media (min-width: 768px) {
  .bootThree .nav-tabs-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
  }
  .bootThree .nav-tabs-justified > .active > a, .bootThree .nav-tabs-justified > .active > a:focus, .bootThree .nav-tabs-justified > .active > a:hover {
    border-bottom-color: #fff;
  }
}

.bootThree .tab-content > .tab-pane {
  display: none;
}

.bootThree .tab-content > .active {
  display: block;
}

.bootThree .nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bootThree .navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

@media (min-width: 768px) {
  .bootThree .navbar {
    border-radius: 4px;
  }
}

@media (min-width: 768px) {
  .bootThree .navbar-header {
    float: left;
  }
}

.bootThree .navbar-collapse {
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

.bootThree .navbar-collapse.in {
  overflow-y: auto;
}

@media (min-width: 768px) {
  .bootThree .navbar-collapse {
    width: auto;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .bootThree .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .bootThree .navbar-collapse.in {
    overflow-y: visible;
  }
  .bootThree .navbar-fixed-bottom .navbar-collapse, .bootThree .navbar-fixed-top .navbar-collapse, .bootThree .navbar-static-top .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }
}

.bootThree .navbar-fixed-bottom .navbar-collapse, .bootThree .navbar-fixed-top .navbar-collapse {
  max-height: 340px;
}

@media (max-device-width: 480px) and (orientation: landscape) {
  .bootThree .navbar-fixed-bottom .navbar-collapse, .bootThree .navbar-fixed-top .navbar-collapse {
    max-height: 200px;
  }
}

.bootThree .container-fluid > .navbar-collapse, .bootThree .container-fluid > .navbar-header, .bootThree .container > .navbar-collapse, .bootThree .container > .navbar-header {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 768px) {
  .bootThree .container-fluid > .navbar-collapse, .bootThree .container-fluid > .navbar-header, .bootThree .container > .navbar-collapse, .bootThree .container > .navbar-header {
    margin-right: 0;
    margin-left: 0;
  }
}

.bootThree .navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}

@media (min-width: 768px) {
  .bootThree .navbar-static-top {
    border-radius: 0;
  }
}

.bootThree .navbar-fixed-bottom, .bootThree .navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

@media (min-width: 768px) {
  .bootThree .navbar-fixed-bottom, .bootThree .navbar-fixed-top {
    border-radius: 0;
  }
}

.bootThree .navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}

.bootThree .navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0;
}

.bootThree .navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
}

.bootThree .navbar-brand:focus, .bootThree .navbar-brand:hover {
  text-decoration: none;
}

.bootThree .navbar-brand > img {
  display: block;
}

@media (min-width: 768px) {
  .bootThree .navbar > .container .navbar-brand, .bootThree .navbar > .container-fluid .navbar-brand {
    margin-left: -15px;
  }
}

.bootThree .navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.bootThree .navbar-toggle:focus {
  outline: 0;
}

.bootThree .navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.bootThree .navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}

@media (min-width: 768px) {
  .bootThree .navbar-toggle {
    display: none;
  }
}

.bootThree .navbar-nav {
  margin: 7.5px -15px;
}

.bootThree .navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

@media (max-width: 767px) {
  .bootThree .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .bootThree .navbar-nav .open .dropdown-menu .dropdown-header, .bootThree .navbar-nav .open .dropdown-menu > li > a {
    padding: 5px 15px 5px 25px;
  }
  .bootThree .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }
  .bootThree .navbar-nav .open .dropdown-menu > li > a:focus, .bootThree .navbar-nav .open .dropdown-menu > li > a:hover {
    background-image: none;
  }
}

@media (min-width: 768px) {
  .bootThree .navbar-nav {
    float: left;
    margin: 0;
  }
  .bootThree .navbar-nav > li {
    float: left;
  }
  .bootThree .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.bootThree .navbar-form {
  padding: 10px 15px;
  margin-top: 8px;
  margin-right: -15px;
  margin-bottom: 8px;
  margin-left: -15px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
  .bootThree .navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .bootThree .navbar-form .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .bootThree .navbar-form .form-control-static {
    display: inline-block;
  }
  .bootThree .navbar-form .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .bootThree .navbar-form .input-group .form-control, .bootThree .navbar-form .input-group .input-group-addon, .bootThree .navbar-form .input-group .input-group-btn {
    width: auto;
  }
  .bootThree .navbar-form .input-group > .form-control {
    width: 100%;
  }
  .bootThree .navbar-form .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .bootThree .navbar-form .checkbox, .bootThree .navbar-form .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .bootThree .navbar-form .checkbox label, .bootThree .navbar-form .radio label {
    padding-left: 0;
  }
  .bootThree .navbar-form .checkbox input[type=checkbox], .bootThree .navbar-form .radio input[type=radio] {
    position: relative;
    margin-left: 0;
  }
  .bootThree .navbar-form .has-feedback .form-control-feedback {
    top: 0;
  }
}

@media (max-width: 767px) {
  .bootThree .navbar-form .form-group {
    margin-bottom: 5px;
  }
  .bootThree .navbar-form .form-group:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .bootThree .navbar-form {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.bootThree .navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bootThree .navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  margin-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.bootThree .navbar-btn {
  margin-top: 8px;
  margin-bottom: 8px;
}

.bootThree .navbar-btn.btn-sm {
  margin-top: 10px;
  margin-bottom: 10px;
}

.bootThree .navbar-btn.btn-xs {
  margin-top: 14px;
  margin-bottom: 14px;
}

.bootThree .navbar-text {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .bootThree .navbar-text {
    float: left;
    margin-right: 15px;
    margin-left: 15px;
  }
}

@media (min-width: 768px) {
  .bootThree .navbar-left {
    float: left !important;
  }
  .bootThree .navbar-right {
    float: right !important;
    margin-right: -15px;
  }
  .bootThree .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
}

.bootThree .navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
  background-image: none;
}

.bootThree .navbar-default .navbar-brand {
  color: #777;
}

.bootThree .navbar-default .navbar-brand:focus, .bootThree .navbar-default .navbar-brand:hover {
  color: #5e5e5e;
  background-color: transparent;
  background-image: none;
}

.bootThree .navbar-default .navbar-text {
  color: #777;
}

.bootThree .navbar-default .navbar-nav > li > a {
  color: #777;
}

.bootThree .navbar-default .navbar-nav > li > a:focus, .bootThree .navbar-default .navbar-nav > li > a:hover {
  color: #333;
  background-color: transparent;
}

.bootThree .navbar-default .navbar-nav > .active > a, .bootThree .navbar-default .navbar-nav > .active > a:focus, .bootThree .navbar-default .navbar-nav > .active > a:hover {
  color: #555;
  background-color: #e7e7e7;
  background-image: none;
}

.bootThree .navbar-default .navbar-nav > .disabled > a, .bootThree .navbar-default .navbar-nav > .disabled > a:focus, .bootThree .navbar-default .navbar-nav > .disabled > a:hover {
  color: #ccc;
  background-color: transparent;
}

.bootThree .navbar-default .navbar-toggle {
  border-color: #ddd;
}

.bootThree .navbar-default .navbar-toggle:focus, .bootThree .navbar-default .navbar-toggle:hover {
  background-color: #ddd;
}

.bootThree .navbar-default .navbar-toggle .icon-bar {
  background-color: #888;
}

.bootThree .navbar-default .navbar-collapse, .bootThree .navbar-default .navbar-form {
  border-color: #e7e7e7;
}

.bootThree .navbar-default .navbar-nav > .open > a, .bootThree .navbar-default .navbar-nav > .open > a:focus, .bootThree .navbar-default .navbar-nav > .open > a:hover {
  color: #555;
  background-color: #e7e7e7;
  background-image: none;
}

@media (max-width: 767px) {
  .bootThree .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #777;
  }
  .bootThree .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus, .bootThree .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #333;
    background-color: transparent;
  }
  .bootThree .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .bootThree .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus, .bootThree .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #555;
    background-color: #e7e7e7;
  }
  .bootThree .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, .bootThree .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus, .bootThree .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover {
    color: #ccc;
    background-color: transparent;
  }
}

.bootThree .navbar-default .navbar-link {
  color: #777;
}

.bootThree .navbar-default .navbar-link:hover {
  color: #333;
}

.bootThree .navbar-default .btn-link {
  color: #777;
}

.bootThree .navbar-default .btn-link:focus, .bootThree .navbar-default .btn-link:hover {
  color: #333;
}

.bootThree .navbar-default .btn-link[disabled]:focus, .bootThree .navbar-default .btn-link[disabled]:hover, .bootThree fieldset[disabled] .navbar-default .btn-link:focus, .bootThree fieldset[disabled] .navbar-default .btn-link:hover {
  color: #ccc;
}

.bootThree .navbar-inverse {
  background-color: #222;
  border-color: #080808;
}

.bootThree .navbar-inverse .navbar-brand {
  color: #9d9d9d;
}

.bootThree .navbar-inverse .navbar-brand:focus, .bootThree .navbar-inverse .navbar-brand:hover {
  color: #fff;
  background-color: transparent;
}

.bootThree .navbar-inverse .navbar-text {
  color: #9d9d9d;
}

.bootThree .navbar-inverse .navbar-nav > li > a {
  color: #9d9d9d;
}

.bootThree .navbar-inverse .navbar-nav > li > a:focus, .bootThree .navbar-inverse .navbar-nav > li > a:hover {
  color: #fff;
  background-color: transparent;
}

.bootThree .navbar-inverse .navbar-nav > .active > a, .bootThree .navbar-inverse .navbar-nav > .active > a:focus, .bootThree .navbar-inverse .navbar-nav > .active > a:hover {
  color: #fff;
  background-color: #080808;
}

.bootThree .navbar-inverse .navbar-nav > .disabled > a, .bootThree .navbar-inverse .navbar-nav > .disabled > a:focus, .bootThree .navbar-inverse .navbar-nav > .disabled > a:hover {
  color: #444;
  background-color: transparent;
}

.bootThree .navbar-inverse .navbar-toggle {
  border-color: #333;
}

.bootThree .navbar-inverse .navbar-toggle:focus, .bootThree .navbar-inverse .navbar-toggle:hover {
  background-color: #333;
}

.bootThree .navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff;
}

.bootThree .navbar-inverse .navbar-collapse, .bootThree .navbar-inverse .navbar-form {
  border-color: #101010;
}

.bootThree .navbar-inverse .navbar-nav > .open > a, .bootThree .navbar-inverse .navbar-nav > .open > a:focus, .bootThree .navbar-inverse .navbar-nav > .open > a:hover {
  color: #fff;
  background-color: #080808;
  background-image: none;
}

@media (max-width: 767px) {
  .bootThree .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #080808;
  }
  .bootThree .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #080808;
  }
  .bootThree .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #9d9d9d;
  }
  .bootThree .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus, .bootThree .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #fff;
    background-color: transparent;
  }
  .bootThree .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, .bootThree .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus, .bootThree .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #fff;
    background-color: #080808;
  }
  .bootThree .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a, .bootThree .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus, .bootThree .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover {
    color: #444;
    background-color: transparent;
  }
}

.bootThree .navbar-inverse .navbar-link {
  color: #9d9d9d;
}

.bootThree .navbar-inverse .navbar-link:hover {
  color: #fff;
}

.bootThree .navbar-inverse .btn-link {
  color: #9d9d9d;
}

.bootThree .navbar-inverse .btn-link:focus, .bootThree .navbar-inverse .btn-link:hover {
  color: #fff;
}

.bootThree .navbar-inverse .btn-link[disabled]:focus, .bootThree .navbar-inverse .btn-link[disabled]:hover, .bootThree fieldset[disabled] .navbar-inverse .btn-link:focus, .bootThree fieldset[disabled] .navbar-inverse .btn-link:hover {
  color: #444;
}

.bootThree .breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.bootThree .breadcrumb > li {
  display: inline-block;
}

.bootThree .breadcrumb > li + li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0";
}

.bootThree .breadcrumb > .active {
  color: #777;
}

.bootThree .pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.bootThree .pagination > li {
  display: inline;
}

.bootThree .pagination > li > a, .bootThree .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.bootThree .pagination > li:first-child > a, .bootThree .pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.bootThree .pagination > li:last-child > a, .bootThree .pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.bootThree .pagination > li > a:focus, .bootThree .pagination > li > a:hover, .bootThree .pagination > li > span:focus, .bootThree .pagination > li > span:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}

.bootThree .pagination > .active > a, .bootThree .pagination > .active > a:focus, .bootThree .pagination > .active > a:hover, .bootThree .pagination > .active > span, .bootThree .pagination > .active > span:focus, .bootThree .pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}

.bootThree .pagination > .disabled > a, .bootThree .pagination > .disabled > a:focus, .bootThree .pagination > .disabled > a:hover, .bootThree .pagination > .disabled > span, .bootThree .pagination > .disabled > span:focus, .bootThree .pagination > .disabled > span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.bootThree .pagination-lg > li > a, .bootThree .pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.bootThree .pagination-lg > li:first-child > a, .bootThree .pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.bootThree .pagination-lg > li:last-child > a, .bootThree .pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.bootThree .pagination-sm > li > a, .bootThree .pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.bootThree .pagination-sm > li:first-child > a, .bootThree .pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.bootThree .pagination-sm > li:last-child > a, .bootThree .pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.bootThree .pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;
}

.bootThree .pager li {
  display: inline;
}

.bootThree .pager li > a, .bootThree .pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
}

.bootThree .pager li > a:focus, .bootThree .pager li > a:hover {
  text-decoration: none;
  background-color: #eee;
}

.bootThree .pager .next > a, .bootThree .pager .next > span {
  float: right;
}

.bootThree .pager .previous > a, .bootThree .pager .previous > span {
  float: left;
}

.bootThree .pager .disabled > a, .bootThree .pager .disabled > a:focus, .bootThree .pager .disabled > a:hover, .bootThree .pager .disabled > span {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
}

.bootThree .label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

.bootThree a.label:focus, .bootThree a.label:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.bootThree .label:empty {
  display: none;
}

.bootThree .btn .label {
  position: relative;
  top: -1px;
}

.bootThree .label-default {
  background-color: #777;
}

.bootThree .label-default[href]:focus, .bootThree .label-default[href]:hover {
  background-color: #5e5e5e;
}

.bootThree .label-primary {
  background-color: #337ab7;
}

.bootThree .label-primary[href]:focus, .bootThree .label-primary[href]:hover {
  background-color: #286090;
}

.bootThree .label-success {
  background-color: #5cb85c;
}

.bootThree .label-success[href]:focus, .bootThree .label-success[href]:hover {
  background-color: #449d44;
}

.bootThree .label-info {
  background-color: #5bc0de;
}

.bootThree .label-info[href]:focus, .bootThree .label-info[href]:hover {
  background-color: #31b0d5;
}

.bootThree .label-warning {
  background-color: #f0ad4e;
}

.bootThree .label-warning[href]:focus, .bootThree .label-warning[href]:hover {
  background-color: #ec971f;
}

.bootThree .label-danger {
  background-color: #d9534f;
}

.bootThree .label-danger[href]:focus, .bootThree .label-danger[href]:hover {
  background-color: #c9302c;
}

.bootThree .badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
}

.bootThree .badge:empty {
  display: none;
}

.bootThree .btn .badge {
  position: relative;
  top: -1px;
}

.bootThree .btn-group-xs > .btn .badge, .bootThree .btn-xs .badge {
  top: 0;
  padding: 1px 5px;
}

.bootThree a.badge:focus, .bootThree a.badge:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.bootThree .list-group-item.active > .badge, .bootThree .nav-pills > .active > a > .badge {
  color: #337ab7;
  background-color: #fff;
}

.bootThree .list-group-item > .badge {
  float: right;
}

.bootThree .list-group-item > .badge + .badge {
  margin-right: 5px;
}

.bootThree .nav-pills > li > a > .badge {
  margin-left: 3px;
}

.bootThree .jumbotron {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #eee;
}

.bootThree .jumbotron .h1, .bootThree .jumbotron h1 {
  color: inherit;
}

.bootThree .jumbotron p {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200;
}

.bootThree .jumbotron > hr {
  border-top-color: #d5d5d5;
}

.bootThree .container .jumbotron, .bootThree .container-fluid .jumbotron {
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 6px;
}

.bootThree .jumbotron .container {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .bootThree .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .bootThree .container .jumbotron, .bootThree .container-fluid .jumbotron {
    padding-right: 60px;
    padding-left: 60px;
  }
  .bootThree .jumbotron .h1, .bootThree .jumbotron h1 {
    font-size: 63px;
  }
}

.bootThree .thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border .2s ease-in-out;
  -o-transition: border .2s ease-in-out;
  transition: border .2s ease-in-out;
}

.bootThree .thumbnail a > img, .bootThree .thumbnail > img {
  margin-right: auto;
  margin-left: auto;
}

.bootThree a.thumbnail.active, .bootThree a.thumbnail:focus, .bootThree a.thumbnail:hover {
  border-color: #337ab7;
}

.bootThree .thumbnail .caption {
  padding: 9px;
  color: #333;
}

.bootThree .alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.bootThree .alert h4 {
  margin-top: 0;
  color: inherit;
}

.bootThree .alert .alert-link {
  font-weight: 700;
}

.bootThree .alert > p, .bootThree .alert > ul {
  margin-bottom: 0;
}

.bootThree .alert > p + p {
  margin-top: 5px;
}

.bootThree .alert-dismissable, .bootThree .alert-dismissible {
  padding-right: 35px;
}

.bootThree .alert-dismissable .close, .bootThree .alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.bootThree .alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.bootThree .alert-success hr {
  border-top-color: #c9e2b3;
}

.bootThree .alert-success .alert-link {
  color: #2b542c;
}

.bootThree .alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.bootThree .alert-info hr {
  border-top-color: #a6e1ec;
}

.bootThree .alert-info .alert-link {
  color: #245269;
}

.bootThree .alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.bootThree .alert-warning hr {
  border-top-color: #f7e1b5;
}

.bootThree .alert-warning .alert-link {
  color: #66512c;
}

.bootThree .alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.bootThree .alert-danger hr {
  border-top-color: #e4b9c0;
}

.bootThree .alert-danger .alert-link {
  color: #843534;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

.bootThree .progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.bootThree .progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
}

.bootThree .progress-bar-striped, .bootThree .progress-striped .progress-bar {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.bootThree .progress-bar.active, .bootThree .progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.bootThree .progress-bar-success {
  background-color: #5cb85c;
}

.bootThree .progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.bootThree .progress-bar-info {
  background-color: #5bc0de;
}

.bootThree .progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.bootThree .progress-bar-warning {
  background-color: #f0ad4e;
}

.bootThree .progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.bootThree .progress-bar-danger {
  background-color: #d9534f;
}

.bootThree .progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.bootThree .media {
  margin-top: 15px;
}

.bootThree .media:first-child {
  margin-top: 0;
}

.bootThree .media, .bootThree .media-body {
  overflow: hidden;
  zoom: 1;
}

.bootThree .media-body {
  width: 10000px;
}

.bootThree .media-object {
  display: block;
}

.bootThree .media-object.img-thumbnail {
  max-width: none;
}

.bootThree .media-right, .bootThree .media > .pull-right {
  padding-left: 10px;
}

.bootThree .media-left, .bootThree .media > .pull-left {
  padding-right: 10px;
}

.bootThree .media-body, .bootThree .media-left, .bootThree .media-right {
  display: table-cell;
  vertical-align: top;
}

.bootThree .media-middle {
  vertical-align: middle;
}

.bootThree .media-bottom {
  vertical-align: bottom;
}

.bootThree .media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.bootThree .media-list {
  padding-left: 0;
  list-style: none;
}

.bootThree .list-group {
  padding-left: 0;
  margin-bottom: 20px;
}

.bootThree .list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.bootThree .list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.bootThree .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.bootThree a.list-group-item, .bootThree button.list-group-item {
  color: #555;
}

.bootThree a.list-group-item .list-group-item-heading, .bootThree button.list-group-item .list-group-item-heading {
  color: #333;
}

.bootThree a.list-group-item:focus, .bootThree a.list-group-item:hover, .bootThree button.list-group-item:focus, .bootThree button.list-group-item:hover {
  color: #555;
  text-decoration: none;
  background-color: #f5f5f5;
}

.bootThree button.list-group-item {
  width: 100%;
  text-align: left;
}

.bootThree .list-group-item.disabled, .bootThree .list-group-item.disabled:focus, .bootThree .list-group-item.disabled:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #eee;
}

.bootThree .list-group-item.disabled .list-group-item-heading, .bootThree .list-group-item.disabled:focus .list-group-item-heading, .bootThree .list-group-item.disabled:hover .list-group-item-heading {
  color: inherit;
}

.bootThree .list-group-item.disabled .list-group-item-text, .bootThree .list-group-item.disabled:focus .list-group-item-text, .bootThree .list-group-item.disabled:hover .list-group-item-text {
  color: #777;
}

.bootThree .list-group-item.active, .bootThree .list-group-item.active:focus, .bootThree .list-group-item.active:hover {
  z-index: 2;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}

.bootThree .list-group-item.active .list-group-item-heading, .bootThree .list-group-item.active .list-group-item-heading > .small, .bootThree .list-group-item.active .list-group-item-heading > small, .bootThree .list-group-item.active:focus .list-group-item-heading, .bootThree .list-group-item.active:focus .list-group-item-heading > .small, .bootThree .list-group-item.active:focus .list-group-item-heading > small, .bootThree .list-group-item.active:hover .list-group-item-heading, .bootThree .list-group-item.active:hover .list-group-item-heading > .small, .bootThree .list-group-item.active:hover .list-group-item-heading > small {
  color: inherit;
}

.bootThree .list-group-item.active .list-group-item-text, .bootThree .list-group-item.active:focus .list-group-item-text, .bootThree .list-group-item.active:hover .list-group-item-text {
  color: #c7ddef;
}

.bootThree .list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}

.bootThree a.list-group-item-success, .bootThree button.list-group-item-success {
  color: #3c763d;
}

.bootThree a.list-group-item-success .list-group-item-heading, .bootThree button.list-group-item-success .list-group-item-heading {
  color: inherit;
}

.bootThree a.list-group-item-success:focus, .bootThree a.list-group-item-success:hover, .bootThree button.list-group-item-success:focus, .bootThree button.list-group-item-success:hover {
  color: #3c763d;
  background-color: #d0e9c6;
}

.bootThree a.list-group-item-success.active, .bootThree a.list-group-item-success.active:focus, .bootThree a.list-group-item-success.active:hover, .bootThree button.list-group-item-success.active, .bootThree button.list-group-item-success.active:focus, .bootThree button.list-group-item-success.active:hover {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}

.bootThree .list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}

.bootThree a.list-group-item-info, .bootThree button.list-group-item-info {
  color: #31708f;
}

.bootThree a.list-group-item-info .list-group-item-heading, .bootThree button.list-group-item-info .list-group-item-heading {
  color: inherit;
}

.bootThree a.list-group-item-info:focus, .bootThree a.list-group-item-info:hover, .bootThree button.list-group-item-info:focus, .bootThree button.list-group-item-info:hover {
  color: #31708f;
  background-color: #c4e3f3;
}

.bootThree a.list-group-item-info.active, .bootThree a.list-group-item-info.active:focus, .bootThree a.list-group-item-info.active:hover, .bootThree button.list-group-item-info.active, .bootThree button.list-group-item-info.active:focus, .bootThree button.list-group-item-info.active:hover {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}

.bootThree .list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}

.bootThree a.list-group-item-warning, .bootThree button.list-group-item-warning {
  color: #8a6d3b;
}

.bootThree a.list-group-item-warning .list-group-item-heading, .bootThree button.list-group-item-warning .list-group-item-heading {
  color: inherit;
}

.bootThree a.list-group-item-warning:focus, .bootThree a.list-group-item-warning:hover, .bootThree button.list-group-item-warning:focus, .bootThree button.list-group-item-warning:hover {
  color: #8a6d3b;
  background-color: #faf2cc;
}

.bootThree a.list-group-item-warning.active, .bootThree a.list-group-item-warning.active:focus, .bootThree a.list-group-item-warning.active:hover, .bootThree button.list-group-item-warning.active, .bootThree button.list-group-item-warning.active:focus, .bootThree button.list-group-item-warning.active:hover {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}

.bootThree .list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}

.bootThree a.list-group-item-danger, .bootThree button.list-group-item-danger {
  color: #a94442;
}

.bootThree a.list-group-item-danger .list-group-item-heading, .bootThree button.list-group-item-danger .list-group-item-heading {
  color: inherit;
}

.bootThree a.list-group-item-danger:focus, .bootThree a.list-group-item-danger:hover, .bootThree button.list-group-item-danger:focus, .bootThree button.list-group-item-danger:hover {
  color: #a94442;
  background-color: #ebcccc;
}

.bootThree a.list-group-item-danger.active, .bootThree a.list-group-item-danger.active:focus, .bootThree a.list-group-item-danger.active:hover, .bootThree button.list-group-item-danger.active, .bootThree button.list-group-item-danger.active:focus, .bootThree button.list-group-item-danger.active:hover {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;
}

.bootThree .list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.bootThree .list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}

.bootThree .panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.bootThree .panel-body {
  padding: 15px;
}

.bootThree .panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.bootThree .panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}

.bootThree .panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}

.bootThree .panel-title > .small, .bootThree .panel-title > .small > a, .bootThree .panel-title > a, .bootThree .panel-title > small, .bootThree .panel-title > small > a {
  color: inherit;
}

.bootThree .panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.bootThree .panel > .list-group, .bootThree .panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}

.bootThree .panel > .list-group .list-group-item, .bootThree .panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}

.bootThree .panel > .list-group:first-child .list-group-item:first-child, .bootThree .panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.bootThree .panel > .list-group:last-child .list-group-item:last-child, .bootThree .panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.bootThree .panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bootThree .panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}

.bootThree .list-group + .panel-footer {
  border-top-width: 0;
}

.bootThree .panel > .panel-collapse > .table, .bootThree .panel > .table, .bootThree .panel > .table-responsive > .table {
  margin-bottom: 0;
}

.bootThree .panel > .panel-collapse > .table caption, .bootThree .panel > .table caption, .bootThree .panel > .table-responsive > .table caption {
  padding-right: 15px;
  padding-left: 15px;
}

.bootThree .panel > .table-responsive:first-child > .table:first-child, .bootThree .panel > .table:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.bootThree .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child, .bootThree .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child, .bootThree .panel > .table:first-child > tbody:first-child > tr:first-child, .bootThree .panel > .table:first-child > thead:first-child > tr:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.bootThree .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child, .bootThree .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child, .bootThree .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child, .bootThree .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child, .bootThree .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child, .bootThree .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child, .bootThree .panel > .table:first-child > thead:first-child > tr:first-child td:first-child, .bootThree .panel > .table:first-child > thead:first-child > tr:first-child th:first-child {
  border-top-left-radius: 3px;
}

.bootThree .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child, .bootThree .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child, .bootThree .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child, .bootThree .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child, .bootThree .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child, .bootThree .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child, .bootThree .panel > .table:first-child > thead:first-child > tr:first-child td:last-child, .bootThree .panel > .table:first-child > thead:first-child > tr:first-child th:last-child {
  border-top-right-radius: 3px;
}

.bootThree .panel > .table-responsive:last-child > .table:last-child, .bootThree .panel > .table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.bootThree .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child, .bootThree .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child, .bootThree .panel > .table:last-child > tbody:last-child > tr:last-child, .bootThree .panel > .table:last-child > tfoot:last-child > tr:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.bootThree .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child, .bootThree .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child, .bootThree .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .bootThree .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child, .bootThree .panel > .table:last-child > tbody:last-child > tr:last-child td:first-child, .bootThree .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child, .bootThree .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .bootThree .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 3px;
}

.bootThree .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child, .bootThree .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child, .bootThree .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .bootThree .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child, .bootThree .panel > .table:last-child > tbody:last-child > tr:last-child td:last-child, .bootThree .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child, .bootThree .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .bootThree .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 3px;
}

.bootThree .panel > .panel-body + .table, .bootThree .panel > .panel-body + .table-responsive, .bootThree .panel > .table + .panel-body, .bootThree .panel > .table-responsive + .panel-body {
  border-top: 1px solid #ddd;
}

.bootThree .panel > .table > tbody:first-child > tr:first-child td, .bootThree .panel > .table > tbody:first-child > tr:first-child th {
  border-top: 0;
}

.bootThree .panel > .table-bordered, .bootThree .panel > .table-responsive > .table-bordered {
  border: 0;
}

.bootThree .panel > .table-bordered > tbody > tr > td:first-child, .bootThree .panel > .table-bordered > tbody > tr > th:first-child, .bootThree .panel > .table-bordered > tfoot > tr > td:first-child, .bootThree .panel > .table-bordered > tfoot > tr > th:first-child, .bootThree .panel > .table-bordered > thead > tr > td:first-child, .bootThree .panel > .table-bordered > thead > tr > th:first-child, .bootThree .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child, .bootThree .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child, .bootThree .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child, .bootThree .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child, .bootThree .panel > .table-responsive > .table-bordered > thead > tr > td:first-child, .bootThree .panel > .table-responsive > .table-bordered > thead > tr > th:first-child {
  border-left: 0;
}

.bootThree .panel > .table-bordered > tbody > tr > td:last-child, .bootThree .panel > .table-bordered > tbody > tr > th:last-child, .bootThree .panel > .table-bordered > tfoot > tr > td:last-child, .bootThree .panel > .table-bordered > tfoot > tr > th:last-child, .bootThree .panel > .table-bordered > thead > tr > td:last-child, .bootThree .panel > .table-bordered > thead > tr > th:last-child, .bootThree .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child, .bootThree .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child, .bootThree .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child, .bootThree .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child, .bootThree .panel > .table-responsive > .table-bordered > thead > tr > td:last-child, .bootThree .panel > .table-responsive > .table-bordered > thead > tr > th:last-child {
  border-right: 0;
}

.bootThree .panel > .table-bordered > tbody > tr:first-child > td, .bootThree .panel > .table-bordered > tbody > tr:first-child > th, .bootThree .panel > .table-bordered > thead > tr:first-child > td, .bootThree .panel > .table-bordered > thead > tr:first-child > th, .bootThree .panel > .table-responsive > .table-bordered > tbody > tr:first-child > td, .bootThree .panel > .table-responsive > .table-bordered > tbody > tr:first-child > th, .bootThree .panel > .table-responsive > .table-bordered > thead > tr:first-child > td, .bootThree .panel > .table-responsive > .table-bordered > thead > tr:first-child > th {
  border-bottom: 0;
}

.bootThree .panel > .table-bordered > tbody > tr:last-child > td, .bootThree .panel > .table-bordered > tbody > tr:last-child > th, .bootThree .panel > .table-bordered > tfoot > tr:last-child > td, .bootThree .panel > .table-bordered > tfoot > tr:last-child > th, .bootThree .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td, .bootThree .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th, .bootThree .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td, .bootThree .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}

.bootThree .panel > .table-responsive {
  margin-bottom: 0;
  border: 0;
}

.bootThree .panel-group {
  margin-bottom: 20px;
}

.bootThree .panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px;
}

.bootThree .panel-group .panel + .panel {
  margin-top: 5px;
}

.bootThree .panel-group .panel-heading {
  border-bottom: 0;
}

.bootThree .panel-group .panel-heading + .panel-collapse > .list-group, .bootThree .panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: 1px solid #ddd;
}

.bootThree .panel-group .panel-footer {
  border-top: 0;
}

.bootThree .panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}

.bootThree .panel-default {
  border-color: #ddd;
}

.bootThree .panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.bootThree .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}

.bootThree .panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333;
}

.bootThree .panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}

.bootThree .panel-primary {
  border-color: #337ab7;
}

.bootThree .panel-primary > .panel-heading {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}

.bootThree .panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #337ab7;
}

.bootThree .panel-primary > .panel-heading .badge {
  color: #337ab7;
  background-color: #fff;
}

.bootThree .panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #337ab7;
}

.bootThree .panel-success {
  border-color: #d6e9c6;
}

.bootThree .panel-success > .panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.bootThree .panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6e9c6;
}

.bootThree .panel-success > .panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d;
}

.bootThree .panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6e9c6;
}

.bootThree .panel-info {
  border-color: #bce8f1;
}

.bootThree .panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.bootThree .panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1;
}

.bootThree .panel-info > .panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f;
}

.bootThree .panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1;
}

.bootThree .panel-warning {
  border-color: #faebcc;
}

.bootThree .panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.bootThree .panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #faebcc;
}

.bootThree .panel-warning > .panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b;
}

.bootThree .panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #faebcc;
}

.bootThree .panel-danger {
  border-color: #ebccd1;
}

.bootThree .panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.bootThree .panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ebccd1;
}

.bootThree .panel-danger > .panel-heading .badge {
  color: #f2dede;
  background-color: #a94442;
}

.bootThree .panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ebccd1;
}

.bootThree .embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

.bootThree .embed-responsive .embed-responsive-item, .bootThree .embed-responsive embed, .bootThree .embed-responsive iframe, .bootThree .embed-responsive object, .bootThree .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.bootThree .embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.bootThree .embed-responsive-4by3 {
  padding-bottom: 75%;
}

.bootThree .well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.bootThree .well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}

.bootThree .well-lg {
  padding: 24px;
  border-radius: 6px;
}

.bootThree .well-sm {
  padding: 9px;
  border-radius: 3px;
}

.bootThree .close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
}

.bootThree .close:focus, .bootThree .close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: .5;
}

.bootThree button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

.bootThree .modal-open {
  overflow: hidden;
}

.bootThree .modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.bootThree .modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  transition: transform .3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.bootThree .modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.bootThree .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.bootThree .modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.bootThree .modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.bootThree .modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.bootThree .modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.bootThree .modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: .5;
}

.bootThree .modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.bootThree .modal-header .close {
  margin-top: -2px;
}

.bootThree .modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.bootThree .modal-body {
  position: relative;
  padding: 15px;
}

.bootThree .modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.bootThree .modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.bootThree .modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.bootThree .modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.bootThree .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .bootThree .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .bootThree .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .bootThree .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .bootThree .modal-lg {
    width: 900px;
  }
}

.bootThree .tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  filter: alpha(opacity=0);
  opacity: 0;
  line-break: auto;
}

.bootThree .tooltip.in {
  filter: alpha(opacity=90);
  opacity: .9;
}

.bootThree .tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}

.bootThree .tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}

.bootThree .tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}

.bootThree .tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}

.bootThree .tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.bootThree .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.bootThree .tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.bootThree .tooltip.top-left .tooltip-arrow {
  right: 5px;
  bottom: 0;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.bootThree .tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.bootThree .tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.bootThree .tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.bootThree .tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.bootThree .tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.bootThree .tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.bootThree .popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
}

.bootThree .popover.top {
  margin-top: -10px;
}

.bootThree .popover.right {
  margin-left: 10px;
}

.bootThree .popover.bottom {
  margin-top: 10px;
}

.bootThree .popover.left {
  margin-left: -10px;
}

.bootThree .popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.bootThree .popover-content {
  padding: 9px 14px;
}

.bootThree .popover > .arrow, .bootThree .popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.bootThree .popover > .arrow {
  border-width: 11px;
}

.bootThree .popover > .arrow:after {
  content: "";
  border-width: 10px;
}

.bootThree .popover.top > .arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, 0.25);
  border-bottom-width: 0;
}

.bootThree .popover.top > .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  content: " ";
  border-top-color: #fff;
  border-bottom-width: 0;
}

.bootThree .popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, 0.25);
  border-left-width: 0;
}

.bootThree .popover.right > .arrow:after {
  bottom: -10px;
  left: 1px;
  content: " ";
  border-right-color: #fff;
  border-left-width: 0;
}

.bootThree .popover.bottom > .arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bootThree .popover.bottom > .arrow:after {
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
}

.bootThree .popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bootThree .popover.left > .arrow:after {
  right: 1px;
  bottom: -10px;
  content: " ";
  border-right-width: 0;
  border-left-color: #fff;
}

.bootThree .carousel {
  position: relative;
}

.bootThree .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.bootThree .carousel-inner > .item {
  position: relative;
  display: none;
  -webkit-transition: .6s ease-in-out left;
  -o-transition: .6s ease-in-out left;
  transition: .6s ease-in-out left;
}

.bootThree .carousel-inner > .item > a > img, .bootThree .carousel-inner > .item > img {
  line-height: 1;
}

@media all and (transform-3d), (-webkit-transform-3d) {
  .bootThree .carousel-inner > .item {
    -webkit-transition: -webkit-transform .6s ease-in-out;
    -o-transition: -o-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }
  .bootThree .carousel-inner > .item.active.right, .bootThree .carousel-inner > .item.next {
    left: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  .bootThree .carousel-inner > .item.active.left, .bootThree .carousel-inner > .item.prev {
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  .bootThree .carousel-inner > .item.active, .bootThree .carousel-inner > .item.next.left, .bootThree .carousel-inner > .item.prev.right {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bootThree .carousel-inner > .active, .bootThree .carousel-inner > .next, .bootThree .carousel-inner > .prev {
  display: block;
}

.bootThree .carousel-inner > .active {
  left: 0;
}

.bootThree .carousel-inner > .next, .bootThree .carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.bootThree .carousel-inner > .next {
  left: 100%;
}

.bootThree .carousel-inner > .prev {
  left: -100%;
}

.bootThree .carousel-inner > .next.left, .bootThree .carousel-inner > .prev.right {
  left: 0;
}

.bootThree .carousel-inner > .active.left {
  left: -100%;
}

.bootThree .carousel-inner > .active.right {
  left: 100%;
}

.bootThree .carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0);
  filter: alpha(opacity=50);
  opacity: .5;
}

.bootThree .carousel-control.left {
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.0001)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000',endColorstr='#00000000',GradientType=1);
  background-repeat: repeat-x;
}

.bootThree .carousel-control.right {
  right: 0;
  left: auto;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000',endColorstr='#80000000',GradientType=1);
  background-repeat: repeat-x;
}

.bootThree .carousel-control:focus, .bootThree .carousel-control:hover {
  color: #fff;
  text-decoration: none;
  filter: alpha(opacity=90);
  outline: 0;
  opacity: .9;
}

.bootThree .carousel-control .glyphicon-chevron-left, .bootThree .carousel-control .glyphicon-chevron-right, .bootThree .carousel-control .icon-next, .bootThree .carousel-control .icon-prev {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
}

.bootThree .carousel-control .glyphicon-chevron-left, .bootThree .carousel-control .icon-prev {
  left: 50%;
  margin-left: -10px;
}

.bootThree .carousel-control .glyphicon-chevron-right, .bootThree .carousel-control .icon-next {
  right: 50%;
  margin-right: -10px;
}

.bootThree .carousel-control .icon-next, .bootThree .carousel-control .icon-prev {
  width: 20px;
  height: 20px;
  font-family: serif;
  line-height: 1;
}

.bootThree .carousel-control .icon-prev:before {
  content: '\2039';
}

.bootThree .carousel-control .icon-next:before {
  content: '\203a';
}

.bootThree .carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
}

.bootThree .carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000\9;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 10px;
}

.bootThree .carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #fff;
}

.bootThree .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.bootThree .carousel-caption .btn {
  text-shadow: none;
}

@media screen and (min-width: 768px) {
  .bootThree .carousel-control .glyphicon-chevron-left, .bootThree .carousel-control .glyphicon-chevron-right, .bootThree .carousel-control .icon-next, .bootThree .carousel-control .icon-prev {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }
  .bootThree .carousel-control .glyphicon-chevron-left, .bootThree .carousel-control .icon-prev {
    margin-left: -10px;
  }
  .bootThree .carousel-control .glyphicon-chevron-right, .bootThree .carousel-control .icon-next {
    margin-right: -10px;
  }
  .bootThree .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px;
  }
  .bootThree .carousel-indicators {
    bottom: 20px;
  }
}

.bootThree .btn-group-vertical > .btn-group:after, .bootThree .btn-group-vertical > .btn-group:before, .bootThree .btn-toolbar:after, .bootThree .btn-toolbar:before, .bootThree .clearfix:after, .bootThree .clearfix:before, .bootThree .container-fluid:after, .bootThree .container-fluid:before, .bootThree .container:after, .bootThree .container:before, .bootThree .dl-horizontal dd:after, .bootThree .dl-horizontal dd:before, .bootThree .form-horizontal .form-group:after, .bootThree .form-horizontal .form-group:before, .bootThree .modal-footer:after, .bootThree .modal-footer:before, .bootThree .modal-header:after, .bootThree .modal-header:before, .bootThree .nav:after, .bootThree .nav:before, .bootThree .navbar-collapse:after, .bootThree .navbar-collapse:before, .bootThree .navbar-header:after, .bootThree .navbar-header:before, .bootThree .navbar:after, .bootThree .navbar:before, .bootThree .pager:after, .bootThree .pager:before, .bootThree .panel-body:after, .bootThree .panel-body:before, .bootThree .row:after, .bootThree .row:before {
  display: table;
  content: " ";
}

.bootThree .btn-group-vertical > .btn-group:after, .bootThree .btn-toolbar:after, .bootThree .clearfix:after, .bootThree .container-fluid:after, .bootThree .container:after, .bootThree .dl-horizontal dd:after, .bootThree .form-horizontal .form-group:after, .bootThree .modal-footer:after, .bootThree .modal-header:after, .bootThree .nav:after, .bootThree .navbar-collapse:after, .bootThree .navbar-header:after, .bootThree .navbar:after, .bootThree .pager:after, .bootThree .panel-body:after, .bootThree .row:after {
  clear: both;
}

.bootThree .center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.bootThree .pull-right {
  float: right !important;
}

.bootThree .pull-left {
  float: left !important;
}

.bootThree .hide {
  display: none !important;
}

.bootThree .show {
  display: block !important;
}

.bootThree .invisible {
  visibility: hidden;
}

.bootThree .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.bootThree .hidden {
  display: none !important;
}

.bootThree .affix {
  position: fixed;
}

@-ms-viewport {
  .bootThree {
    width: device-width;
  }
}

.bootThree .visible-lg, .bootThree .visible-md, .bootThree .visible-sm, .bootThree .visible-xs {
  display: none !important;
}

.bootThree .visible-lg-block, .bootThree .visible-lg-inline, .bootThree .visible-lg-inline-block, .bootThree .visible-md-block, .bootThree .visible-md-inline, .bootThree .visible-md-inline-block, .bootThree .visible-sm-block, .bootThree .visible-sm-inline, .bootThree .visible-sm-inline-block, .bootThree .visible-xs-block, .bootThree .visible-xs-inline, .bootThree .visible-xs-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .bootThree .visible-xs {
    display: block !important;
  }
  .bootThree table.visible-xs {
    display: table !important;
  }
  .bootThree tr.visible-xs {
    display: table-row !important;
  }
  .bootThree td.visible-xs, .bootThree th.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .bootThree .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .bootThree .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .bootThree .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .bootThree .visible-sm {
    display: block !important;
  }
  .bootThree table.visible-sm {
    display: table !important;
  }
  .bootThree tr.visible-sm {
    display: table-row !important;
  }
  .bootThree td.visible-sm, .bootThree th.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .bootThree .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .bootThree .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .bootThree .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .bootThree .visible-md {
    display: block !important;
  }
  .bootThree table.visible-md {
    display: table !important;
  }
  .bootThree tr.visible-md {
    display: table-row !important;
  }
  .bootThree td.visible-md, .bootThree th.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .bootThree .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .bootThree .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .bootThree .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .bootThree .visible-lg {
    display: block !important;
  }
  .bootThree table.visible-lg {
    display: table !important;
  }
  .bootThree tr.visible-lg {
    display: table-row !important;
  }
  .bootThree td.visible-lg, .bootThree th.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .bootThree .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .bootThree .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .bootThree .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .bootThree .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .bootThree .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .bootThree .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .bootThree .hidden-lg {
    display: none !important;
  }
}

.bootThree .visible-print {
  display: none !important;
}

@media print {
  .bootThree .visible-print {
    display: block !important;
  }
  .bootThree table.visible-print {
    display: table !important;
  }
  .bootThree tr.visible-print {
    display: table-row !important;
  }
  .bootThree td.visible-print, .bootThree th.visible-print {
    display: table-cell !important;
  }
}

.bootThree .visible-print-block {
  display: none !important;
}

@media print {
  .bootThree .visible-print-block {
    display: block !important;
  }
}

.bootThree .visible-print-inline {
  display: none !important;
}

@media print {
  .bootThree .visible-print-inline {
    display: inline !important;
  }
}

.bootThree .visible-print-inline-block {
  display: none !important;
}

@media print {
  .bootThree .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .bootThree .hidden-print {
    display: none !important;
  }
}

.bootThree .breakingNews {
  width: 100%;
  height: 40px;
  background: inherit;
  position: relative;
  overflow: hidden;
}

.bootThree .breakingNews > .bn-title {
  width: auto;
  height: 40px;
  display: inline-block;
  background: inherit;
  position: relative;
  padding-top: 4px;
}

.bootThree .breakingNews > .bn-title > h2 {
  display: inline-block;
  margin: 0;
  padding: 0 0px;
  line-height: 40px;
  font-size: 16px;
  color: #ffcb08;
  height: 40px;
  box-sizing: border-box;
  font-family: 'Source Sans Pro',sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.bootThree .breakingNews > .bn-title > span {
  width: 0;
  position: absolute;
  right: -10px;
  top: 10px;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent;
}

.bootThree .breakingNews > ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  left: 170px;
  top: 0;
  right: 40px;
  height: 40px;
  font-size: 14px;
}

.bootThree .breakingNews > ul > li {
  position: absolute;
  height: 40px;
  width: 100%;
  line-height: 40px;
  display: none;
}

.bootThree .breakingNews > ul > li > a {
  text-decoration: none;
  color: #fff;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal;
  font-family: 'Source Sans Pro',sans-serif;
  word-spacing: 1px;
  padding-top: 4px;
}

.bootThree .breakingNews > ul > li > a > span {
  color: #ffcb08;
}

.bootThree .breakingNews > ul > li > a:hover {
  color: #ffcb08;
}

.bootThree .breakingNews > .bn-navi {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
}

.bootThree .breakingNews > .bn-navi > span {
  width: 20px;
  height: 40px;
  position: absolute;
  top: 0;
  cursor: pointer;
  opacity: 0.3;
  background-image: url(/sites/all/themes/anc/image/bn-arrows.html);
  background-repeat: no-repeat;
}

.bootThree .breakingNews > .bn-navi > span:hover {
  opacity: 1;
}

.bootThree .breakingNews > .bn-navi > span:first-child {
  background-position: left center;
  left: 0;
}

.bootThree .breakingNews > .bn-navi > span:last-child {
  background-position: right center;
  right: 0;
}

.bootThree .breakingNews:hover .bn-navi {
  opacity: 1;
}

.bootThree .bn-large {
  height: 50px;
}

.bootThree .bn-large > .bn-title {
  height: 50px;
}

.bootThree .bn-large > .bn-title > h2 {
  line-height: 50px;
  font-size: 26px;
}

.bootThree .bn-large > .bn-title > span {
  top: 15px;
}

.bootThree .bn-large > ul {
  height: 50px;
  left: 250px;
  font-size: 20px;
}

.bootThree .bn-large > ul > li {
  height: 50px;
  line-height: 50px;
}

.bootThree .bn-large > .bn-navi {
  height: 50px;
}

.bootThree .bn-large > .bn-navi > span {
  height: 50px;
}

.bootThree .bn-small {
  height: 30px;
}

.bootThree .bn-small > .bn-title {
  height: 30px;
}

.bootThree .bn-small > .bn-title > h2 {
  line-height: 30px;
  font-size: 18px;
}

.bootThree .bn-small > .bn-title > span {
  top: 5px;
}

.bootThree .bn-small > ul {
  height: 30px;
  left: 200px;
  font-size: 14px;
}

.bootThree .bn-small > ul > li {
  height: 30px;
  line-height: 30px;
}

.bootThree .bn-small > .bn-navi {
  height: 30px;
}

.bootThree .bn-small > .bn-navi > span {
  height: 30px;
}

.bootThree .bn-red {
  border-color: #f44a56;
}

.bootThree .bn-red > .bn-title {
  background: #f44a56;
}

.bootThree .bn-red > .bn-title > span {
  border-left-color: #f44a56;
}

.bootThree .bn-red > ul > li > a:hover, .bootThree .bn-red > ul > li > a > span {
  color: #f44a56;
}

.bootThree .bn-green {
  border-color: #27ae60;
}

.bootThree .bn-green > .bn-title {
  background: #27ae60;
}

.bootThree .bn-green > .bn-title > span {
  border-left-color: #27ae60;
}

.bootThree .bn-green > ul > li > a:hover, .bootThree .bn-green > ul > li > a > span {
  color: #27ae60;
}

.bootThree .bn-purple {
  border-color: #795aac;
}

.bootThree .bn-purple > .bn-title {
  background: #795aac;
}

.bootThree .bn-purple > .bn-title > span {
  border-left-color: #795aac;
}

.bootThree .bn-purple > ul > li > a:hover, .bootThree .bn-purple > ul > li > a > span {
  color: #795aac;
}

.bootThree .bn-turquoise {
  border-color: #1fb5ad;
}

.bootThree .bn-turquoise > .bn-title {
  background: #1fb5ad;
}

.bootThree .bn-turquoise > .bn-title > span {
  border-left-color: #1fb5ad;
}

.bootThree .bn-turquoise > ul > li > a:hover, .bootThree .bn-turquoise > ul > li > a > span {
  color: #1fb5ad;
}

.bootThree .bn-orange {
  border-color: #f46e27;
}

.bootThree .bn-orange > .bn-title {
  background: #f46e27;
}

.bootThree .bn-orange > .bn-title > span {
  border-left-color: #f46e27;
}

.bootThree .bn-orange > ul > li > a:hover, .bootThree .bn-orange > ul > li > a > span {
  color: #f46e27;
}

.bootThree .bn-black {
  border-color: #333;
}

.bootThree .bn-black > .bn-title {
  background: #333;
}

.bootThree .bn-black > .bn-title > span {
  border-left-color: #333;
}

.bootThree .bn-black > ul > li > a:hover, .bootThree .bn-black > ul > li > a > span {
  color: #333;
}

.bootThree .bn-yellow {
  border-color: #d3ac23;
}

.bootThree .bn-yellow > .bn-title {
  background: #d3ac23;
}

.bootThree .bn-yellow > .bn-title > span {
  border-left-color: #d3ac23;
}

.bootThree .bn-yellow > ul > li > a:hover, .bootThree .bn-yellow > ul > li > a > span {
  color: #d3ac23;
}

.bootThree .bn-light {
  border-color: #DDD;
}

.bootThree .bn-light > .bn-title {
  background: #DDD;
}

.bootThree .bn-light > .bn-title > h2 {
  color: #333;
}

.bootThree .bn-light > .bn-title > span {
  border-left-color: #DDD;
}

.bootThree .bn-light > ul > li > a:hover, .bootThree .bn-light > ul > li > a > span {
  color: #DDD;
}

.bootThree .bn-pink {
  border-color: #d65aac;
}

.bootThree .bn-pink > .bn-title {
  background: #d65aac;
}

.bootThree .bn-pink > .bn-title > span {
  border-left-color: #d65aac;
}

.bootThree .bn-pink > ul > li > a:hover, .bootThree .bn-pink > ul > li > a > span {
  color: #d65aac;
}

.bootThree .bn-darkred {
  border-color: #d01e1e;
}

.bootThree .bn-darkred > .bn-title {
  background: #d01e1e;
}

.bootThree .bn-darkred > .bn-title > span {
  border-left-color: #d01e1e;
}

.bootThree .bn-darkred > ul > li > a:hover, .bootThree .bn-darkred > ul > li > a > span {
  color: #d01e1e;
}

.bootThree .bn-bordernone {
  border: none;
}

.bootThree .bn-italic > ul > li > a {
  font-style: italic;
}

.bootThree .bn-bold > ul > li > a {
  font-weight: bold;
}

.bootThree .breakingNews > .bn-navi, .bootThree .breakingNews > ul > li > a, .bootThree .breakingNews > .bn-navi > span {
  transition: .25s linear;
  -moz-transition: .25s linear;
  -webkit-transition: .25s linear;
}

.bootThree .header {
  position: relative;
  -moz-transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  text-align: left;
  width: 100%;
  z-index: 100;
  background-color: #ffffff;
}

.bootThree .header.affix {
  position: fixed;
  -moz-transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  text-align: left;
  width: 100%;
  z-index: 100;
  background-color: #ffffff;
}

.bootThree .header .widget, .bootThree .header .widget-title, .bootThree .navbar-nav, .bootThree .navbar {
  margin: 0 !important;
  border-radius: 0 !important;
}

.bootThree .widget-title {
  position: relative;
}

.bootThree .widget-title a {
  position: absolute;
  right: 0;
  bottom: 20px;
  font-size: 13px;
}

.bootThree .navbar-default {
  border: 0;
  box-shadow: none !important;
  background-color: transparent;
}

.bootThree .navbar-brand {
  font-size: 24px;
  color: #fff !important;
  padding: 0 0 0 0;
  margin: 0;
  line-height: 1;
  text-transform: capitalize;
  height: auto;
  text-decoration: none !important;
  font-weight: 700;
}

.bootThree .navbar-brand img {
  width: 100%;
}

.bootThree .navbar-nav > li > a i {
  margin-right: 3px;
}

.bootThree .navbar-nav > li > a {
  font-size: 14px;
  color: #fff !important;
  padding: 10px 15px;
  text-transform: uppercase;
  text-decoration: none !important;
  line-height: 30px;
  font-family: "Source Sans Pro",sans-serif;
  letter-spacing: 1px;
}

.bootThree .yamm-content .links-widget {
  padding: 20px 0 0;
}

.bootThree .yamm-content .links-widget a {
  color: #111111 !important;
  font-family: "Source Sans Pro",sans-serif;
}

.bootThree .navbar-nav > li {
  -webkit-transition: background-color 0.7s ease 0s;
  -moz-transition: background-color 0.7s ease 0s;
  -ms-transition: background-color 0.7s ease 0s;
  -o-transition: background-color 0.7s ease 0s;
  transition: background-color 0.7s ease 0s;
}

.bootThree .navbar-nav > li:hover, .bootThree .navbar-default .navbar-nav > .active > a, .bootThree .navbar-default .navbar-nav > .active > a:hover, .bootThree .navbar-default .navbar-nav > .active > a:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.bootThree .navbar-default .navbar-nav > .open > a, .bootThree .navbar-default .navbar-nav > .open > a:hover, .bootThree .navbar-default .navbar-nav > .open > a:focus {
  background-color: transparent;
}

.bootThree .m30 {
  margin-top: 30px;
}

.bootThree .ads-widget img {
  margin: 0 auto;
  width: auto;
  display: block;
}

.bootThree .yamm .nav, .bootThree .yamm .collapse, .bootThree .yamm .dropup, .bootThree .yamm .dropdown {
  position: static;
}

.bootThree .yamm .dropdown.yamm-fw {
  position: static;
}

.bootThree .yamm .container {
  position: relative;
}

.bootThree .yamm .yamm-content {
  padding: 30px;
}

.bootThree .yamm .dropdown.yamm-fw .dropdown-menu {
  left: 0;
  right: 0;
  padding: 30px;
}

.bootThree .yamm .dropdown {
  position: relative;
}

.bootThree .yamm .dropdown.yamm-fw.yamm-half .dropdown-menu {
  left: auto;
  right: auto;
}

.bootThree .yamm-half .yamm-content ul {
  min-width: 180px;
}

.bootThree .yamm-fw .dropdown-menu li {
  padding: 10px 0px;
}

.bootThree .yamm-fw .dropdown-menu li a {
  padding: 0 !important;
  font-size: 14px;
  background-color: transparent !important;
  text-transform: capitalize;
}

.bootThree .yamm .dropdown .dropdown-menu {
  border-radius: 0;
  margin-top: 20px;
  padding: 0;
}

.bootThree .yamm .dropdown.yamm-fw .dropdown-menu {
  border-radius: 0;
  left: 0;
  background-color: #fff !important;
  margin-top: 20px;
  padding: 0;
  right: 0;
}

.bootThree .yamm .dropdown .dropdown-menu .show-left {
  margin-top: 0;
}

.bootThree .well {
  border: 0;
  padding: 12px 0;
}

.bootThree .yamm p {
  margin-top: 0;
  padding-top: 0;
  border-bottom: 0;
  line-height: 1;
  margin-bottom: 0;
  padding-bottom: 15px;
  font-weight: 600;
  color: #222222;
  font-size: 14px;
}

.bootThree .well .form-control {
  font-size: 12px !important;
  height: 35px;
  margin-bottom: 15px;
}

.bootThree .yamm ul {
  padding: 0;
}

@media (min-width: 992px) {
  .bootThree .yamm-fw {
    position: relative;
  }
  .bootThree .hasmenu:hover > ul, .bootThree .yamm-fw:hover > ul {
    visibility: visible;
    opacity: 1;
    top: 100%;
  }
  .bootThree .navbar-nav .hasmenu ul, .bootThree .navbar-nav .yamm-fw ul {
    -webkit-transition: visibility 0.35s ease-in-out,opacity 0.35s ease-in-out,top 0.35s ease-in-out,bottom 0.35s ease-in-out,left 0.35s ease-in-out,right 0.35s ease-in-out;
    -moz-transition: visibility 0.35s ease-in-out,opacity 0.35s ease-in-out,top 0.35s ease-in-out,bottom 0.35s ease-in-out,left 0.35s ease-in-out,right 0.35s ease-in-out;
    -o-transition: visibility 0.35s ease-in-out,opacity 0.35s ease-in-out,top 0.35s ease-in-out,bottom 0.35s ease-in-out,left 0.35s ease-in-out,right 0.35s ease-in-out;
    transition: visibility 0.35s ease-in-out,opacity 0.35s ease-in-out,top 0.35s ease-in-out,bottom 0.35s ease-in-out,left 0.35s ease-in-out,right 0.35s ease-in-out;
  }
  .bootThree .hasmenu .dropdown-menu, .bootThree .yamm-fw .dropdown-menu {
    min-width: 240px;
    padding: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .bootThree .hasmenu > ul, .bootThree .yamm-fw > ul {
    position: absolute;
    top: 130%;
    visibility: hidden;
    display: block;
    opacity: 0;
    left: 0;
  }
  .bootThree .hasmenu > ul.start-right, .bootThree .yamm-fw > ul.start-right {
    right: 0;
    left: auto;
  }
  .bootThree .hasmenu > ul li, .bootThree .yamm-fw > ul li {
    position: relative;
  }
  .bootThree .hasmenu > ul li:hover > ul, .bootThree .yamm-fw > ul li:hover > ul {
    visibility: visible;
    opacity: 1;
    left: 100%;
  }
  .bootThree .hasmenu > ul li:hover > ul.show-right, .bootThree .yamm-fw > ul li:hover > ul.show-right {
    right: 100%;
  }
}

@media (max-width: 1200px) {
  .bootThree .nav > li > a {
    padding: 1em 0.7em;
  }
}

.bootThree .dropdown {
  position: relative;
}

.bootThree .dropdown-toggle:focus {
  outline: 0;
}

.bootThree .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999999999;
  display: none;
  float: left;
  min-width: 160px;
  padding: 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #ffffff;
  border-radius: 0.25em;
  background-clip: padding-box;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border: 1px solid #efefef;
  box-shadow: none;
}

.bootThree .dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.bootThree .dropdown-menu .divider {
  height: 1px;
  margin: 0.5em 0;
  overflow: hidden;
  background-color: #e6e6e6;
}

.bootThree .dropdown-mega span {
  position: absolute;
  right: 0;
  color: #999999;
  font-style: italic;
}

.bootThree .dropdown-mega > li a:before {
  content: "\f105";
  font-family: "FontAwesome";
  font-size: 13px;
  left: 0;
  display: inline-block !important;
  color: #999;
  padding-right: 8px;
  position: relative;
  top: 0;
}

.bootThree .dropdown-mega > li {
  list-style: none;
}

.bootThree .box h5 {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.bootThree .dropdown-mega > li > a, .bootThree .dropdown-menu > li > a {
  clear: both;
  color: #111111 !important;
  display: block;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 1;
  padding: 15px 20px;
  white-space: nowrap;
  text-transform: capitalize;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.bootThree .dropdown-mega > li, .bootThree .dropdown-menu > li {
  border-bottom: 1px solid #efefef;
}

.bootThree .dropdown-mega > li:last-child, .bootThree .dropdown-menu > li:last-child {
  border-bottom: 0;
}

.bootThree .dropdown-menu > li:hover > a {
  background-color: #242424;
  color: #FFF !important;
}

.bootThree .dropdown-menu > .active > a, .bootThree .dropdown-menu > .active > a:hover, .bootThree .dropdown-menu > .active > a:focus {
  text-decoration: none;
  outline: 0;
  background-color: #00A651;
  color: #fff;
}

.bootThree .dropdown-menu > .disabled > a, .bootThree .dropdown-menu > .disabled > a:hover, .bootThree .dropdown-menu > .disabled > a:focus {
  color: #cccccc;
}

.bootThree .dropdown-menu > .disabled > a:hover, .bootThree .dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: not-allowed;
}

.bootThree .open > .dropdown-menu {
  display: block;
}

.bootThree .open > a {
  outline: 0;
}

.bootThree .dropdown-menu-right {
  left: auto;
  right: 0;
}

.bootThree .dropdown-menu-left {
  left: 0;
  right: auto;
}

.bootThree .dropdown-header {
  display: block;
  padding: 0.5em 1em;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  color: #666666;
  white-space: nowrap;
}

.bootThree .dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990;
}

.bootThree .pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.bootThree .dropup .caret, .bootThree .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 0.3em solid;
  content: "";
}

.bootThree .dropup .dropdown-menu, .bootThree .navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}

@media (min-width: 768px) {
  .bootThree .navbar-right .dropdown-menu {
    left: auto;
    right: 0;
  }
  .bootThree .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}

.bootThree .yamm-content .widget {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.bootThree .widget-title {
  padding: 15px 0 0;
  display: block;
  position: relative;
}

.bootThree .widget-title h4 {
  line-height: 1;
  padding: 0 !important;
  font-weight: 700;
  margin: 0 !important;
  font-size: 16px;
  text-transform: uppercase;
}

.bootThree .widget-title hr {
  border-width: 4px;
  margin-bottom: 0;
}

.bootThree .widget-title hr:before {
  width: 80px;
  background-color: #ddd;
  display: block;
  height: 4px;
  position: relative;
  content: "";
  top: -4px;
}

.bootThree .widget hr {
  border-color: #e0e0e0;
}

.bootThree div.mini-widget-title {
  float: left;
  margin-left: 10px;
  width: 105px;
}

.bootThree div.mini-widget-title a {
  color: #121212;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 20px;
}

.bootThree div.mini-widget-hr {
  border-bottom: 1px solid #e0e0e0;
  height: 1px;
  margin-top: 15px;
  width: 45px;
}

.bootThree div.mini-widget-thumb {
  display: block;
  float: left;
  width: 57px;
}

.bootThree div.mini-widget-thumb a, .bootThree div.mini-widget-thumb img {
  display: inherit;
  height: inherit;
  width: inherit;
}

.bootThree .mini-widget .post {
  margin-bottom: 30px;
}

.bootThree .mini-widget-title small {
  display: block;
  padding: 5px 0 0;
  font-style: italic;
  color: #999;
}

@media (max-width: 1080px) {
  .bootThree .yamm-content div.mini-widget-thumb {
    display: none !important;
  }
  .bootThree div.mini-widget-thumb {
    width: 25px;
    float: left;
  }
  .bootThree div.mini-widget-title {
    margin: 0 0 0 10px;
  }
}

@media (max-width: 992px) {
  .bootThree .navbar-default .navbar-toggle {
    background-color: #231f20 !important;
  }
  .bootThree .navbar-brand {
    padding: 0;
    margin: 0 auto 10px !important;
    width: 100%;
    display: inline-block;
    float: none;
    text-align: center;
  }
  .bootThree .navbar-brand img {
    max-width: 220px;
    padding: 0;
    margin: 0 auto;
  }
  .bootThree .ads-widget img {
    margin: 0 auto !important;
    width: auto;
    display: block;
    float: none;
    text-align: center;
  }
  .bootThree .nav > li > a {
    padding: 10px 10px;
  }
}

@media (max-width: 900px) {
  .bootThree .nav > li > a {
    padding: 10px 10px;
  }
}

@media (max-width: 768px) {
  .bootThree .dropdown-menu {
    background-color: #ffffff !important;
  }
  .bootThree .nav > li > a {
    padding: 10px 10px;
  }
  .bootThree .yamm-content {
    padding: 0 20px !important;
  }
  .bootThree .navbar-nav > li:hover, .bootThree .navbar-default .navbar-nav > .active > a, .bootThree .navbar-default .navbar-nav > .active > a:hover, .bootThree .navbar-default .navbar-nav > .active > a:focus {
    background-color: transparent !important;
  }
}

@media (max-width: 420px) {
  .bootThree .header .widget, .bootThree .header .widget-title, .bootThree .navbar-nav, .bootThree .navbar {
    margin: 0 !important;
    border-radius: 0 !important;
    float: left !important;
    width: 100% !important;
  }
}

@media (max-width: 1406px) {
  .bootThree .dm-social {
    display: none;
  }
}

.bootThree .webform-next {
  background: #008001;
  color: #fff;
}

.bootThree p {
  font-size: 14px;
  line-height: 28px;
}

.bootThree a {
  text-decoration: none;
}

.bootThree a:hover {
  text-decoration: none;
}

.bootThree a:focus {
  outline: none;
}

.bootThree ul, .bootThree ol {
  list-style: none;
  padding: 0;
}

.bootThree .color-overlay {
  background-image: url(/sites/all/themes/anc/images/overlay.png);
}

.bootThree h1, .bootThree h2, .bootThree h3, .bootThree h4, .bootThree h5, .bootThree h6 {
  font-family: 'Nunito',sans-serif;
}

.bootThree .color-overlay {
  bottom: 0;
  height: 100%;
  left: 0;
  min-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

.bootThree .green {
  background-color: #00a651;
}

.bootThree .red {
  background-color: #00a651;
}

.bootThree .blue {
  background-color: #ffcb08;
}

.bootThree .purpal {
  background-color: #231f20;
}

.bootThree .yellow {
  background-color: #ffcb08;
}

.bootThree .brown {
  background-color: #49311c;
}

.bootThree .nopadding {
  padding: 0;
}

.bootThree .top-bar {
  background-color: #FFCB08;
  max-height: 48px;
  position: relative;
  display: block;
  border: 1px solid #FFDB4D;
  border-top: 1px solid #F7C600;
}

.bootThree .weather {
  background-color: #00a651;
  color: #fff;
  display: inline-block;
  font-family: "Nunito",sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  padding-top: 6px;
  text-align: center;
  padding-bottom: 4px;
  padding-left: 5px;
  padding-right: 5px;
}

.bootThree .weather span {
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  margin-bottom: 4px;
}

.bootThree .weather i {
  font-size: 14px;
}

.bootThree .top-bar .block {
  padding: 0;
  margin: 0;
}

.bootThree .top-bar .search {
  background-color: #ffcb08;
  display: inline-block;
  color: #fff;
  padding-left: 20px;
  padding-top: 7px;
}

.bootThree .top-bar .search a {
  color: #FFF;
}

.bootThree .top-bar .search a:hover {
  color: #272727;
}

.bootThree .top-bar .search i {
  font-size: 14px;
  margin: 0 12px;
}

.bootThree #header-container {
  position: relative;
}

.bootThree .top-header {
  padding-top: 20px;
}

.bootThree .breakingNews {
  background-color: #231F20;
  padding-top: 10px;
  padding-left: 20px;
}

.bootThree #anc {
  padding: 15px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background-color: #00A651;
}

.bootThree .logo {
  position: relative;
  z-index: 999;
  padding-right: 20px;
}

.bootThree .navbar-default .navbar-toggle {
  background-color: #231f20 !important;
  width: 50px;
  margin-right: 20px;
}

@media only screen and (min-width: 990px) {
  .bootThree .logo img {
    float: right;
  }
  .bootThree .main-header .nav-outer:before {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 53px;
    width: 100%;
    left: -100%;
    content: "";
    z-index: 9;
    background-color: transparent;
  }
}

.bootThree .bn-title {
  padding-left: 15px;
}

.bootThree .header-image {
  position: relative;
  overflow: hidden;
  margin: 0px 0%;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  background-color: #000000;
  border: 0px solid #272727;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.bootThree .header-image *, .bootThree .header-image *:before, .bootThree .header-image *:after {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.bootThree .header-image img {
  max-width: 100%;
  backface-visibility: hidden;
  vertical-align: top;
}

.bootThree .header-image i {
  position: absolute;
  line-height: 50px;
  width: 50px;
  text-align: center;
  font-size: 28px;
  z-index: 9;
  top: 0;
  left: 0;
}

.bootThree .header-image i.ion-star:after {
  position: absolute;
  top: 3px;
  left: 3px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 75px 75px 0 0;
  border-color: #231f20 transparent transparent;
  z-index: -1;
}

.bootThree .header-image i.ion-volume-medium:after {
  position: absolute;
  top: 3px;
  left: 3px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 75px 75px 0 0;
  border-color: #00a651 transparent transparent;
  z-index: -1;
}

.bootThree .header-image i.ion-share:after {
  position: absolute;
  top: 3px;
  left: 3px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 75px 75px 0 0;
  border-color: #00a651 transparent transparent;
  z-index: -1;
}

.bootThree .header-image i.ion-videocamera:after {
  position: absolute;
  top: 3px;
  left: 3px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 75px 75px 0 0;
  border-color: #ffcb08 transparent transparent;
  z-index: -1;
}

.bootThree .header-image span {
  position: absolute;
  bottom: 0px;
  top: 0;
  left: 0px;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  color: #FFF;
}

.bootThree .header-image span a {
  bottom: 0;
  position: absolute;
  padding: 10px 20px;
  color: #FFF;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Nunito',sans-serif;
  letter-spacing: 1px;
}

.bootThree .header-image span a:hover {
  color: #ffcb08;
}

.bootThree .header-image:hover img, .bootThree .header-image.hover img {
  zoom: 1;
  filter: alpha(opacity=70);
  -webkit-opacity: 0.7;
  opacity: 0.7;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.bootThree .header-image:hover i, .bootThree .header-image.hover i {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.bootThree .slider .btn {
  margin-right: 20px;
}

.bootThree .btn-primary, .bootThree #block-simplenews-1 .btn {
  background-color: #00a651;
  border: 2px solid #231f20;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 0px;
  color: #fff;
}

.bootThree #block-simplenews-1 .btn {
  width: 100%;
  text-align: center;
}

.bootThree .btn-primary:hover {
  background-color: #272727;
  border: 2px solid #272727;
}

.bootThree .btn-white {
  background-color: transparent;
  border: 1px solid #fff;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 0px;
  color: #ffcb08;
}

.bootThree .btn-white:hover {
  color: #595959;
  background-color: #fff;
  border: 1px solid #fff;
}

.bootThree .slider-caption {
  position: absolute;
  bottom: 30%;
  z-index: 9999;
  width: 50%;
}

.bootThree .slider-title {
  font-size: 42px;
  text-transform: uppercase;
  color: #fff;
  line-height: 52px;
}

.bootThree .slider-content {
  font-size: 18px;
  padding: 10px 0px 15px 0px;
  color: #fff;
}

.bootThree .slider .owl-next {
  background-color: rgba(255, 255, 255, 0.9) !important;
  color: #242424 !important;
  position: absolute;
  right: 0px;
  top: 45%;
  opacity: 0;
}

.bootThree .slider:hover .owl-next, .bootThree .slider:hover .owl-prev {
  opacity: 1;
}

.bootThree .slider .owl-prev {
  background-color: rgba(255, 255, 255, 0.9) !important;
  color: #242424 !important;
  left: 0;
  position: absolute;
  top: 45%;
  opacity: 0;
}

.bootThree .slider .owl-controls .owl-prev, .bootThree .slider .owl-controls .owl-next {
  font-size: 30px;
  color: #fff;
}

.bootThree .feature-item {
  padding: 2px;
}

.bootThree .navbar-default, .bootThree .header, .bootThree .flex-direction-nav a, .bootThree .mini-widget .post:hover a, .bootThree .widget-title hr:before {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #ffcb08 !important;
}

.bootThree a, .bootThree .widget-title a {
  color: #00A652;
}

.bootThree a:hover, .bootThree a:focus {
  color: #0080c9;
}

.bootThree .navbar-collapse {
  padding-left: 0px;
}

.bootThree .main-wrapper {
  padding: 60px 0;
}

.bootThree .lifestyle {
  padding: 0px 0 0;
  display: block;
  position: relative;
  margin-bottom: 20px;
}

.bootThree .lifestyle h4 {
  line-height: 1;
  padding: 0 !important;
  font-weight: 700;
  margin: 0 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.bootThree .lifestyle hr {
  border-width: 2px;
  margin-bottom: 0;
  margin-top: 25px;
}

.bootThree .lifestyle hr:before {
  width: 80px;
  background-color: #ffcb08;
  display: block;
  height: 2px;
  position: relative;
  content: "";
  top: -2px;
}

.bootThree .fashion {
  text-align: center;
  padding-bottom: 20px;
}

.bootThree .latest-category .fashion h1, .bootThree .fashion h1 {
  text-transform: capitalize;
  font-size: 30px;
  color: #242424;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.bootThree .latest-category .fashion h1:hover, .bootThree .fashion h1:hover {
  color: #FFCC00;
}

.bootThree .fashion h2 {
  color: #231f20;
  font-size: 16px;
  text-transform: uppercase;
  padding-top: 10px;
  margin-top: 0px;
  padding-bottom: 10px;
  letter-spacing: 1px;
  font-weight: 600;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.bootThree .fashion h2:hover {
  color: #ffcb08;
}

.bootThree .fashion i {
  padding-right: 10px;
  color: #a6a6a6;
}

.bootThree .fashion span {
  color: #FFF;
}

.bootThree .lorem {
  color: #272727;
  display: inline-block;
  float: left;
  width: auto;
  height: auto;
  line-height: 44px;
  font-size: 50px;
  font-weight: 500;
  margin: 0.1em 0.2em 0em 0;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
}

.bootThree .paragraph p {
  line-height: 28px;
  color: #595959;
}

.bootThree .btn-lifestyle {
  padding-top: 13px;
  font-size: 14px;
  color: #fff;
  background-color: #ffcb08;
  text-transform: uppercase;
  border: 0px;
  border-radius: 0px;
  float: left;
}

.bootThree .btn-lifestyle:hover {
  background-color: #272727;
  color: #fff;
}

.bootThree .paragraph {
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #eee;
}

.bootThree .paragraph a {
  color: #fff;
  background-color: #ffcb08;
  padding: 7px 15px;
  margin-left: 10px;
  margin-top: 5px;
  float: right;
}

.bootThree .paragraph a:hover {
  background-color: #272727;
  color: #fff;
}

.bootThree .banner img {
  margin: 50px auto 50px;
}

.bootThree .whats-hot {
  padding: 0 20px;
}

.bootThree .media-body small {
  font-style: italic;
  color: #595959;
}

.bootThree .widget {
  display: block;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
}

.bootThree .section {
  margin-top: 60px;
}

.bootThree .media-sidebar {
  padding-top: 30px;
}

.bootThree .media {
  margin-top: 20px;
}

.bootThree .media-heading {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  padding-top: 10px;
  color: #242424;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.bootThree .media-heading:hover {
  color: #ffcb08;
}

.bootThree .technology span {
  color: #FFF;
  font-size: 12px;
  text-transform: uppercase;
  padding: 2px 10px;
  margin-right: 2px;
}

.bootThree .technology p {
  margin-bottom: 0;
}

.bootThree .papular-videos {
  background-color: #f4c004;
  padding: 50px 0;
}

.bootThree .papular-videos h2, .bootThree .papular-videos p, .bootThree .papular-videos h4, .bootThree .papular-videos .lorem {
  color: #242424;
}

.bootThree .carousel-video {
  backface-visibility: #242424;
}

.bootThree .carousel-cell {
  width: 100%;
}

.bootThree .flickity-page-dots {
  bottom: 0px;
}

.bootThree .flickity-page-dots .dot {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: transparent;
  border: 2px solid white;
}

.bootThree .flickity-page-dots .dot.is-selected {
  background: white;
}

.bootThree .latest-category {
  padding: 60px 0px;
}

.bootThree .author img {
  margin-bottom: 20px;
}

.bootThree .social-media-widget ul {
  padding: 0;
  margin: 0 !important;
}

.bootThree .social-media-widget li {
  text-align: center;
  padding: 20px;
  list-style: none;
  float: left;
  width: 33.33%;
  margin: 0 !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.bootThree .social-media-widget li:hover {
  zoom: 1;
  filter: alpha(opacity=80);
  opacity: 0.8;
}

.bootThree .social-media-widget small {
  font-family: 'Nunito',sans-serif;
  line-height: 1;
  letter-spacing: 1px;
  padding: 10px 0 0;
}

.bootThree .social-media-widget li i {
  font-size: 24px;
}

.bootThree .social-media-widget ul li a {
  color: #ffffff !important;
}

.bootThree .social-media-widget li small {
  display: block;
}

.bootThree .social-media-widget li.facebook {
  background-color: #3b5998;
}

.bootThree .social-media-widget li.twitter {
  background-color: #55acee;
}

.bootThree .social-media-widget li.googleplus {
  background-color: #dd4b39;
}

.bootThree .social-media-widget li.pinterest {
  background-color: #cb2027;
}

.bootThree .social-media-widget li.linkedin {
  background-color: #007bb5;
}

.bootThree .social-media-widget li.rss {
  background-color: #F36F24;
}

.bootThree .social-media-widget li.youtube {
  background-color: #bb0000;
}

.bootThree .social-media-widget li.instagram {
  background-color: #125688;
}

.bootThree .latest-post {
  margin-top: 20px;
}

.bootThree .img-padding {
  margin-bottom: 20px;
}

.bootThree .interview a h2 {
  font-size: 16px;
  text-transform: uppercase;
  color: #121212;
  padding-bottom: 10px;
}

.bootThree .ad {
  display: table;
  margin: 0 auto;
}

.bootThree .widget .insta img {
  float: left;
  margin: 5px;
  width: 80px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.bootThree .widget .insta img:hover {
  opacity: 0.5;
}

.bootThree .pagination > li > a, .bootThree .pagination > li > span {
  color: #272727;
}

.bootThree .pagination > .active > a, .bootThree .pagination > .active > span, .bootThree .pagination > .active > a:hover, .bootThree .pagination > .active > span:hover, .bootThree .pagination > .active > a:focus, .bootThree .pagination > .active > span:focus {
  color: #272727;
  background-color: #ffcb08;
  border: 1px solid #ffcb08;
}

.bootThree .pagination > li > a:hover, .bootThree .pagination > li > span:hover {
  background-color: #ffcb08;
}

.bootThree .single-para {
  padding-bottom: 20px;
}

.bootThree .blog-title h1 {
  text-transform: capitalize;
  margin: 0;
}

.bootThree blockquote {
  border-left: 0 none;
  color: #797979;
  font-family: Raleway-SemiBold;
  font-size: 20px;
  line-height: 1.8;
  margin: 50px 20px;
  padding: 0.35em 50px 0.5em;
  position: relative;
  text-transform: capitalize;
}

.bootThree blockquote::before {
  content: "";
  font-family: "FontAwesome";
  color: #ccc;
  display: block;
  font-size: 34px;
  left: 0;
  position: absolute;
  top: -15px;
}

.bootThree .post-sharing {
  display: block;
  width: 100%;
  position: relative;
  margin: 0px 0 0;
}

.bootThree .fb-button {
  font-size: 12px;
  padding-top: 8px;
}

.bootThree .single-blog .fashion {
  text-align: left;
}

.bootThree .breadcrumb {
  margin-bottom: 10px;
  background-color: transparent;
  padding: 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.bootThree .breadcrumb a:first-child {
  margin-left: 0;
}

.bootThree .breadcrumb a {
  color: #797979;
  margin: 0 10px;
  text-transform: capitalize;
  font-size: 14px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.bootThree .breadcrumb a:hover {
  color: #ffcb08;
}

.bootThree .breadcrumb .topic {
  color: #ccc;
}

.bootThree .search .stylish-input-group .input-group-addon {
  background: white !important;
  border-radius: 0;
  border-left: 1px solid #ccc;
}

.bootThree .search .stylish-input-group .form-control {
  border-right: 0;
  box-shadow: 0 0 0;
  border-color: #ccc;
  height: 50px;
}

.bootThree .search .stylish-input-group button {
  border: 0;
  background: transparent;
}

.bootThree .article-img-left {
  float: left;
  height: 50%;
  margin-right: 30px;
  overflow: hidden;
  position: relative;
  width: 50%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.bootThree .article-img-right {
  float: right;
  height: 50%;
  margin-left: 30px;
  overflow: hidden;
  position: relative;
  width: 50%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.bootThree .widget .tag_cloud a {
  background: #ffffff none repeat scroll 0 0;
  border: 1px solid #e2e2e2;
  display: inline-block;
  float: left;
  font-size: 13px;
  font-weight: 500;
  line-height: 30px;
  margin: 0 10px 8px 0;
  padding: 0 20px;
  color: #242424;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.bootThree .widget .tag_cloud a:hover {
  color: #FFCC00;
  border: 1px solid #FFCC00;
}

.bootThree .fashion span {
  font-weight: 12px;
  padding: 2px 10px;
  text-transform: uppercase;
  margin-right: 5px;
}

.bootThree .widget ul li {
  border-bottom: 1px solid #f1f1f1;
}

.bootThree .widget ul li a {
  color: #242424;
  font-size: 14px;
  font-weight: 500;
  line-height: 42px;
  position: relative;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-transform: uppercase;
}

.bootThree .widget ul li a:hover {
  color: #FFCC00;
}

.bootThree .widget ul li a span {
  padding: 5px;
  float: right;
  border: 1px solid #f1f1f1;
  color: #999;
  line-height: 10px;
  margin-top: 10px;
}

.bootThree .widget ul li a span:hover {
  color: #FFCC00;
}

.bootThree .tag-cloud-post i {
  margin-right: 20px;
}

.bootThree .tag-cloud-post a {
  color: #999 !important;
  margin-right: 10px;
}

.bootThree .section-comment {
  margin-top: 40px;
}

.bootThree .media {
  position: relative;
}

.bootThree .media-comment {
  margin-bottom: 50px;
}

.bootThree .time-comment {
  margin-bottom: 10px;
  display: inline-block;
  color: #b4b4b4;
  line-height: 1;
}

.bootThree .media .reply {
  right: 20px;
  padding: 5px 10px;
  position: absolute;
  top: 14px;
  font-size: 12px;
}

.bootThree .comment-reply {
  margin-left: 45px;
  margin-bottom: 50px;
}

.bootThree .comments .well {
  background-color: #ffffff !important;
  border: 0 !important;
  box-shadow: none !important;
  margin-bottom: 0px;
  padding-bottom: 0px !important;
}

.bootThree .commentform textarea.form-control {
  height: 140px !important;
  max-width: 100% !important;
}

.bootThree label {
  font-size: 14px;
}

.bootThree .required {
  color: #DA5333;
  margin-left: 5px;
}

.bootThree .sociallinks {
  padding: 0;
  list-style: none;
}

.bootThree .sociallinks i {
  margin-right: 5px;
}

.bootThree .darkcolor a {
  color: #121212 !important;
}

.bootThree .sociallinks li {
  margin-bottom: 10px;
}

.bootThree .contact-para {
  line-height: 1.8;
  padding-bottom: 20px;
}

.bootThree #map-canvas {
  width: 100%;
  height: 400px;
}

.bootThree .footer-wrapper {
  background-color: #ffcb07;
  border-top: #D9AD00 1px solid;
  width: 100%;
  padding: 50px 0px;
}

.bootThree .footer-wrapper .lifestyle h4 {
  color: #242424;
}

.bootThree .about p {
  line-height: 1.6;
  word-spacing: 1px;
  padding: 15px 0;
  color: #242424;
  text-align: left;
  font-size: 14px;
}

.bootThree .about i {
  padding-bottom: 10px;
  color: #242424;
}

.bootThree .about i:last-child {
  padding-bottom: 0px;
}

.bootThree .about span {
  color: #242424;
  padding-left: 10px;
}

.bootThree .footer-tech {
  color: #fff;
  line-height: 1.6;
}

.bootThree .footer-wrapper .media h4 {
  color: #242424;
}

.bootThree .footer-wrapper .media h4:hover {
  color: #ffcb08;
}

.bootThree .form-control {
  border-radius: 0px;
  margin-bottom: 25px;
}

.bootThree .check {
  padding: 0;
  list-style: none;
}

.bootThree .check li {
  margin-bottom: 10px;
}

.bootThree .check li a {
  color: #242424;
}

.bootThree .check li a:hover {
  color: #ffcb08;
}

.bootThree .footer-bottom {
  background-color: #151515;
}

.bootThree .footer-bottom .navbar {
  min-height: auto !important;
}

.bootThree .footer-bottom .navbar-nav > li > a {
  padding: 0 20px;
  color: #FFF !important;
}

.bootThree .copyright {
  position: relative;
  overflow: hidden;
  padding: 15px 0;
}

.bootThree .copyright p {
  color: #FFF;
  margin-bottom: 0;
}

.bootThree .footer-bottom .navbar-nav {
  min-height: 20px;
}

.bootThree .img-gellary {
  position: relative;
  overflow: hidden;
}

.bootThree .img-gellary img {
  opacity: 0.9;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.bootThree .img-gellary img:hover {
  opacity: 1;
}

.bootThree #spinner {
  background: #fff;
  height: 100%;
  left: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 999999999;
}

.bootThree .spinner-img {
  height: 100%;
  left: 0;
  position: fixed;
  top: 50%;
  width: 100%;
}

.bootThree .page404 {
  text-align: center;
}

.bootThree .page404 h1 {
  font-size: 150px;
}

.bootThree .page404 h3 {
  margin-top: 0;
  margin-bottom: 50px;
}

@media only screen and (min-width: 320px) and (max-width: 359px) {
  .bootThree .logo img {
    margin: 0 auto;
  }
  .bootThree .wrapper {
    margin-top: 0px;
  }
  .bootThree .slider .btn {
    display: none;
  }
  .bootThree .slider-caption {
    position: absolute;
    z-index: 9999;
    width: 70%;
    bottom: 12% !important;
    left: 15%;
  }
  .bootThree .slider-title {
    font-size: 16px;
    margin-bottom: 20px;
    color: #fff;
    line-height: 21px;
    font-weight: 600;
  }
  .bootThree .slider-content {
    display: none;
  }
  .bootThree .breakingNews > ul > li > a {
    padding-top: 0px;
  }
  .bootThree .fashion h2 {
    font-size: 14px;
  }
  .bootThree .banner img {
    margin: 40px auto 0;
  }
  .bootThree .section {
    margin-top: 40px;
  }
  .bootThree .media-heading {
    font-size: 12px;
  }
  .bootThree .section-comment {
    margin-top: 20px;
  }
  .bootThree .comment-reply {
    margin-left: 0px;
  }
  .bootThree .technology p {
    font-size: 12px;
  }
  .bootThree .social-media {
    margin-top: 40px;
  }
  .bootThree .latest-post {
    margin-top: 30px;
  }
  .bootThree .navbar-toggle .icon-bar {
    border-radius: 1px;
    display: block;
    height: 2px;
    margin: 0 auto;
    width: 22px;
  }
  .bootThree .navbar-toggle {
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-top: 8px;
    padding: 10px;
    position: relative;
    text-align: center;
    width: 100%;
    margin-right: 0;
  }
  .bootThree .footer-wrapper .lifestyle {
    margin-top: 20px;
  }
  .bootThree .lifestyle hr {
    margin-top: 10px;
  }
  .bootThree .header-image {
    margin-bottom: 20px;
  }
  .bootThree .media-object {
    display: block;
    width: 50px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .bootThree .main-slider-two {
    margin-top: 8px;
  }
  .bootThree .logo img {
    margin: 0 auto;
  }
  .bootThree .wrapper {
    margin-top: 0px;
  }
  .bootThree .slider .btn {
    display: none;
  }
  .bootThree .slider-caption {
    position: absolute;
    z-index: 9999;
    width: 70%;
    left: 15%;
  }
  .bootThree .slider-title {
    font-size: 16px;
    margin-bottom: 20px;
    color: #fff;
    line-height: 21px;
    font-weight: 600;
  }
  .bootThree .slider-content {
    display: none;
  }
  .bootThree .breakingNews > .bn-title {
    padding-top: 0px;
  }
  .bootThree .breakingNews > ul > li > a {
    padding-top: 0px;
  }
  .bootThree .fashion h2 {
    font-size: 14px;
  }
  .bootThree .banner img {
    margin: 40px auto 0;
  }
  .bootThree .section {
    margin-top: 40px;
  }
  .bootThree .section-comment {
    margin-top: 20px;
  }
  .bootThree .media-heading {
    font-size: 12px;
  }
  .bootThree .technology p {
    font-size: 12px;
  }
  .bootThree .social-media {
    margin-top: 40px;
  }
  .bootThree .latest-post {
    margin-top: 30px;
  }
  .bootThree .footer-wrapper {
    margin-top: 20px;
  }
  .bootThree .navbar-toggle .icon-bar {
    border-radius: 1px;
    display: block;
    height: 2px;
    margin: 0 auto;
    width: 22px;
  }
  .bootThree .navbar-toggle {
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-top: 8px;
    padding: 10px;
    position: relative;
    text-align: center;
    width: 100%;
    margin-right: 0;
  }
  .bootThree .footer-wrapper .lifestyle {
    margin-top: 20px;
  }
  .bootThree .lifestyle hr {
    margin-top: 10px;
  }
  .bootThree .header-image {
    margin-bottom: 20px;
  }
  .bootThree .media-object {
    display: block;
    width: 50px;
  }
}

@media screen and (min-device-width: 480px) and (max-device-width: 767px) and (orientation: landscape) {
  .bootThree .logo img {
    margin: 0 auto;
  }
  .bootThree .wrapper {
    margin-top: 0px;
  }
  .bootThree .slider .btn {
    display: none;
  }
  .bootThree .slider-caption {
    position: absolute;
    z-index: 9999;
    width: 70%;
    bottom: 12% !important;
    left: 15%;
  }
  .bootThree .slider-title {
    font-size: 16px;
    margin-bottom: 20px;
    color: #fff;
    line-height: 21px;
    font-weight: 600;
  }
  .bootThree .slider-content {
    display: none;
  }
  .bootThree .breakingNews > ul > li > a {
    padding-top: 0px;
  }
  .bootThree .fashion h2 {
    font-size: 16px;
  }
  .bootThree .banner img {
    margin: 40px auto 0;
  }
  .bootThree .section {
    margin-top: 40px;
  }
  .bootThree .section-comment {
    margin-top: 20px;
  }
  .bootThree .media-heading {
    font-size: 14px;
  }
  .bootThree .technology p {
    font-size: 14px;
  }
  .bootThree .social-media {
    margin-top: 40px;
  }
  .bootThree .latest-post {
    margin-top: 30px;
  }
  .bootThree .footer-wrapper {
    margin-top: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
  .bootThree .slider-caption {
    position: absolute;
    z-index: 9999;
    width: 70%;
    left: 15%;
  }
  .bootThree .slider-title {
    font-size: 30px;
    margin-bottom: 20px;
    color: #fff;
    font-weight: 600;
  }
  .bootThree .slider-content {
    display: none;
  }
  .bootThree .breakingNews > .bn-title {
    padding-top: 0px;
  }
  .bootThree .breakingNews > ul > li > a {
    padding-top: 0px;
  }
  .bootThree .social-media {
    margin-top: 60px;
  }
  .bootThree .latest-post {
    margin-top: 30px;
  }
  .bootThree .footer-wrapper .lifestyle {
    margin-top: 20px;
  }
  .bootThree .footer-bottom .navbar-nav > li > a {
    padding: 0 15px;
  }
  .bootThree .navbar-nav > li > a {
    font-size: 12px !important;
  }
  .bootThree .yamm .dropdown .dropdown-menu {
    margin-top: 20px;
  }
  .bootThree .yamm .dropdown.yamm-fw .dropdown-menu {
    margin-top: 20px;
  }
  .bootThree .footer-bottom .navbar-nav > li > a {
    padding: 0 15px;
  }
  .bootThree .footer-wrapper .lifestyle {
    margin-top: 20px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1279px) and (orientation: landscape) {
  .bootThree .fashion h2 {
    font-size: 14px;
  }
  .bootThree .media-heading {
    font-size: 12px;
  }
  .bootThree .social-media {
    margin-top: 0px;
  }
}

.bootThree #navbar .menu {
  text-transform: uppercase;
}

.bootThree #navbar .menu .fa {
  margin-right: 5px;
}

.bootThree .search .btn-primary {
  border: none;
}

.bootThree #admin-menu {
  position: fixed;
  top: auto !important;
  bottom: 0px;
}

.bootThree #admin-menu li ul {
  bottom: 28px;
}

.bootThree #admin-menu li ul li {
  position: relative;
}

.bootThree #admin-menu li ul li ul {
  bottom: auto;
  position: absolute;
  bottom: 0;
}

.bootThree .logged-in .footer-bottom {
  padding-bottom: 20px;
}

.bootThree #mini-panel-top_bar i {
  margin-right: 6px;
}

.bootThree #mini-panel-top_bar .nav > li > a:hover {
  text-decoration: none;
  background-color: #FFCB08;
  border-bottom: 2px solid #fff;
}

.bootThree #mini-panel-top_bar .nav > li > a:focus {
  background-color: transparent;
}

.bootThree #mini-panel-top_bar ul li {
  display: inline-block;
}

.bootThree #mini-panel-top_bar ul li a {
  color: #222;
}

.bootThree .pane-block-1 a {
  display: inline-block;
  margin-left: 10px;
  color: #222;
}

.bootThree .pane-block-1 a:hover {
  color: #fff;
}

.bootThree .pane-block-1 {
  padding-top: 12px;
}

.bootThree .pane-system-user-menu {
  padding-top: 4px;
  z-index: 9999;
}

.bootThree .breakingNews a {
  color: #fff;
}

.bootThree .whats-hot .pane-block-3 .btn-default {
  background: #F6C800;
}

.bootThree #twitter-widget-0 {
  max-height: 352px;
  overflow-y: scroll;
  width: 100%;
}

.bootThree #embed-responsive-item {
  max-height: 350px;
}

.bootThree .twitter-tweet {
  width: 100%;
  padding: 20px;
}

.bootThree .whats-hot h2 {
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.bootThree #mini-panel-home_blocks .left h2 {
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.bootThree .pane-block-10 h2 {
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.bootThree .pane-block-3 h2 {
  border-bottom: 1px solid #8DF5BC;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.bootThree #mini-panel-home_blocks .left {
  padding: 20px 10px 10px 10px;
  background: #252122 url(/sites/all/themes/anc/images/current_late_president.jpg) no-repeat center;
  color: #ccc;
  height: 100%;
}

.bootThree #mini-panel-home_blocks .right h2 {
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.bootThree #mini-panel-home_blocks .right {
  color: #000;
  padding: 20px 20px 0 20px;
}

.bootThree #mini-panel-home_blocks .right .views-row {
  padding-top: 4px;
  border-bottom: 1px dotted #333;
  clear: both;
  overflow: hidden;
  padding-bottom: 2px;
}

.bootThree #mini-panel-home_blocks .right .views-field-field-tweet-linked-images {
  display: inline-block;
  float: left;
  max-height: 70px;
  margin-bottom: 5px;
}

.bootThree #mini-panel-home_blocks .right .views-field-field-tweet-linked-images img {
  border: 2px solid #fff;
  margin-right: 15px;
  width: 100px;
}

.bootThree .dropdown-menu li, .bootThree .dropdown-menu li a {
  width: 100%;
  display: block;
}

.bootThree #mini-panel-home_blocks .right a {
  color: #000;
  margin-bottom: 10px;
}

.bootThree #mini-panel-home_blocks .right .readmore a {
  color: #fff;
}

.bootThree #mini-panel-home_blocks .right a:hover {
  color: #666;
}

.bootThree #mini-panel-home_blocks_2 .left {
  background: #00A652 url(/sites/all/themes/anc/images/women_green.jpg) no-repeat center;
  color: #fff;
  padding: 20px;
}

.bootThree #mini-panel-home_blocks_2 .right {
  background: #222;
  color: #fff;
  padding-top: 20px;
  text-align: center;
}

.bootThree #mini-panel-home_blocks_2 .right .pane-content {
  padding-top: 30px;
}

.bootThree #panel-bootstrap-column-1.fa {
  margin-right: 10px;
  color: #222;
}

.bootThree .readmore, .bootThree .node-readmore {
  padding: 3px 5px;
  background-color: #00A651;
  border-radius: 2px;
  margin-top: 10px;
  bottom: 10px;
}

.bootThree .readmore a, .bootThree .node-readmore a {
  color: #fff;
}

.bootThree .front .whats-hot {
  background-color: #FFCB06;
}

.bootThree .news-article {
  margin-bottom: 10px;
}

.bootThree .stripes {
  width: 100%;
  background: #000;
  height: auto;
  padding: 10px 0;
  min-height: 500px;
}

.bootThree .fb-page, .bootThree ._1dro {
  width: 100%;
}

.bootThree .panels-bootstrap-tabs, .bootThree .region-officials {
  background-color: #fff;
  min-height: 475px;
}

.bootThree .panels-bootstrap-tabs li {
  background-color: #F6C800;
}

.bootThree .panels-bootstrap-tabs li a {
  color: #000;
}

.bootThree .panels-bootstrap-tabs .pane-content {
  padding-left: 20px;
}

.bootThree .panels-bootstrap-tabs li:active {
  background-color: #fff;
}

.bootThree .panels-bootstrap-tabs li:active a {
  color: #333;
}

.bootThree .panels-bootstrap-tabs li:hover {
  background-color: #FFCD00;
}

.bootThree .panels-bootstrap-tabs li:hover a {
  color: #333;
}

.bootThree .news-title {
  margin-bottom: 10px;
}

.bootThree #content-area {
  padding-top: 20px;
  padding-bottom: 40px;
}

@media screen and (min-width: 768px) {
  .bootThree .dropdown:hover .dropdown-menu, .bootThree .btn-group:hover .dropdown-menu {
    display: block;
  }
  .bootThree .dropdown-menu {
    top: 26px;
    background: #FFD020;
    border-color: #333;
  }
  .bootThree .dropdown-menu li {
    border-color: #333;
  }
  .bootThree .dropdown-toggle {
    margin-bottom: 2px;
  }
  .bootThree .navbar .dropdown-toggle, .bootThree .nav-tabs .dropdown-toggle {
    margin-bottom: 0;
  }
}

.bootThree .not-logged-in .page-user h1 {
  display: none;
}

.bootThree #search-block-form .btn-primary {
  background-color: #00A651;
  border: none;
  margin-top: 5px;
}

@media (min-width: 720px) {
  .bootThree #user-login {
    width: 50%;
    margin-bottom: 150px;
  }
  .bootThree #user-pass {
    width: 50%;
    margin-bottom: 150px;
  }
}

.bootThree .read {
  margin-top: 30px;
  clear: both;
  background-color: #00A652;
  padding: 8px 15px;
  border: 1px solid #FFCB06;
  width: 102px;
  display: block;
}

.bootThree .read a {
  color: #fff;
  display: block;
}

.bootThree .read:hover {
  background-color: #000;
}

.bootThree .node-readmore {
  background-color: #00A652;
}

.bootThree .social-icons i:hover {
  color: #FFCB06;
}

.bootThree .node-readmore:hover {
  background-color: #FFCB06;
  color: #000;
}

.bootThree #home-video {
  background-color: #00A652;
  padding: 35px 20px;
}

.bootThree .node-readmore a {
  color: #fff;
}

.bootThree #node-15 .field-item {
  float: left;
  margin: 10px;
}

.bootThree .jcarousel-skin-default {
  background: #fff;
}

.bootThree .jcarousel-skin-default .jcarousel-item {
  padding: 0;
  width: 220px;
  height: 150px;
  overflow: hidden;
  border: 1px solid #333;
  list-style: none;
  background: #fff none;
}

.bootThree .jcarousel-skin-default .jcarousel-item-horizontal {
  margin: 0 4px;
}

.bootThree .jcarousel-skin-default .jcarousel-container-horizontal {
  width: 440px;
  height: 190px;
  padding: 20px 40px;
  margin: auto;
}

.bootThree .jcarousel-skin-default .jcarousel-next, .bootThree .jcarousel-skin-default .jcarousel-prev {
  display: block;
  width: 32px;
  height: 32px;
  background-image: url(/sites/all/themes/anc/images/arrows.png);
}

.bootThree .jcarousel-skin-default .jcarousel-next-disabled, .bootThree .jcarousel-skin-default .jcarousel-prev-disabled {
  display: none;
}

.bootThree .jcarousel-skin-default .jcarousel-prev-horizontal {
  position: absolute;
  top: 80px;
  left: 10px;
  background-position: 0 0;
}

.bootThree .jcarousel-skin-default .jcarousel-prev-horizontal:hover {
  background-position: -32px 0;
}

.bootThree .jcarousel-skin-default .jcarousel-prev-horizontal:active {
  background-position: -64px 0;
}

.bootThree .jcarousel-skin-default .jcarousel-next-horizontal {
  position: absolute;
  top: 80px;
  right: 10px;
  background-position: 0 -32px;
}

.bootThree .jcarousel-skin-default .jcarousel-next-horizontal:hover {
  background-position: -32px -32px;
}

.bootThree .jcarousel-skin-default .jcarousel-next-horizontal:active {
  background-position: -64px -32px;
}

.bootThree #backtotop {
  background: url(/sites/all/themes/anc/images/backtotop.png) no-repeat center center;
  bottom: 40px;
  cursor: pointer;
  display: none;
  height: 70px;
  position: fixed;
  right: 20px;
  text-indent: -9999px;
  width: 70px;
  z-index: 1;
}

.bootThree .compact-form-label {
  margin-top: 7px;
  margin-left: 10px;
}

.bootThree #block-views-popular-posts-block .views-field-field-headline-image img {
  border: 2px solid #000;
  margin-right: 15px;
}

.bootThree #block-views-popular-posts-block a {
  color: #000;
  margin-bottom: 10px;
}

.bootThree #block-views-popular-posts-block .readmore a {
  color: #fff;
}

.bootThree #block-views-popular-posts-block a:hover {
  color: #666;
}

.bootThree #mini-panel-top_bar .dropdown-toggle {
  background-color: transparent;
}

.bootThree .media-left .img-responsive {
  width: 100px !important;
  height: 80px !important;
}

.bootThree #header-blocks {
  bottom: 0;
  margin-bottom: 0;
}

@media (max-width: 760px) {
  .bootThree #header-group {
    background: transparent !important;
    position: relative;
  }
  .bootThree #header-group h1 {
    display: none;
  }
  .bootThree .top-bar .pane-block-1 {
    display: none;
  }
  .bootThree #mini-panel-top_bar .nav > li > a:hover {
    text-decoration: none;
    background-color: transparent;
    border-top: 2px solid #FFCB06;
    border-bottom: none;
  }
  .bootThree .breakingNews {
    margin-top: 12px;
    height: auto;
  }
  .bootThree .whats-hot {
    margin-top: 0 !important;
  }
  .bootThree .flickity-slider {
    margin-bottom: 0 !important;
  }
}

.bootThree .footer-wrapper .nav > li > a {
  position: relative;
  display: block;
  padding: 5px 10px;
  color: #000;
}

.bootThree .page-contact .panel-default > .panel-heading {
  color: #000;
  background-color: #F3D58D;
  border: #FFCB07 solid 1px;
}

.bootThree .page-contact .panel-default > .panel-heading a {
  display: block;
}

.bootThree .page-contact h2 {
  padding-bottom: 5px;
  margin-bottom: 20px;
  border-bottom: 4px solid #00A651;
}

.bootThree #anc ul li a {
  position: relative;
  display: block;
  padding: 5px 15px;
  float: left;
  background: transparent;
  color: #fff;
  font-size: 12px;
  margin-right: 10px;
}

.bootThree #anc ul li a:hover {
  background: #231F20;
}

.bootThree #anc ul li {
  position: relative;
  background: transparent;
}

.bootThree #block-views-officials-block-block-1 .views-field-title {
  text-align: center;
}

.bootThree #block-views-officials-block-block-1 .views-field-field-picture {
  text-align: center;
}

.bootThree #block-views-officials-block-block-1 .views-field-field-picture img {
  border: 1px solid #FFCB07;
  border-radius: 4px;
  padding: 5px;
  margin: 0 auto;
}

.bootThree #block-views-officials-block-block-1 .views-field-field-picture img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.bootThree #block-views-officials-block-block-1 {
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

@media (min-width: 760px) {
  .bootThree #anc ul li a {
    background: #008241;
    margin-right: 10px;
  }
}

.bootThree #block-block-9 {
  margin-top: 20px;
}

.bootThree .sharethis-wrapper {
  margin-top: 30px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .bootThree .social-icons {
    display: none;
  }
}

@media only screen and (min-width: 1224px) {
  .bootThree #mini-panel-home_blocks_2 .left, .bootThree #mini-panel-home_blocks_2 .right, .bootThree #mini-panel-home_blocks .left, .bootThree #mini-panel-home_blocks .right {
    height: 420px;
  }
  .bootThree #mini-panel-home_blocks .left p {
    font-size: 14px;
    line-height: 20px;
  }
  .bootThree #mini-panel-home_blocks .right .views-row {
    padding-top: 8px;
    clear: both;
    overflow: hidden;
  }
  .bootThree #mini-panel-home_blocks .right a, .bootThree #mini-panel-home_blocks .right {
    color: #000;
    line-height: 14px;
  }
}

@media only screen and (min-width: 1300px) {
  .bootThree #mini-panel-home_blocks .left p {
    font-size: 14px;
    line-height: 26px;
  }
  .bootThree #mini-panel-home_blocks .right a, .bootThree #mini-panel-home_blocks .right {
    color: #000;
    line-height: 18px;
  }
  .bootThree #mini-panel-home_blocks_2 .left, .bootThree #mini-panel-home_blocks_2 .right, .bootThree #mini-panel-home_blocks .left, .bootThree #mini-panel-home_blocks .right {
    height: 440px !important;
  }
}

@media only screen and (min-width: 1600px) {
  .bootThree #mini-panel-home_blocks_2 .left, .bootThree #mini-panel-home_blocks_2 .right, .bootThree #mini-panel-home_blocks .left, .bootThree #mini-panel-home_blocks .right {
    height: 450px !important;
  }
  .bootThree #mini-panel-home_blocks .left p {
    font-size: 14px;
    line-height: 28px;
  }
  .bootThree #mini-panel-home_blocks .right a, .bootThree #mini-panel-home_blocks .right {
    color: #000;
    line-height: 24px;
  }
}

@media only screen and (min-width: 1680px) {
  .bootThree #mini-panel-home_blocks_2 .left, .bootThree #mini-panel-home_blocks_2 .right, .bootThree #mini-panel-home_blocks .left, .bootThree #mini-panel-home_blocks .right {
    height: 480px;
  }
  .bootThree #mini-panel-home_blocks .left p {
    font-size: 14px;
    line-height: 28px;
  }
  .bootThree #mini-panel-home_blocks .right a, .bootThree #mini-panel-home_blocks .right {
    color: #000;
    line-height: 16px;
  }
}

@media only screen and (min-width: 1824px) {
  .bootThree #mini-panel-home_blocks_2 .left, .bootThree #mini-panel-home_blocks_2 .right, .bootThree #mini-panel-home_blocks .left, .bootThree #mini-panel-home_blocks .right {
    height: 480px !important;
  }
  .bootThree #mini-panel-home_blocks_2 .right {
    padding-top: 5px;
  }
}

.bootThree .views-field-field-dpf a {
  text-align: center;
  padding: 5px;
  background: #D0FFE7;
  border: 1px solid #333;
  color: #fff;
}

.bootThree .views-field-field-dpf {
  text-align: center;
}

.bootThree #edit-views-send-message-format, .bootThree #views-form-media-contacts-page #edit-to, .bootThree #views-form-media-contacts-page #edit-token, .bootThree .help-block {
  display: none;
}

.bootThree .main-slider-two .slide-column {
  position: relative;
  padding: 0px 2px;
}

.bootThree .main-slider-two .slide-column .row {
  margin: 0px 2px;
}

.bootThree .main-slider-two .slide-column .inner-slide {
  padding: 0px 2px;
}

.bootThree .news-block-three {
  position: relative;
}

.bootThree .news-block-three .inner-box {
  position: relative;
  overflow: hidden;
}

.bootThree .news-block-three .inner-box .image {
  position: relative;
}

.bootThree .news-block-three .inner-box:hover .image img {
  -webkit-transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}

.bootThree .news-block-three .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}

.bootThree .news-block-three .inner-box .image .overlay-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}

.bootThree .news-block-three .inner-box .image .overlay-box .play-btn {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 34px;
  height: 34px;
  line-height: 28px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  color: #ffffff;
  border: 3px solid #ffffff;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.bootThree .news-block-three .inner-box .image .overlay-box .play-btn .icon {
  font-size: 12px;
  padding-left: 3px;
}

.bootThree .news-block-three .inner-box .image .overlay-box .content {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 100%;
  text-align: center;
  padding: 0px 15px 20px 15px;
}

.bootThree .news-block-three .inner-box .image .overlay-box .content .tag {
  position: relative;
  color: #ffffff;
  font-size: 12px;
  display: inline-block;
  padding: 0px 12px;
  font-weight: 400;
  margin-bottom: 12px;
  text-transform: uppercase;
  background-color: #00A54F;
}

.bootThree .news-block-three .inner-box .image .overlay-box .content .tag a {
  color: #ffffff;
}

.bootThree .news-block-three .inner-box .image .overlay-box .content h3 {
  position: relative;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.6em;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.bootThree .news-block-three .inner-box .image .overlay-box .content h3 a {
  color: #ffffff;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.bootThree .news-block-three .inner-box .image .overlay-box .content h3 a:hover {
  color: #A2FFCE;
}

.bootThree .news-block-three .inner-box .image .overlay-box .content .post-meta {
  position: relative;
}

.bootThree .news-block-three .inner-box .image .overlay-box .content .post-meta li {
  position: relative;
  color: #ffffff;
  font-size: 11px;
  font-weight: 400;
  padding-left: 20px;
  line-height: 1em;
  margin-right: 15px;
  display: inline-block;
  text-transform: uppercase;
}

.bootThree .news-block-three .inner-box .image .overlay-box .content .post-meta li .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 11px;
  color: #ffffff;
}

.bootThree .news-block-three.style-two .inner-box .image .overlay-box .content h3 {
  font-size: 30px;
  line-height: 1.4em;
}

.bootThree .news-block-three.style-three .inner-box .image .overlay-box .content h3 {
  font-size: 26px;
  line-height: 1.4em;
}

.bootThree .news-block-three.style-four .inner-box .image .overlay-box .content h3 {
  font-size: 22px;
  line-height: 1.4em;
}

.bootThree .news-block .inner-box {
  position: relative;
}

.bootThree .news-block .inner-box .image {
  position: relative;
}

.bootThree .news-block .inner-box .image .category {
  position: absolute;
  left: 15px;
  bottom: 15px;
  color: #ffffff;
  padding: 2px 10px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #222222;
}

.bootThree .news-block .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
}

.bootThree .news-block .inner-box .lower-box {
  position: relative;
  padding: 45px 0px 30px;
  border-bottom: 1px solid #eeeeee;
}

.bootThree .news-block .inner-box .lower-box h3 {
  position: relative;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.4em;
  margin-bottom: 15px;
  font-family: 'Montserrat',sans-serif;
}

.bootThree .news-block .inner-box .lower-box h3 a {
  color: #333333;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.bootThree .news-block .inner-box .lower-box h3 a:hover {
  color: #00A54F;
}

.bootThree .news-block .inner-box .lower-box .post-meta {
  position: relative;
  margin-bottom: 15px;
}

.bootThree .news-block .inner-box .lower-box .post-meta li {
  position: relative;
  color: #999999;
  font-weight: 600;
  font-size: 13px;
  padding-left: 18px;
  display: inline-block;
  margin-right: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.bootThree .news-block .inner-box .lower-box .post-meta li .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 13px;
  color: #999999;
}

.bootThree .news-block .inner-box .lower-box .text {
  position: relative;
  color: #777777;
  font-size: 14px;
  line-height: 1.8em;
  margin-bottom: 25px;
}

.bootThree .main-header {
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  background-color: #ffcb07;
}

.bootThree .main-header .container-fluid {
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}

.bootThree .main-header .col-md-2, .bootThree .main-header .col-sm-3, .bootThree .main-header .col-xs-12, .bootThree .main-header .col-md-10, .bootThree .main-header .col-sm-9 {
  position: relative;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
}

.bootThree #header-group h1 {
  margin-left: 0px;
  margin-top: 40px;
  font-size: 32px;
}

.bootThree #header-group {
  background: transparent !important;
  min-height: 120px;
}

.bootThree .main-header .logo-outer img {
  max-height: 160px;
}

.bootThree .main-header .header-lower {
  bottom: 0px;
  width: 100%;
  z-index: 5;
  background-color: #00A651;
}

.bootThree .main-header .nav-outer {
  position: relative;
  margin-left: 0px;
  padding-left: 0px;
  bottom: 0;
}

.bootThree #countdown {
  border: 2px solid #00A651;
  border-radius: 50%;
  behavior: url(/sites/all/themes/anc/css/PIE.htc);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  width: 70px;
  height: 70px;
  padding: 15px;
  text-align: center;
  color: #00A651;
  font: 32px Arial,sans-serif;
}

.bootThree #next-event span {
  margin: 15px auto;
  display: inline-block;
}

.bootThree #next-event {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.bootThree #edit-search-block-form--2 {
  background-color: #FFCB08;
  border: 1px solid #FFDB4D;
  border-top: 1px solid #F7C600;
  color: #00A651;
  margin-top: 5px;
}

.bootThree .view .view--anc-today a {
  font-size: 14px;
}

.bootThree #pane-46 .item-list li {
  padding: 10px;
  background-color: #ffff;
  border-bottom: 1px dotted #666;
}

.bootThree #pane-46 .item-list li a {
  font-size: 18px;
}

.bootThree #pane-46 li a {
  display: block;
}

.bootThree #block-panels-mini-social-feeds .nav-tabs {
  display: none;
}

.bootThree #pane-46 {
  padding-top: 30px;
  padding-right: 20px;
}

.bootThree .pane-anc-today .views-field-created {
  color: #666;
}

.bootThree .page-search .search-info a {
  display: none;
}

.bootThree #top_benner {
  padding-top: 15px;
}

.bootThree .collapsed .panel-body, .bootThree .webform-component-select-table {
  display: none !important;
}

.bootThree #autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.bootThree #autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.bootThree #autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
  zoom: 1;
}

.bootThree html.js input.form-autocomplete {
  background-image: url(/misc/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

.bootThree html.js input.throbbing {
  background-image: url(/misc/throbber-active.gif);
  background-position: 100% center;
}

.bootThree html.js fieldset.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.bootThree html.js fieldset.collapsed .fieldset-wrapper {
  display: none;
}

.bootThree fieldset.collapsible {
  position: relative;
}

.bootThree fieldset.collapsible .fieldset-legend {
  display: block;
}

.bootThree .form-textarea-wrapper textarea {
  display: block;
  margin: 0;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.bootThree .resizable-textarea .grippie {
  background: #eee url(/misc/grippie.png) no-repeat center 2px;
  border: 1px solid #ddd;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.bootThree body.drag {
  cursor: move;
}

.bootThree .draggable a.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
}

.bootThree a.tabledrag-handle:hover {
  text-decoration: none;
}

.bootThree a.tabledrag-handle .handle {
  background: url(/misc/draggable.png) no-repeat 6px 9px;
  height: 13px;
  margin: -0.4em 0.5em;
  padding: 0.42em 0.5em;
  width: 13px;
}

.bootThree a.tabledrag-handle-hover .handle {
  background-position: 6px -11px;
}

.bootThree div.indentation {
  float: left;
  height: 1.7em;
  margin: -0.4em 0.2em -0.4em -0.4em;
  padding: 0.42em 0 0.42em 0.6em;
  width: 20px;
}

.bootThree div.tree-child {
  background: url(/misc/tree.png) no-repeat 11px center;
}

.bootThree div.tree-child-last {
  background: url(/misc/tree-bottom.png) no-repeat 11px center;
}

.bootThree div.tree-child-horizontal {
  background: url(/misc/tree.png) no-repeat -11px center;
}

.bootThree .tabledrag-toggle-weight-wrapper {
  text-align: right;
}

.bootThree table.sticky-header {
  background-color: #fff;
  margin-top: 0;
}

.bootThree .progress .bar {
  background-color: #fff;
  border: 1px solid;
}

.bootThree .progress .filled {
  background-color: #000;
  height: 1.5em;
  width: 5px;
}

.bootThree .progress .percentage {
  float: right;
}

.bootThree .ajax-progress {
  display: inline-block;
}

.bootThree .ajax-progress .throbber {
  background: transparent url(/misc/throbber-active.gif) no-repeat 0px center;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

.bootThree .ajax-progress .message {
  padding-left: 20px;
}

.bootThree tr .ajax-progress .throbber {
  margin: 0 2px;
}

.bootThree .ajax-progress-bar {
  width: 16em;
}

.bootThree .container-inline div, .bootThree .container-inline label {
  display: inline;
}

.bootThree .container-inline .fieldset-wrapper {
  display: block;
}

.bootThree .nowrap {
  white-space: nowrap;
}

.bootThree html.js .js-hide {
  display: none;
}

.bootThree .element-hidden {
  display: none;
}

.bootThree .element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}

.bootThree .element-invisible.element-focusable:active, .bootThree .element-invisible.element-focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
}

.bootThree .clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.bootThree * html .clearfix {
  height: 1%;
}

.bootThree *:first-child + html .clearfix {
  min-height: 1%;
}
